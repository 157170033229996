import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, signOut } from 'firebase/auth';

import { FIREBASE_CONFIG } from '../constants';

async function signOutFirebaseSession() {
	try {
		const app =
			getApps().length === 0 ? initializeApp(FIREBASE_CONFIG) : getApp();

		const auth = getAuth(app);
		await signOut(auth);
	} catch (e) {
		console.error('e', e);
	}
}

export default signOutFirebaseSession;
