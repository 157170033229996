import styled from '@cogoport/front/styled';

export const DragContainer = styled.div`
	${(props) => `width: ${props.width} !important;`};
	position: relative;
	layout: flex row;

	plugin: ui/upload/dragContainer;
	border-radius: 4px;

	&.multiple {
		margin-left: 40px;
	}
	padding: 0px !important;

	&:hover {
		background-color: rgba(51, 51, 51, 0.8) !important;
	}
`;

export const FileContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: _fs1;
	svg {
		margin-left: _s4;
	}
	color: _orange_action.primary;

	&.primary {
		color: _orange_action.primary;
	}

	&.secondary {
		color: _blue_primary.default;
	}

	&.disabled {
		cursor: not-allowed;
	}

	svg {
		border-radius: 50%;
		background-color: #fff;
	}
`;

export const ImageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: _fs1;
	margin-bottom: 8px;
	svg {
		border-radius: 50%;
		background-color: #fff;
	}
`;

export const Binder = styled.div`
	position: relative;
`;

export const TextDiv = styled.div`
	position: absolute;
	max-width: 88px;
	padding: 4px;
	color: _white.surface;
`;
