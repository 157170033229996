import useRequest from '@/temp/request/useRequest';
import { Toast } from '@cogoport/components';
import { useSelector } from '@cogoport/front/store';
import { useTranslation } from 'next-i18next';

const translationKey = 'common:components_header_tickets_api';

const useUpdateTicketFeedback = ({ refetchTicket = () => {} }) => {
	const { t } = useTranslation(['common']);
	const profile = useSelector((state) => state?.profile);

	const { loading, trigger } = useRequest('put', false, 'cogocare', {
		authKey: 'put_tickets_feedback',
	})('/feedback');

	const updateTicketFeedback = async (rating = '', id = '') => {
		try {
			await trigger({
				data: {
					Rating: rating,
					TicketId: Number(id),
					PerformedByID: profile?.id,
				},
			});
			refetchTicket();
			Toast.success(t(`${translationKey}_rating_success`));
		} catch (e) {
			Toast.error(e?.error || t(`${translationKey}_error`));
		}
	};

	return {
		updateTicketFeedback,
		updateLoading: loading,
	};
};

export default useUpdateTicketFeedback;
