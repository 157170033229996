import { useSelector } from '@cogoport/front/store';
import getDefaultPath from '@/utils/getDefaultPath';

const getDefaultHomePath = () => {
	const { profile } = useSelector((state) => state);

	const { partner } = profile || {};

	const defaultPath = getDefaultPath({ activeChannelPartner: partner });

	return defaultPath;
};

export default getDefaultHomePath;
