import formatDate from '@/temp/utils/formatDate';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

const TO_DAY = 0;

const DAY = 1;

const WEEK = 7;

const MONTH = 30;

const YEAR = 365;

export const getTimeAgo = ({ days_difference, created_at, t }) => {
	const ago = t('common:ago_notifications');
	const date = formatDate({
		date: created_at,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd/mm/yyyy'],
		separator: ', ',
	});
	if (days_difference === TO_DAY) {
		return t('common:today_notifications');
	}

	if (days_difference === DAY) {
		return `1 ${t('common:day_notifications')} ${ago}`;
	}

	if (days_difference < WEEK) {
		return `${days_difference} ${t('common:days_notifications')} ${ago} `;
	}

	if (days_difference < MONTH) {
		const weeksAgo = Math.floor(days_difference / WEEK);
		return `${weeksAgo} ${
			weeksAgo === 1
				? t('common:week_notifications')
				: t('common:weeks_notifications')
		} ${ago} ${date}`;
	}

	if (days_difference < YEAR) {
		const monthsAgo = Math.floor(days_difference / MONTH);
		return `${monthsAgo} ${
			monthsAgo === 1
				? t('common:month_notifications')
				: t('common:months_notifications')
		} ${ago} ${date}`;
	}

	const yearsAgo = Math.floor(days_difference / YEAR);
	return `${yearsAgo} ${
		yearsAgo === DAY
			? t('common:year_notifications')
			: t('common:years_notifications')
	} ${ago} ${date}`;
};
