import Image from 'next/image';

function USAFlag() {
	return (
		<Image
			src="https://cdn.cogoport.io/cms-prod/cogo_public/vault/original/usa_flag.svg"
			alt="USA Flag"
			width={18}
			height={18}
			style={{
				margin: '2px',
				clipPath: 'circle(50% at 50% 50%)',
				objectFit: 'cover',
			}}
		/>
	);
}

export default USAFlag;
