import { getApp, getApps, initializeApp } from 'firebase/app';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useState, useCallback, useRef, useEffect } from 'react';
import { FIREBASE_CONFIG } from '../constants';

function useGetFirestoreAuthId() {
	const unSubRef = useRef(null);

	const [firestoreAuthId, setFirestoreAuthId] = useState('');

	const authObserver = useCallback(() => {
		const app =
			getApps().length === 0 ? initializeApp(FIREBASE_CONFIG) : getApp();

		const authInstance = getAuth(app);

		unSubRef.current = onAuthStateChanged(authInstance, (userData) => {
			if (userData) {
				setFirestoreAuthId(userData?.uid);
			}
		});
	}, []);

	useEffect(() => {
		authObserver();
		const unSubScribe = unSubRef.current;

		return () => {
			unSubScribe?.();
		};
	}, [authObserver]);

	return {
		firestoreAuthId,
	};
}
export default useGetFirestoreAuthId;
