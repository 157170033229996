import styled from '@cogoport/front/styled';

export const Container = styled.div`
	border-radius: 4px;
	box-shadow: 0 1px 4px #e4e9ff;
	transition: 0.2s;

	:hover {
		box-shadow: 0 1px 6px #bdbdbd;
		transition: 0.2s;
	}
`;

export const ModalContainer = styled.div`
	display: flex;
	position: relative;
	min-height: 200px;
	flex-direction: column;
`;

export const IconContainer = styled.div`
	position: absolute;
	bottom: -16px;
	right: -16px;
`;

export const A = styled.div`
	display: flex;
	align-items: center;
	padding: 6px 10px;
	cursor: pointer;

	:hover {
		text-decoration: none;
	}
`;

export const Heading = styled.span`
	color: #ff7a71;
	font-weight: 500;
	font-size: 14px;
	margin-left: 4px;
`;

export const Text = styled.div`
	color: #221f20;
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 12px;
`;

export const Subtext = styled.div`
	color: #221f20;
	font-size: 12px;
	font-weight: 400;
	width: 40%;
`;
