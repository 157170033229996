import { toast } from '@cogoport/front/components';
import { getApiErrorString } from '@cogoport/front/utils';
import { useState, useEffect } from 'react';
import useLegacyRequest from '../utils/getCustomAxios';

const useGetEmojiList = () => {
	const [onClicked, setOnClicked] = useState(false);

	const [{ data: emojisList }, trigger] = useLegacyRequest(
		{
			url: 'https://cogoport-testing.sgp1.digitaloceanspaces.com/b3949cf1f8cd3366d0272bd60c87c930/emoji-list.json',
			method: 'get',
		},
		{ manual: true },
	);

	const emojiListFetch = async () => {
		try {
			await trigger();
		} catch (error) {
			toast.error(getApiErrorString(error.data));
		}
	};

	useEffect(() => {
		emojiListFetch();
	}, []);

	return {
		emojisList,
		setOnClicked,
		onClicked,
		emojiListFetch,
	};
};

export default useGetEmojiList;
