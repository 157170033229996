import { Button } from '@cogoport/components';
import { useDispatch, useSelector } from '@cogoport/front/store';
import { IcCSendWhatsapp } from '@cogoport/icons-react';
import formatDate from '@/temp/utils/formatDate';
import { setProfileStoreState } from '@/utils/stores';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import MessageBody from '../MessageBody';
import styles from './styles.module.css';
import FooterItems from './FooterItems';

function UserReceivedView({
	eachMessage,
	sendFirebaseMessage,
	sendMessageLoading,
	t,
}) {
	const { profile = {} } = useSelector((state) => state);
	const dispatch = useDispatch();

	const { partner = {} } = profile || {};
	const { id: partnerId } = partner || {};

	const { response = {}, created_at, message_type } = eachMessage || {};
	const sentAt = formatDate({
		date: new Date(created_at),
		dateFormat: GLOBAL_CONSTANTS.formats.date['hh:mm'],
		formatType: 'dateTime',
		separator: ' ',
	});

	const { whatsapp_get_started_link = '' } = response || {};

	const handleScheduleDemo = () => {
		dispatch(
			setProfileStoreState({
				demoModalSource: 'web_bot',
			}),
		);
	};

	return (
		<div className={styles.parent_div}>
			<div className={styles.user_chat}>
				<MessageBody response={response} message_type={message_type} />
				{whatsapp_get_started_link && (
					<div
						role="presentation"
						onClick={() => window.open(whatsapp_get_started_link, '_blank')}
						className={styles.button_flex}
					>
						<IcCSendWhatsapp height="18px" width="18px" />
						<div className={styles.get_started_button}>
							{t('common:whatsapp_get_started_button')}
						</div>
					</div>
				)}

				{whatsapp_get_started_link && partnerId && (
					<div className={styles.demo_button}>
						<Button
							size="sm"
							themeType="secondary"
							onClick={handleScheduleDemo}
						>
							{t('common:schedule_demo_button')}
						</Button>
					</div>
				)}
				<div className={styles.time_stamp}>{sentAt}</div>
			</div>
			<FooterItems
				response={response}
				sendFirebaseMessage={sendFirebaseMessage}
				sendMessageLoading={sendMessageLoading}
			/>
		</div>
	);
}
export default UserReceivedView;
