import { isEmpty } from '@cogoport/utils';
import { useMemo } from 'react';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import formatDate from '@/temp/utils/formatDate';
import Loader from '../../Loader';
import useUpdateFaqSearchHistory from '../hooks/useUpdateFaqSearchHistory';
import styles from './styles.module.css';

function SearchHistoryList({
	setShowHistory,
	searchHistoryList,
	searchHistoryListLoading,
	setSearch,
	setInput,
}) {
	const { onClickClearHistory = () => {} } = useUpdateFaqSearchHistory({
		setShowHistory,
	});

	const today = new Date();
	const formatToday = formatDate({
		date: today,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	const yesterday = new Date(today.getTime() - 86400000); // 86400000 is the number of milliseconds in a day
	const formatYesterday = formatDate({
		date: yesterday,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	const daysMapping = {
		[formatToday]: 'Today',
		[formatYesterday]: 'Yesterday',
	};

	const filteredObject = useMemo(() => {
		const modifiedFilteredObject = {};

		(searchHistoryList || []).forEach((aaa) => {
			const { updated_at, requested_search } = aaa || {};
			const date = formatDate({
				date: updated_at,
				dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
				formatType: 'date',
			});

			const time = formatDate({
				date: updated_at,
				dateFormat: GLOBAL_CONSTANTS.formats.date['hh:mm aa'],
				formatType: 'time',
			});

			if (date && requested_search) {
				if (!modifiedFilteredObject[date]) {
					modifiedFilteredObject[date] = [{ question: requested_search, time }];
				} else {
					modifiedFilteredObject[date].push({
						question: requested_search,
						time,
					});
				}
			}
		});

		return modifiedFilteredObject;
	}, [searchHistoryList]);

	const sortedDates = Object.keys(filteredObject || {})
		.map((item) => item)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

	if (searchHistoryListLoading) return <Loader />;

	if (isEmpty(searchHistoryList)) {
		return <div className={styles.empty_state_wrapper}>No History Found</div>;
	}

	return (
		<div className={styles.container}>
			<div className={styles.history_list}>
				{sortedDates.map((ele) => (
					<>
						<div className={styles.header}>{daysMapping[ele] || ele}</div>

						{(filteredObject[ele] || []).map((element) => {
							const { question, time } = element;

							return (
								<div
									className={styles.question_time_container}
									role="presentation"
									onClick={() => {
										setSearch(question);
										setInput(question);
									}}
								>
									<div className={styles.question_wrapper}>{question}</div>

									<div className={styles.time_wrapper}>{time}</div>
								</div>
							);
						})}
					</>
				))}
			</div>

			<div
				className={styles.clear_search}
				role="presentation"
				onClick={onClickClearHistory}
			>
				Clear History
			</div>
		</div>
	);
}

export default SearchHistoryList;
