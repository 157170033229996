import { createWithStore } from '@cogoport/front/store';

import {
	profileStore,
	dashboardStore,
	searchStore,
	importerExporterStore,
	serviceProviderStore,
} from './stores';

const withStore = createWithStore(
	{
		profile: profileStore,
		dashboard: dashboardStore,
		search: searchStore,
		importer_exporter: importerExporterStore,
		service_provider: serviceProviderStore,
	},
	{
		storeKey: process.env.NEXT_PUBLIC_STORE_KEY,
	},
);

export default withStore;
