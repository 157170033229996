import { Image } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals';

const ICON_MAPPING = {
	value_added_services: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.value_added_services}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	onboarding: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.onboarding}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	industry: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.industry}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	bookings_or_transaction_flow: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.bookings_or_transaction_flow}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	operations_and_shipment_execution: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.operations_and_shipment_execution}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	platform_help: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.platform_help}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},

	saas_tools: {
		icon: (
			<Image
				src={GLOBAL_CONSTANTS.image_urls.saas_tools}
				alt="logo cogoport"
				width={50}
				height={50}
			/>
		),
	},
};
export default ICON_MAPPING;
