import { Toast } from '@cogoport/components';
import { isEmpty } from '@cogoport/utils';
import getFileAttributes from '../../../utils/getFileAttributes';

const INCREASE_NEW_MESSAGE_COUNT = 1;

const useSendFirebaseMessage = ({
	supabaseClient,
	sendMessageLoading,
	sendMessage,
	messageStatus,
	fetchMessagesRef,
	authUserId,
}) => {
	const addMessageToFirestore = async ({ userChat, userRoom }) => {
		const {
			new_message_count: oldCount = 0,
			agent_type = 'bot',
			id: roomId = null,
		} = userRoom?.[0] || {};

		await supabaseClient.from('customer_messages').insert({
			...userChat,
			agent_type,
			customer_chat_id: roomId,
		});

		await supabaseClient
			.from('customer_chats')
			.update({
				new_message_count: oldCount + INCREASE_NEW_MESSAGE_COUNT,
				last_message_document: userChat,
				last_message: userChat?.response.message || '',
				has_admin_unread_messages: true,
				new_message_sent_at: new Date(),
			})
			.eq('id', roomId);
	};

	const sendFirebaseMessage = async ({
		message,
		buttonId = null,
		type = '',
		file = {},
		reset = () => {},
	}) => {
		if (sendMessageLoading || !(message || !isEmpty(file))) {
			return;
		}

		if (
			(!isEmpty(fetchMessagesRef.current) &&
				fetchMessagesRef.current.state !== 'joined') ||
			messageStatus !== 'SUBSCRIBED'
		) {
			Toast.error('Reopen bot or Refresh');
			return;
		}

		const newMessage = message.trim() || '';
		const { fileType = '', finalUrl = '' } = !isEmpty(file)
			? getFileAttributes({
					fileName: file?.name,
					finalUrl: file?.url,
			  })
			: {};

		const { data: userRoom } = await supabaseClient
			.from('customer_chats')
			.select()
			.eq('auth_user_id', authUserId);

		const roomSessionType = userRoom?.[0]?.session_type || 'bot';
		const roomId = userRoom?.[0].id || '';
		const userChat = {
			conversation_type: 'sent',
			message_type: finalUrl ? fileType : type || 'text',
			response: {
				message: newMessage,
				media_url: finalUrl || '',
				session_type: roomSessionType,
				replied_button_id: buttonId,
			},
		};

		let messageMetaData = {
			btn_id: buttonId || undefined,
			session_type: roomSessionType,
			customer_chat_id: roomId,
		};

		if (finalUrl) {
			messageMetaData = {
				...messageMetaData,
				message_type: fileType,
				text: newMessage,
				media_url: finalUrl,
			};
		} else {
			messageMetaData = {
				...messageMetaData,
				message_type: type || 'text',
				text: newMessage,
			};
		}

		sendMessage({
			messageMetaData,
			updateFirestore: () => {
				addMessageToFirestore({ userChat, userRoom });
				reset();
			},
		});
	};

	return {
		sendFirebaseMessage,
	};
};
export default useSendFirebaseMessage;
