import { arrayOf, shape, func, bool, string } from 'prop-types';
import { Text } from '@cogoport/front/components';
import IconCross from '../../../icons/cross1.svg';
import IconAttachment from '../../../icons/attachment.svg';
import IconDownload2 from '../../../icons/download-2.svg';
import { getThemeType } from './getThemeType';

import { FileContainer } from './styles';

function FileList({
	list,
	onRemove,
	showRemoveIcons,
	size,
	themeType,
	showDownloadIcon,
	disabled,
}) {
	const sizeObj = {
		small: '88px',
		mid: '148px',
		large: '215px',
	};
	const handleDownload = (url) => {
		window.open(url);
	};
	return list.map((file) => (
		<FileContainer
			className={`${themeType || 'primary'} ${disabled ? 'disabled' : ''}`}
			key={file.name}
		>
			<IconAttachment
				style={{ marginRight: '4px' }}
				size={1.5}
				themeType={getThemeType(themeType)}
			/>
			<Text
				style={{
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					display: 'block',
					width: sizeObj[size],
				}}
			>
				{(file || {}).name || ''}
			</Text>
			{showRemoveIcons && (
				<IconCross
					size={1}
					themeType={getThemeType(themeType)}
					cursor={!disabled ? 'pointer' : 'not-allowed'}
					onClick={!disabled ? () => onRemove(file) : null}
					style={{ marginLeft: 5 }}
				/>
			)}
			{showDownloadIcon && (
				<IconDownload2
					size={1.25}
					themeType="#333333"
					onClick={() => handleDownload(file.url)}
					cursor="pointer"
				/>
			)}
		</FileContainer>
	));
}

FileList.propTypes = {
	list: arrayOf(shape({})),
	onRemove: func,
	showRemoveIcons: bool,
	size: string.isRequired,
	showDownloadIcon: bool,
	disabled: bool,
};

FileList.defaultProps = {
	list: [],
	onRemove: () => {},
	showRemoveIcons: true,
	showDownloadIcon: true,
	disabled: false,
};

export default FileList;
