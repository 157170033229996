import '@cogoport/components/dist/themes/base.css';
import '@cogoport/components/dist/themes/dawn.css';
import { useEffect } from 'react';
import { ThemeProvider, Global } from '@cogoport/front/styled';
import { Provider as StoreProvider } from '@cogoport/front/store';
import ToastContainer from '@cogoport/front/components/toast/ToastContainer';
import { setStoreState as setGeneralStoreState } from '@cogoport/front/store/store/general';
import { appWithTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { getCookie } from '@cogoport/utils';
import { LocaleContext, language } from '@cogoport/components';
import getMetaTagData from '@/temp/utils/getMetaTagData';

import { setCookies } from '@/temp/cogo-cookies';
import isMobileAgent from '@/utils/isMobileAgent';
import { Head } from '@/temp/next';

import withStore from '@/utils/store';
import { GlobalLayout } from '@/components/Layout';
import handleAuthentication from '@/utils/auth/handleAuthentication';
import getFirestoreCustomToken from '@/utils/auth/getFirestoreCustomToken';

import pageProgressBar from '@/temp/pageProgressBar';
import getCountryDetails from '@/temp/utils/getCountryDetails';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import { getGeoConstants } from '@/constants/geo';
import ScheduleDemoForm from '@/commons/components/ScheduleDemoForm';
import BotAuthorization from '@/components/BotAuthorization';
import cogoTheme from '../theme';
// export function reportWebVitals() {
// 	if (process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
// 		// console.log('web-vitals', metric);
// 	}
// }
const VIETNAM_COUNTRY_CODE = getCountryDetails({
	country_id: GLOBAL_CONSTANTS.country_ids.VN,
})?.country_code;

function CpLspApp({ Component, pageProps, store, generalData }) {
	const { profile } = store.getState() || {};

	const router = useRouter();

	const { locale } = router;

	const geo = getGeoConstants();

	const { parent_entity_id, id: partnerId } = profile.partner || {};

	let countryCode = '';

	if (typeof document !== 'undefined') {
		countryCode = getCookie('location');
	}

	const isUnKnownUser = !partnerId;

	const isBotVisible = isUnKnownUser
		? countryCode !== VIETNAM_COUNTRY_CODE
		: geo.parent_entity_id !== GLOBAL_CONSTANTS.country_entity_ids.VN;

	useEffect(() => {
		setCookies('parent_entity_id', parent_entity_id);

		router.events.on('routeChangeStart', () => {
			pageProgressBar.start();
			pageProgressBar.set(0.4);
		});

		router.events.on('routeChangeComplete', () => {
			pageProgressBar.done();
		});
	}, []);

	useEffect(() => {
		store.dispatch(setGeneralStoreState(generalData));
	}, [generalData]);

	return (
		<LocaleContext.Provider
			value={language[locale === 'default' ? 'en-IN' : locale]}
		>
			<StoreProvider store={store}>
				{/* <RoutesProvider config={{ pathPrefix, asPrefix, query }}> */}
				<ThemeProvider theme={cogoTheme}>
					<Head>
						<title>Cogoport Partner</title>

						<link rel="icon" href="/favicon.ico" />

						<link
							rel="icon"
							type="image/png"
							sizes="32x32"
							href="/favicon-32x32.png"
						/>
						<link
							rel="icon"
							type="image/png"
							sizes="16x16"
							href="/favicon-16x16.png"
						/>

						<meta
							name="viewport"
							content="width=device-width, initial-scale=1.0"
						/>
						<meta name="theme-color" content="#ffffff" />

						<link rel="manifest" href="/manifest.json" />
					</Head>

					<Global styles={cogoTheme.globalStyles} />

					<GlobalLayout {...pageProps}>
						<Component {...pageProps} />

						<ToastContainer />

						{isBotVisible && <BotAuthorization />}

						<ScheduleDemoForm />
					</GlobalLayout>
				</ThemeProvider>
				{/* </RoutesProvider> */}
			</StoreProvider>
		</LocaleContext.Provider>
	);
}

CpLspApp.getInitialProps = async ({ Component, ctx }) => {
	const { store, req, pathname, asPath, query = {}, locale } = ctx;

	// let modifiedAsPath = asPath;
	// if (pathname.includes('[partner_id]')) {
	// 	const { partner_id } = query;

	// 	const modifiedPathname = pathname.replace('/[partner_id]/', '');

	// 	modifiedAsPath = `/${partner_id}/${modifiedPathname}`;
	// }

	const isServer = typeof req !== 'undefined';

	const pathPrefix = `/[partner_id]`;

	const ctxParams = {
		...ctx,
		isServer,
		pathPrefix,
		// asPath: modifiedAsPath,
	};

	// let unPrefixedPath = `/${asPath.split('/').slice(2).join('/')}`;
	const unPrefixedPath = `/${pathname.replace('/[partner_id]/', '')}`;

	const { asPrefix, query: qError } = await handleAuthentication(ctxParams);
	const { firestoreCustomToken: firestoreToken } =
		await getFirestoreCustomToken();

	const metaTagsData = await getMetaTagData({ locale, pathname });

	const isMobile = !isServer
		? window.innerWidth < 768
		: isMobileAgent(ctx.req.headers['user-agent'] || '');

	const generalData = {
		pathname,
		// asPath: modifiedAsPath,
		asPath,
		unPrefixedPath,
		pathPrefix,
		asPrefix,
		scope: 'partner',
		query: { ...query, ...(qError || {}) },
		isServer,
		isMobile,
		locale,
		firestoreToken,
		metaTagsData,
	};

	await store.dispatch(setGeneralStoreState(generalData));

	const initialProps = Component.getInitialProps
		? await Component.getInitialProps(ctxParams)
		: {};

	return {
		pageProps: initialProps,
		pathname,
		asPath,
		query,
		isServer,
		generalData,
	};
};

const AppWithStore = withStore(CpLspApp);

export default appWithTranslation(AppWithStore);
