import { ICON_MAPPING } from '@/components/Notifications/common/IconMapping';
import { getStatus } from '@/components/Notifications/helper';
import { useRouter } from '@/temp/next';

import { differenceInDays, isEmpty, toDate } from '@cogoport/utils';
import { useTranslation } from 'next-i18next';

import EmptyList from '../../../common/EmptyList';
import Loader from '../../../common/Loader';
import useUpdateCommunication from '../../../hooks/useUpdateCommunication';
import { getTimeAgo } from '../../../utils';

import styles from './styles.module.css';

function NotificationsList({
	list = [],
	loading = false,
	getListCommunications = () => {},
	showPopover = false,
}) {
	const { t } = useTranslation(['common']);

	const { push } = useRouter();

	const { updateCommunication = false } = useUpdateCommunication({
		getListCommunications,
	});

	const onSubmit = (item, link) => {
		updateCommunication(item);
		if (item?.is_clicked && link) {
			push(link);
		}
	};

	if (loading) {
		return <Loader />;
	}

	return isEmpty(list) ? (
		<EmptyList />
	) : (
		(list || []).map((item) => {
			const { content = {}, is_seen = '', service = '' } = item || {};
			const {
				body = '',
				created_at = '',
				link = '',
				subject = '',
			} = content || {};
			const days_difference = differenceInDays(
				toDate(Date.now()),
				new Date(created_at),
			);
			return (
				<div
					role="presentation"
					className={is_seen ? styles.seen_wrapper : styles.wrapper}
					key={item?.id}
					onClick={() => onSubmit(item, link)}
					style={{
						backgroundColor: showPopover ? '#f9f9f9' : '#ffff',
					}}
				>
					{ICON_MAPPING?.[service] && (
						<div
							className={styles.avatar}
							style={{
								background: ICON_MAPPING?.[service]?.background,
							}}
						>
							{ICON_MAPPING?.[service]?.icon}
						</div>
					)}

					<div className={styles.text_box}>
						<div className={styles.service}>{subject}</div>
						<div className={is_seen ? styles.body : styles.unseen_body}>
							<div dangerouslySetInnerHTML={{ __html: body }} />
						</div>
						<div className={styles.days_ago}>
							<div className={styles.days}>
								{getTimeAgo({ days_difference, created_at, t })}
							</div>
							<div>{getStatus({ item, t })}</div>
						</div>
					</div>
				</div>
			);
		})
	);
}

export default NotificationsList;
