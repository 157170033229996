import getDemoTimeSlotOptions from '@/commons/utils/getDemoTimeSlotOptions';

const getControls = ({ t, user_availability_date = '' }) => {
	return [
		{
			name: 'user_availability_date',
			label: t('common:controls_user_availability_date'),
			type: 'date',
			size: 'lg',
			showTimeSelect: false,
			rules: { required: true },
		},
		{
			name: 'user_availability_time_slot',
			type: 'chips',
			label: t('common:controls_user_time_slot'),
			items: getDemoTimeSlotOptions({ user_availability_date }),
			rules: { required: true },
		},
		{
			name: 'remarks',
			label: t('common:controls_remarks'),
			type: 'text',
			rules: { required: true },
		},
	];
};

export default getControls;
