import styled from '@cogoport/front/styled';

export const Container = styled.div`
	${(props) => `width: ${props.width} !important;`};
	cursor: pointer;
	position: relative;

	:focus {
		outline: none;
	}

	plugin: ui/upload/container;
`;

export const DragContainer = styled.div`
	display: flex;
	flex-direction: row;
	${(props) => `width: ${props.width} !important;`};

	plugin: ui/upload/dragContainer;
`;

export const DragText = styled.div`
	plugin: ui/upload/dragText;
`;

export const HelperText = styled.div`
	font-size: 14px;
	font-style: italic;
	line-height: 1.43;
	color: _grey.border;
`;

export const UploadLists = styled.div`
	layout: column;
	.rc-upload {
		:focus {
			outline: none;
		}
	}
	:focus {
		outline: none;
	}
	&.row {
		layout: row;
		.rc-upload {
			width: 100%;
		}
	}
`;
