import { useSelector } from '@cogoport/front/store';
import useRequest from '@/utils/request/useRequest';

const useGetTicketsUnreadCount = () => {
	const { id } = useSelector((state) => state.profile);

	const { trigger, data } = useRequest('get', false, 'cogocare', {
		authkey: 'get_tickets_unread_count',
	})('/unread_count');

	const getUnreadTicketsCount = () => {
		try {
			trigger({
				params: {
					PerformedByID: id,
				},
			});
		} catch (error) {
			console.log(error);
		}
	};

	return {
		getUnreadTicketsCount,
		unreadCount: data,
	};
};

export default useGetTicketsUnreadCount;
