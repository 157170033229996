import React from 'react';
import { Placeholder } from '@cogoport/components';

function Loader() {
	return (
		<>
			{[...Array(6).keys()].map((key) => {
				return (
					<div key={key}>
						<Placeholder width="100%" height="20px" margin="16px 0" />
					</div>
				);
			})}
		</>
	);
}

export default Loader;
