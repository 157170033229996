import useRequest from '@/temp/request/useRequest';
import { useEffect, useState } from 'react';
import { useSelector } from '@cogoport/front/store';
import useDebounceQuery from '@/commons/form/hooks/useDebounceQuery';
import { FILTER_KEYS_MAPPING, NO_OF_TICKETS_TO_BE_SHOWED } from '../constants';

const useListTickets = ({
	searchValue = '',
	activeTab = '',
	listType = '',
}) => {
	const { profile } = useSelector((state) => state);
	const [pagination, setPagination] = useState(1);
	const [listData, setListData] = useState([]);

	const { debounceQuery, query: searchQuery = '' } = useDebounceQuery();

	const { loading, data, trigger } = useRequest('get', false, 'cogocare', {
		authkey: 'get_tickets_list',
	})('/list');

	const fetchTickets = async (pageIndex) => {
		try {
			const response = await trigger({
				params: {
					Statuses: FILTER_KEYS_MAPPING[activeTab],
					UserID: profile?.id,
					PerformedByID: profile?.id,
					size: NO_OF_TICKETS_TO_BE_SHOWED?.[
						listType === 'create' ? 'create' : 'default'
					],
					page: pageIndex - 1,
					QFilter: searchQuery || undefined,
				},
			});

			setListData((prev) => [...prev, ...(response?.data?.items || [])]);
			setPagination(pageIndex + 1);
		} catch (error) {
			console.log('error:', error);
		}
	};

	const refreshTickets = () => {
		setListData([]);
		fetchTickets(1);
	};

	useEffect(() => {
		setListData([]);
		fetchTickets(1);
	}, [searchQuery, activeTab]);

	useEffect(() => {
		debounceQuery(searchValue);
	}, [searchValue]);

	const handleScroll = (clientHeight, scrollTop, scrollHeight) => {
		const reachBottom = scrollHeight - (clientHeight + scrollTop) <= 20;
		const hasMoreData = pagination <= (data?.total_pages || 0);
		if (reachBottom && hasMoreData && !loading) {
			fetchTickets(pagination);
		}
	};

	return {
		ticketData: listData,
		listLoading: loading,
		fetchTickets,
		handleScroll,
		refreshTickets,
	};
};

export default useListTickets;
