import styled from '@cogoport/front/styled';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

const image = GLOBAL_CONSTANTS.image_urls.onboarding_background;
const getBackground = (props) => {
	return props?.isbeforeRegistration
		? 'transparent'
		: `url(${image}) no-repeat`;
};
const getMargin = (activeComponentKey) => {
	if (['termsAndConditions', 'accountCreated'].includes(activeComponentKey)) {
		return '40px';
	}
	return '0px';
};
export const Container = styled.section`
	background: ${(props) => getBackground(props)};
	background-size: cover;
	&.right-panel {
		height: 100%;
		padding-top: ${(props) => props.paddingTop};
		/* padding-bottom: 80px; */

		position: relative;

		display: flex;
		flex-direction: column;

		overflow-y: auto;
	}
`;

export const Header = styled.header`
	&.right-panel__header {
		height: 52px;
		background-color: inherit;
		padding: 16px;

		z-index: 1;

		display: flex;
		justify-content: flex-end;
		align-items: center;

		position: fixed;
		top: 0;
		left: 0;
		right: 0;

		@media (min-width: 567px) {
			left: calc(100% - 40%);
		}
	}
`;
export const Icon = styled.div`
	&.left-panel__icon {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&.left-panel__icon--cogoport {
		margin-top: 32px;

		& svg {
			width: 130px;
			min-height: 38px;
		}
	}

	&.left-panel__icon--globe {
		position: fixed;
		bottom: -160px;

		& svg {
			width: 100%;
			height: 500px;
		}
	}
`;
export const Main = styled.main`
	&.right-panel__main {
		margin-top: ${({ activeComponentKey }) => getMargin(activeComponentKey)};
		padding: 16px;

		@media (min-width: 576px) {
			padding: 32px;
		}

		@media (min-width: 768px) {
			padding: 8px 80px;
		}
		@media (max-width: 768px) {
			padding: 40px 10px;
		}
	}
`;
