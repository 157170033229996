import { useDispatch, useSelector } from '@cogoport/front/store';
import { useState, useEffect } from 'react';
import { Toast } from '@cogoport/components';
import { getApiErrorString, startCase } from '@cogoport/front/utils';
import { useTranslation } from 'next-i18next';
import useRequest from '@/temp/request/useRequest';
import { setProfileStoreState } from '@/utils/stores';

import { useForm } from '@/commons/form';
import { useRouter } from '@/temp/next';
import getControls from './controls';

const useScheduleDemo = () => {
	const { t } = useTranslation(['common']);

	const dispatch = useDispatch();

	const { push } = useRouter();

	const { profile } = useSelector((state) => ({
		profile: state.profile,
	}));

	const {
		name = '',
		partner,
		id: user_id = '',
		demoModalSource = false,
	} = profile || {};
	const { twin_importer_exporter_id = '' } = partner || {};

	const { loading, trigger } = useRequest(
		'post',
		false,
		'partner',
	)('/create_cogoone_calendar');

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
		setValue,
	} = useForm();

	const { user_availability_date } = watch();

	const [demoScheduled, setDemoScheduled] = useState('');

	const controls = getControls({ t, user_availability_date });

	function convertTimeSlotToUTC(timeSlot, dateToAppend) {
		const [start, end] = timeSlot.split(' - ');

		const startTime = new Date(`${dateToAppend.toDateString()} ${start}`);
		const startTimeUTC = new Date(startTime.toUTCString()).toISOString();

		const endTime = new Date(`${dateToAppend.toDateString()} ${end}`);
		const endTimeUTC = new Date(endTime.toUTCString()).toISOString();

		return {
			user_availability_start_time: startTimeUTC,
			user_availability_end_time: endTimeUTC,
		};
	}

	const onSubmit = async (values) => {
		try {
			const { user_availability_time_slot, remarks } = values;

			const { user_availability_start_time, user_availability_end_time } =
				convertTimeSlotToUTC(
					user_availability_time_slot,
					user_availability_date,
				);

			if (user_availability_start_time >= user_availability_end_time) {
				Toast.error(t('common:invalid_time_error'));
				return;
			}

			const payload = {
				subject: `Demo Request for ${startCase(name)}`,
				start_time: user_availability_start_time,
				end_time: user_availability_end_time,
				validity_start: user_availability_start_time,
				validity_end: user_availability_end_time,
				category: 'meeting',
				frequency: 'one_time',
				tags: ['demo_request'],
				recurrence_rule: { type: 'normal' },
				description: remarks || undefined,
				metadata: {
					organization_id: twin_importer_exporter_id,
					user_id,
				},
			};

			await trigger({ data: payload });

			setDemoScheduled(true);
		} catch (err) {
			if (err.data.message) {
				Toast.error(err.data.message.split(' ').slice(1).join(' '));
			} else {
				Toast.error(getApiErrorString(err.data));
			}
		}
	};

	const handleClose = () => {
		dispatch(
			setProfileStoreState({
				demoModalSource: false,
			}),
		);
		setDemoScheduled(false);
	};

	const handleNavigate = () => {
		reset();

		handleClose();

		if (demoModalSource === 'web_bot' && demoScheduled) {
			push('/demo');
		}
	};

	useEffect(() => {
		setValue('user_availability_time_slot', '');
	}, [user_availability_date, setValue]);

	return {
		loading,
		demoScheduled,
		control,
		controls,
		errors,
		handleSubmit,
		handleNavigate,
		handleClose,
		setDemoScheduled,
		onSubmit,
		reset,
	};
};

export default useScheduleDemo;
