import { getApiErrorString } from '@cogoport/front/utils';
import { Toast } from '@cogoport/components';
import { useEffect } from 'react';
import { useSelector } from '@cogoport/front/store';
import useRequest from '@/temp/request/useRequest';

const getParams = ({ user_id = '' }) => ({
	participant_data_required: true,
	filters: {
		tags: ['demo_request'],
		user_id,
	},
});

const useGetOrgCommunicationDetails = () => {
	const { profile = {} } = useSelector((state) => state);

	const { demoModalSource, id = '' } = profile;

	const {
		data = {},
		loading = false,
		trigger,
	} = useRequest(
		'get',
		false,
		'partner',
	)('/communication/list_cogoone_calendars');

	const getScheduledDemo = async () => {
		try {
			await trigger({ params: getParams({ user_id: id }) });
		} catch (err) {
			if (err?.data) {
				Toast.error(getApiErrorString(err.data));
			}
		}
	};

	useEffect(() => {
		if (!demoModalSource) {
			getScheduledDemo();
		}
	}, [demoModalSource]);

	return {
		getScheduledDemo,
		data,
		loading,
	};
};

export default useGetOrgCommunicationDetails;
