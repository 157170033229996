import getDefaultHomePath from '@/temp/utils/getDefaultHomePath';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import { A, ContainerFlex } from './styles';

function CompanyDetails() {
	const redirectUrl = getDefaultHomePath();

	return (
		<ContainerFlex alignItems="center">
			<A href={redirectUrl}>
				<img
					src={GLOBAL_CONSTANTS.image_urls.new_cogoport_logo}
					alt="cogoport-logo"
					style={{ display: 'block' }}
					width={118}
					height={24}
				/>
			</A>
		</ContainerFlex>
	);
}

export default CompanyDetails;
