import { useState, useCallback, useEffect } from 'react';
import { useSelector } from '@cogoport/front/store';
import useAuthenticateFirebase from './useAuthenticateFirebase';
import useGetFirestoreAuthId from './useGetFirestoreAuthId';
import signOutFirebaseSession from '../helpers/signOutFirebaseSession';

function useCheckUserLoggedIn({ supabaseClient }) {
	const orgId = useSelector(({ profile }) => profile?.partner?.id);

	const [anonymousUserId, setAnonymousUserId] = useState('');

	const { firestoreAuthId } = useGetFirestoreAuthId();

	const { signInUser } = useAuthenticateFirebase({
		isUnKnownUser: !orgId,
		supabaseClient,
		firestoreAuthId,
	});

	const getSupabaseSession = useCallback(async () => {
		const data = await signInUser();
		if (data && data.session) {
			const { role = '' } = data.session.user;

			if ((orgId && role === 'anon') || (!orgId && role !== 'anon')) {
				setAnonymousUserId('');
				await supabaseClient.auth.signOut({ scope: 'local' });
				setAnonymousUserId(data?.session?.user?.id);
			} else {
				setAnonymousUserId(data?.session?.user?.id || '');
			}
		} else {
			console.log('supabaseLogInError', 'could not logged in');
		}
		signOutFirebaseSession();
	}, [signInUser]);

	useEffect(() => {
		getSupabaseSession();
	}, [getSupabaseSession]);

	return {
		anonymousUserId,
	};
}
export default useCheckUserLoggedIn;
