import { useState } from 'react';
import { arrayOf, shape, func, bool } from 'prop-types';
import IconCrossCircle2 from '../../../icons/cross-circle2.svg';
import Image from '../../Content/Image';

import { DragContainer, ImageContainer, Binder, TextDiv } from './styles';

function ImageList({
	list,
	onRemove,
	showRemoveIcons,
	className,
	multiple,
	disabled,
}) {
	const [isHovering, setIsHovering] = useState(false);
	const crossStyle = {
		position: 'absolute',
		right: '-6px',
		top: '-6px',
		zIndex: '1',
	};
	return (
		<ImageContainer>
			{list.map((image) => (
				<Binder
					key={image.uid || image.url || image.name}
					onMouseOver={() => setIsHovering(true)}
					onMouseLeave={() => setIsHovering(false)}
				>
					{showRemoveIcons && (
						<IconCrossCircle2
							style={crossStyle}
							size={1.5}
							themeType="primary"
							onClick={!disabled ? () => onRemove(image) : null}
						/>
					)}
					<DragContainer className={`${className} ${multiple && 'multiple'}`}>
						{isHovering && <TextDiv>{image.name}</TextDiv>}
						<Image
							style={{
								height: '88px',
								width: '88px',
								opacity: isHovering && 0.3,
							}}
							src={image.url}
						/>
					</DragContainer>
				</Binder>
			))}
		</ImageContainer>
	);
}

ImageList.propTypes = {
	list: arrayOf(shape({})),
	onRemove: func,
	showRemoveIcons: bool,
	disabled: bool,
};

ImageList.defaultProps = {
	list: [],
	onRemove: () => {},
	showRemoveIcons: true,
	disabled: false,
};

export default ImageList;
