import { Loader } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import { isEmpty } from '@cogoport/utils';
import styles from './styles.module.css';
import UpcomingDemoCard from './UpcomingDemoCard';

function UpcomingDemo({ data = {}, loading = false }) {
	const { t } = useTranslation(['common']);

	const currentDate = new Date();

	const upcomingDemos = ((data || {}).list || []).filter(
		(item) => new Date(item.start_time) >= currentDate,
	);

	if (loading) {
		return (
			<div className={styles.loading_container}>
				<Loader style={{ width: 32, height: 32 }} />
			</div>
		);
	}

	if (isEmpty(upcomingDemos)) {
		return null;
	}

	return (
		<div className={styles.container}>
			<p className={styles.heading}>{t('common:demo_heading')}</p>

			{upcomingDemos.map((upcoming_demo) => (
				<UpcomingDemoCard
					key={upcoming_demo.id}
					upcoming_demo={upcoming_demo}
				/>
			))}
		</div>
	);
}

export default UpcomingDemo;
