import request from '../request';

const getPartner = async (ctx) => {
	const response = await request.partner.get('/auth/get_user_session', { ctx });

	let data = {};

	if (!response?.hasError) {
		data = response.data;
	}

	return data;
};

export default getPartner;
