const STATIC_OPTIONS = [
	'trade-types',
	'commodities',
	'truck-types',
	'work-scopes',
	'countries',
	'languages',
	'inco-terms',
	'container-sizes',
	'container-types',
	'currencies',
	'service-types',
	'supplier-license-type',
	'months',
	'sales_agent_locations',
	'searchable-services',
];

export default STATIC_OPTIONS;
