import useRequest from '@/temp/request/useRequest';
import { useSelector } from '@cogoport/front/store';

const useUpdateCommunication = ({ getListCommunications }) => {
	const { general } = useSelector((state) => state);
	const { scope } = general;

	const { data, loading, trigger } = useRequest(
		'POST',
		false,
		scope,
	)('/communication/update_communication');

	const updateCommunication = async (item) => {
		const payload = {
			id: item?.id,
			is_clicked: true,
		};
		try {
			await trigger({
				data: payload,
			});
			getListCommunications();
		} catch (e) {
			console.error(e);
		}
	};

	return { updateCommunication, data, loading };
};
export default useUpdateCommunication;
