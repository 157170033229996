import { useRouter } from '@/temp/next';
import { useSelector } from '@cogoport/front/store';
import { Button } from '@cogoport/components';

import styles from './styles.module.css';

function Error404() {
	const {
		profile: { partner },
	} = useSelector((reduxState) => reduxState);

	const { account_types } = partner;

	const router = useRouter();

	const handleBack = () => {
		if (
			account_types.includes('service_provider') &&
			!account_types.includes('importer_exporter')
		) {
			router.push('/lsp-dashboard', '/lsp-dashboard');
		} else {
			router.push('/dashboard', '/dashboard');
		}
	};

	return (
		<div className={styles.component}>
			<div className={styles.world_map} />
			<div className={styles.text_container}>
				<div className={styles.title}>404</div>
				<div className={styles.description}>Looks like you are off course</div>
				<div className={styles.button_container}>
					<Button onClick={handleBack} themeType="accent">
						Get back to base
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Error404;
