import { arrayOf, shape, string, bool, func } from 'prop-types';

import FileList from './Types/File';
import ImageList from './Types/Image';

function UploadList(props) {
	const {
		listType,
		items,
		onRemove,
		showRemoveIcons,
		renderUploadList,
		size,
		themeType,
		className,
		multiple,
		showDownloadIcon,
		disabled,
	} = props;
	const renderList = () => {
		switch (listType) {
			case 'file':
				return (
					<FileList
						size={size}
						list={items}
						onRemove={onRemove}
						showRemoveIcons={showRemoveIcons}
						themeType={themeType}
						showDownloadIcon={showDownloadIcon}
						disabled={disabled}
					/>
				);
			case 'image':
				return (
					<ImageList
						size={size}
						list={items}
						onRemove={onRemove}
						showRemoveIcons={showRemoveIcons}
						themeType={themeType}
						className={className}
						multiple={multiple}
						showDownloadIcon={showDownloadIcon}
						disabled={disabled}
					/>
				);
			default:
				return (
					<FileList
						size={size}
						list={items}
						onRemove={onRemove}
						showRemoveIcons={showRemoveIcons}
						themeType={themeType}
						showDownloadIcon={showDownloadIcon}
						disabled={disabled}
					/>
				);
		}
	};
	if (items.length <= 0) {
		return null;
	}
	return (
		<div>
			{renderUploadList
				? renderUploadList({
						listType,
						items,
						onRemove,
						showRemoveIcons,
						showDownloadIcon,
				  })
				: renderList()}
		</div>
	);
}

UploadList.propTypes = {
	items: arrayOf(shape({})),
	listType: string,
	showRemoveIcons: bool,
	onRemove: func,
	renderUploadList: func,
	size: string.isRequired,
	multiple: bool,
	showDownloadIcon: bool,
	disabled: bool,
};

UploadList.defaultProps = {
	items: [],
	listType: 'file',
	showRemoveIcons: true,
	onRemove: () => {},
	renderUploadList: null,
	multiple: false,
	showDownloadIcon: true,
	disabled: false,
};

export default UploadList;
