import { useSelector } from '@cogoport/front/store';
import { useEffect, useCallback } from 'react';
import useRequest from '@/utils/request/useRequest';
import useDebounceQuery from '@/commons/form/hooks/useDebounceQuery';

function useListFaqQuestions({ selectedQuery = '' }) {
	const { profile } = useSelector((state) => state);

	const { debounceQuery, query: searchQuery = '' } = useDebounceQuery();

	const { loading, data, trigger } = useRequest(
		'get',
		false,
		'partner',
	)(`/cogo_academy/list_faq_questions`);

	const fetchFaqQuestions = useCallback(async () => {
		try {
			await trigger({
				params: {
					filters: {
						persona: 'channel_partner',
						cogo_entity_id: profile?.partner?.parent_entity_id,
						platform: 'partner',
						work_scope: 'all',
						country_id: profile?.partner?.country_id,
						q: searchQuery,
					},
				},
			});
		} catch (error) {
			console.log('error', error);
		}
	}, [trigger, searchQuery]);

	useEffect(() => {
		if (searchQuery) {
			fetchFaqQuestions();
		}
	}, [fetchFaqQuestions]);

	useEffect(() => {
		debounceQuery(selectedQuery);
	}, [selectedQuery]);

	return {
		faqListData: data || {},
		loading,
	};
}

export default useListFaqQuestions;
