import dynamic from 'next/dynamic';
import Links from './Links';
import { Container, Header, Main } from './styles';

const UserDetails = dynamic(() => import('@/commons/components/UserDetails'), {
	ssr: false,
});

function RenderHeader({ layout }) {
	const { header = {} } = layout;
	const {
		show = true,
		reDirectionLinks = [],
		showUserDetails = false,
	} = header;

	if (!show) {
		return null;
	}

	return (
		<Header className="right-panel__header">
			<Links links={reDirectionLinks} />
			{showUserDetails && <UserDetails />}
		</Header>
	);
}

function RenderMain({ activeComponentKey, mainComponent }) {
	return (
		<Main activeComponentKey={activeComponentKey} className="right-panel__main">
			{mainComponent}
		</Main>
	);
}

function RightPanel({
	activeComponentKey = '',
	panel = {},
	headerComponent = null,
	isbeforeRegistration = false,
	isTnCpage = false,
}) {
	const { config = {}, component: mainComponent = null } = panel;

	const { layout = {}, components = {} } = config;

	if (!(activeComponentKey in components)) {
		return null;
	}

	return (
		<Container
			className="right-panel"
			isbeforeRegistration={isbeforeRegistration}
			isTnCpage={isTnCpage}
			paddingTop={headerComponent ? '0' : '64px'}
		>
			{!['forgotPassword', 'setPassword'].includes(activeComponentKey) &&
				!headerComponent && <RenderHeader layout={layout} />}

			<RenderMain
				mainComponent={mainComponent}
				activeComponentKey={activeComponentKey}
			/>
		</Container>
	);
}

export default RightPanel;
