import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';

import { useEffect, useState } from 'react';
import useRequest from '@/utils/request/useRequest';

const useQuestionList = ({
	topic = {},
	search = '',
	from,
	question,
	setQuestion,
	query_name = undefined,
}) => {
	const {
		general: { scope = '' },
		profile,
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const { partner = {} } = profile;
	const {
		country_id = '',
		parent_entity_id = '',
		account_types = [],
	} = partner;

	const [page, setPage] = useState(1);

	const {
		data,
		loading = false,
		trigger,
	} = useRequest('get', false, scope)('/cogo_academy/list_faq_questions');

	const fetch = async () => {
		try {
			await trigger({
				params: {
					filters: {
						status: 'active',
						state: 'published',
						faq_topic_id: [topic?.id] || undefined,
						country_id,
						cogo_entity_id: parent_entity_id,
						persona: account_types?.[0],
						q: search || query_name || undefined,
					},
					is_test_ongoing: from === 'test_module' ? true : undefined,
					sort_by: 'view_count',
					page,
					faq_tags_data_required: true,
					faq_topics_data_required: true,
					related_questions_required: !!query_name,
				},
			});
		} catch (error) {
			if (error?.data) {
				Toast.error(getApiErrorString(error?.data));
			}
		}
	};

	useEffect(() => {
		if (question && !query_name) {
			return;
		}
		fetch();
	}, [page, query_name, question]);

	const {
		list = [],
		response_type,
		gpt_answer = '',
		show_more = '',
		...pageData
	} = data || {};
	return {
		page,
		setPage,
		pageData,
		loading,
		list,
		question,
		setQuestion,
		response_type,
		gpt_answer,
		show_more,
	};
};

export default useQuestionList;
