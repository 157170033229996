import { Upload, Toast } from '@cogoport/components';
import { IcMDocument, IcMUpload } from '@cogoport/icons-react';
import { isEmpty } from '@cogoport/front/utils';
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'next-i18next';
import Axios from 'axios';
import customAxios from '@/temp/request/useRequest/custom-axios';
import styles from './styles.module.css';

const publicRequest = Axios.create();

function FileUploader(props) {
	const {
		onChange,
		showProgress,
		multiple,
		docName,
		uploadIcon,
		uploadDesc,
		uploaderType = 'card',
		fileUploaderRef,
		...rest
	} = props;

	const { t } = useTranslation(['common']);

	const [fileName, setFileName] = useState(null); // remove
	const [loading, setLoading] = useState(true); // remove
	const [urlStore, setUrlStore] = useState([]);
	const [progress, setProgress] = useState({});

	useEffect(() => {
		if (multiple) {
			onChange(urlStore);
		} else {
			onChange(urlStore[0]);
		}
	}, [multiple, urlStore, onChange]);

	const onUploadProgress = (index) => (file) => {
		setProgress((previousProgress) => ({
			...previousProgress,
			[`${index}`]: (() => {
				const { loaded, total } = file;
				const percentCompleted = Math.floor((loaded * 100) / total);

				return percentCompleted;
			})(),
		}));
	};

	const uploadFile = (index) => async (file) => {
		const { data } = await customAxios({
			method: 'GET',
			url: '/get_media_upload_url',
			params: {
				file_name: file.name,
			},
		});

		const { url, headers } = data;

		await publicRequest({
			url,
			data: file,
			method: 'PUT',
			headers: {
				...headers,
				'Content-Type': file.type,
			},
			onUploadProgress: onUploadProgress(index),
		});

		const finalUrl = url.split('?')[0];

		return {
			fileName: file.name,
			finalUrl,
		};
	};

	const handleChange = async (values) => {
		try {
			setLoading(true);

			if (values.length > 0) {
				setProgress({});

				const promises = values.map((value, index) => uploadFile(index)(value));

				const allUrls = await Promise.all(promises);

				if (multiple) {
					setUrlStore((prev) => {
						if (prev === null) {
							return allUrls;
						}
						return [...prev, ...allUrls];
					});
					setFileName((prev) => {
						if (prev === null) return values;
						let prevValue = [];

						if (typeof prev !== 'object' || !Array.isArray(prev)) {
							prevValue = prev?.target?.value || [];
						}

						return [...prevValue, ...values];
					});
				} else {
					setUrlStore(allUrls);
					setFileName(values);
				}
			}
		} catch (error) {
			console.log(error, 'error');
			Toast.error(t('common:file_uploader_toast_error'));
		} finally {
			setLoading(false);
		}
	};

	const handleDelete = (values) => {
		setFileName(values);
		const files = Array.isArray(values) ? values?.map((item) => item.name) : [];
		const newUrls = urlStore.filter((item) => files.includes(item.fileName));
		setUrlStore(newUrls);
	};

	return (
		<>
			<Upload
				{...rest}
				value={fileName}
				multiple={multiple}
				onChange={handleChange}
				onClick={handleDelete}
				loading={loading}
				type={uploaderType}
				uploadDesc={uploadDesc || 'Upload files'}
				uploadIcon={
					uploadIcon || <IcMUpload color="#ACDADF" height={40} width={40} />
				}
				fileData={urlStore}
				ref={fileUploaderRef}
			/>

			{loading &&
				!isEmpty(progress) &&
				Object.keys(progress).map((key) => (
					<div className={styles.progress_container}>
						<IcMDocument
							style={{ height: '30', width: '30', color: '#2C3E50' }}
						/>
						<div>
							<div className={styles.file_name}>
								{`${t('common:file_uploading_message')} (${progress[key]}%)...`}
							</div>
							<div className={styles.progress_bar}>
								<div
									className={styles.progress}
									style={{ width: `${progress[key]}%` }}
								/>
							</div>
						</div>
					</div>
				))}
		</>
	);
}

export default FileUploader;
