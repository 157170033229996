export const getFileSizeExceedMessage = (maxSize, t) => {
	if (maxSize) {
		if (maxSize < 1024) {
			return `${t(
				'common:awsUploader_uploadList_fileSize_size',
			)} ${maxSize} ${t('common:awsUploader_uploadList_fileSize_bytes')}`;
		}
		if (maxSize >= 1024 && maxSize < 1048576) {
			const size = Math.ceil(Number(maxSize) / 1024);
			return `${t('common:awsUploader_uploadList_fileSize_size')} ${size} ${t(
				'common:awsUploader_uploadList_fileSize_kb',
			)}`;
		}
		if (maxSize >= 1048576 && maxSize < 107374182) {
			const size = Math.ceil(Number(maxSize) / (1024 * 1024));
			return `${t('common:awsUploader_uploadList_fileSize_size')} ${size} ${t(
				'common:awsUploader_uploadList_fileSize_mb',
			)}`;
		}
	}
	return null;
};
