import RedirectLink from '@/commons/components/RedirectLink';

import { Container } from './styles';

function Links({ links = [] }) {
	return (
		<Container>
			{links.map((link) => {
				const {
					key = '',
					show = false,
					label = '',
					linkLabel = '',
					href = '',
					withPrefix = false,
				} = link;

				if (!show) {
					return null;
				}

				return (
					<RedirectLink
						key={key}
						label={label}
						href={href}
						withPrefix={withPrefix}
						linkLabel={linkLabel}
					/>
				);
			})}
		</Container>
	);
}

export default Links;
