import { Pill } from '@cogoport/components';
import styles from './styles.module.css';

const STATE_MAPPING = {
	published: 'Answered',
	requested: 'Requested',
	draft: 'Requested',
};

function RequestedQuestion({
	item = {},
	selectedQuestion = {},
	setQuestion = () => {},
}) {
	const {
		requestedQuestion = '',
		time = '',
		state = '',
		is_viewed = '',
	} = item || {};

	const isRequestedQuestion = state === 'requested';

	function handleBackgroundColor() {
		if (state !== 'published') {
			return '#FEF199';
		}

		if (is_viewed) {
			return '#F7FAEF';
		}

		return '#C4DC91';
	}

	return (
		<div
			role="presentation"
			className={styles.question_time_container}
			style={{
				backgroundColor: !is_viewed ? '#fdfbf6' : '#ffffff',
				cursor: isRequestedQuestion ? 'not-allowed' : 'pointer',
			}}
			onClick={() => {
				if (!isRequestedQuestion) {
					setQuestion(selectedQuestion);
				}
			}}
		>
			<div className={styles.pills_question_wrapper}>
				<Pill color={handleBackgroundColor({ state, is_viewed })}>
					{STATE_MAPPING[state]}
				</Pill>

				<div className={styles.question_wrapper}>{requestedQuestion}</div>
			</div>

			<div className={styles.time_wrapper}>{time}</div>
		</div>
	);
}

export default RequestedQuestion;
