import { useCallback } from 'react';

import useRequest from '@/utils/request/useRequest';
import { useSelector } from '@cogoport/front/store';

const useCreateCustomerChatMapping = () => {
	const {
		general: { scope },
	} = useSelector((state) => state);

	const { trigger } = useRequest('post', false, scope, {
		autoCancel: false,
	})('/communication/create_customer_chat_mapping');

	const createMapping = useCallback(
		async ({ customer_chat_id = '' }) => {
			try {
				await trigger({
					data: {
						customer_chat_id,
						user_type: 'member',
						user_id: process.env.NEXT_PUBLIC_COGOVERSE_ID,
					},
				});
			} catch (err) {
				console.error(err);
			}
		},
		[trigger],
	);

	return {
		createMapping,
	};
};

export default useCreateCustomerChatMapping;
