import styled from '@cogoport/front/styled';

export const Component = styled.main`
	&.page-bg {
		background-image: url(/Globe.png);
		background-position: right top;
		background-repeat: no-repeat;
		background-size: auto;
	}

	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;
