import { css } from '@cogoport/front/styled';

const globalStyles = css`
	html,
	body {
		margin: 0;
		padding: 0;
		font-family: 'Roboto';
	}

	:root {
		--font-family-en: 'Poppins', sans-serif;
		--font-family-vi: 'Be Vietnam Pro', sans-serif;
	}

	* {
		box-sizing: border-box;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	#nprogress .bar {
		background: #ee3425 !important;
	}

	#nprogress .peg {
		box-shadow: 0 0 10px #ee3425, 0 0 5px #ee3425 !important;
	}

	#nprogress .spinner-icon {
		border-top-color: #ee3425 !important;
		border-left-color: #ee3425 !important;
	}
`;

export default globalStyles;
