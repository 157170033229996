import styles from './styles.module.css';
import CustomFileDiv from './CustomFileDiv';

const FILE_TYPE_MAPPING = {
	image: ({ mediaUrl }) => (
		// next/image require fixed height and width as required props.
		<img src={mediaUrl} alt="attachment" className={styles.object_styles} />
	),
	audio: ({ mediaUrl, extension }) => (
		<audio controls className={styles.object_styles}>
			<source src={mediaUrl} type={`audio/${extension}`} />
			<track src="" kind="captions" />
		</audio>
	),
	video: ({ mediaUrl, extension }) => (
		<video controls className={styles.object_styles}>
			<source src={mediaUrl} type={`video/${extension}`} />
			<track src="" kind="captions" />
		</video>
	),
	document: ({ mediaUrl }) => <CustomFileDiv mediaUrl={mediaUrl} />,
};

function FileViewMapping({ mediaUrl = '', extension = '', messageType = '' }) {
	const Media = FILE_TYPE_MAPPING[messageType] || null;

	if (!Media) {
		return null;
	}

	return (
		<div
			className={styles.clickable_object}
			role="presentation"
			onClick={() => {
				window.open(mediaUrl, '_blank', 'noreferrer');
			}}
		>
			<Media mediaUrl={mediaUrl} extension={extension} />
		</div>
	);
}

export default FileViewMapping;
