import { useSelector } from '@cogoport/front/store';
import { useEffect, useRef, useState } from 'react';
import {
	IcMLiveChat,
	IcMPlatformDemo,
	IcMPlatformchat,
} from '@cogoport/icons-react';

import { useTranslation } from 'next-i18next';

import useRequest from '@/utils/request/useRequest';
import FAQs from '../FAQs';
import { FlexContainer, Title } from './styles';
import useFetchRoom from '../WebBot/hooks/useFetchRoom';

import useSendMessage from '../WebBot/hooks/useSendMessage';
import usePublicSendMessage from '../WebBot/hooks/usePublicSendMessage';
import WebBot from '../WebBot';
import Demo from '../Demo';

const getWidgetMapping = ({
	cogobotLoading = false,
	sendMessage = () => {},
	sendMessageLoading = false,
	chatbotUserRoom = {},
	faqData = [],
	faqNotificationApiLoading = false,
	fetchFaqNotification = () => {},
	setIncreaseModalSize,
	increaseModalSize,
	isUnKnownUser = false,
	room_id = null,
	anonymousUserId = null,
}) => {
	return [
		{
			name: 'chat',
			tabs_title: 'Chat',
			tabs_sub_title: 'Send a message to our Support Manager',
			icon: IcMLiveChat,
			title: (
				<FlexContainer>
					<IcMLiveChat width={16} height={16} />
					<Title>Chat</Title>
				</FlexContainer>
			),
			component: WebBot,
			props: {
				roomId: room_id,
				cogobotLoading,
				sendMessage,
				sendMessageLoading,
				chatbotUserRoom,
				authUserId: anonymousUserId,
			},
			conditionToShow: true,
		},
		{
			name: 'assist',
			tabs_title: 'Help & FAQs',
			tabs_sub_title:
				'Use our Assist to know more about the platform, industry and other interesting facts',
			icon: IcMPlatformchat,
			title: (
				<FlexContainer>
					<IcMPlatformchat width={16} height={16} />
					<Title>Help & FAQs</Title>
				</FlexContainer>
			),
			component: FAQs,
			props: {
				faqNotificationData: faqData,
				faqNotificationApiLoading,
				refetch: fetchFaqNotification,
			},
			conditionToShow: !isUnKnownUser,
		},
		{
			name: 'demo',
			tabs_title: 'Platform Demo',
			tabs_sub_title: 'Know how to use the Platform with these demos',
			icon: IcMPlatformDemo,
			title: (
				<FlexContainer>
					<IcMPlatformDemo width={16} height={16} />
					<Title>Demo</Title>
				</FlexContainer>
			),
			component: Demo,
			props: {
				setIncreaseModalSize,
				increaseModalSize,
			},
			conditionToShow: !isUnKnownUser,
		},
	];
};

const useFloatingWidget = ({ anonymousUserId, supabaseClient }) => {
	const { profile } = useSelector((state) => state);

	const { t } = useTranslation(['common']);

	const {
		id: user_id = null,
		partner,
		lead_user_id = '',
		name: userName = '',
	} = profile;

	const {
		id: partnerId,
		twin_importer_exporter_id = '',
		twin_service_provider_id = '',
	} = partner || {};

	const isUnKnownUser = !partnerId;

	const [activeTab, setActiveTab] = useState('');
	const [show, setShow] = useState(false);
	const [cogobotLoading, setCogobotLoading] = useState(true);

	const [chatbotUserRoom, setChatbotUserRoom] = useState({});

	const [increaseModalSize, setIncreaseModalSize] = useState({});

	const {
		sendMessage: publicSendMessage = () => {},
		loading: publicSendMessageLoading,
	} = usePublicSendMessage();

	const { sendMessage = () => {}, loading: sendMessageLoading } =
		useSendMessage({
			user_id,
			organization_id:
				twin_importer_exporter_id || twin_service_provider_id || '',
			lead_user_id,
		});

	const {
		toggleUserChat,
		getInitialRoomData,
		fetchRealtimeRoom,
		cogoBotState,
		fetchRoomRef,
	} = useFetchRoom({
		profile,
		sendMessage: isUnKnownUser ? publicSendMessage : sendMessage,
		t,
		isUnKnownUser,
		setCogobotLoading,
		setChatbotUserRoom,
		anonymousUserId,
		supabaseClient,
	});

	const { isRoomCreated = false, room_id = null } = cogoBotState || {};

	const faqNotificationApi = useRequest(
		'get',
		false,
		'partner',
	)('/cogo_academy/get_faq_notification');

	const fetchFaqNotification = () => {
		try {
			faqNotificationApi.trigger();
		} catch (e) {
			console.log(e);
		}
	};

	const { data = {}, loading: faqNotificationApiLoading } =
		faqNotificationApi || {};

	const { notification_details: faqData = [] } = data || {};

	const isDraggingRef = useRef(false);

	const onStart = () => {
		isDraggingRef.current = true;
	};

	const onDrag = () => {
		isDraggingRef.current = true;
	};

	const onStop = () => {
		isDraggingRef.current = false;
	};

	const handleOpenOptions = () => {
		if (!isDraggingRef.current) {
			setShow((prevValue) => !prevValue);

			toggleUserChat();
		}
	};

	const widgetMapping = getWidgetMapping({
		anonymousUserId,
		cogobotLoading,
		sendMessage: isUnKnownUser ? publicSendMessage : sendMessage,
		sendMessageLoading: isUnKnownUser
			? publicSendMessageLoading
			: sendMessageLoading,
		chatbotUserRoom,
		faqData,
		faqNotificationApiLoading,
		setIncreaseModalSize,
		increaseModalSize,
		fetchFaqNotification,
		isUnKnownUser,
		room_id,
	});

	const showWidget = widgetMapping.some((item) => item?.conditionToShow);

	const totalMessages =
		Number(cogoBotState?.newMessageCount) + Number(faqData?.length);

	useEffect(() => {
		const abortMessagesControl = new AbortController();

		getInitialRoomData({ abortMessagesControl });

		fetchRealtimeRoom();
		return () => {
			if (fetchRoomRef.current) {
				supabaseClient.removeChannel(fetchRoomRef.current);
			}
			abortMessagesControl?.abort?.();
		};
	}, [fetchRealtimeRoom, getInitialRoomData, supabaseClient, anonymousUserId]);

	useEffect(() => {
		if (!isUnKnownUser) {
			fetchFaqNotification();
		}
	}, [isUnKnownUser]);

	useEffect(() => {
		if (isRoomCreated) {
			setActiveTab('chat');
		}
	}, []);

	return {
		room_id,
		userName,
		showWidget,
		onStart,
		onStop,
		onDrag,
		totalMessages,
		handleOpenOptions,
		widgetMapping,
		show,
		setShow,
		isDraggingRef,
		toggleUserChat,
		activeTab,
		setActiveTab,
		increaseModalSize,
		setIncreaseModalSize,
		isRoomCreated,
	};
};

export default useFloatingWidget;
