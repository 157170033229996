import { useCallback } from 'react';

import useLegacyRequest from '../utils/getCustomAxios';

const useCreatePublicCustomerChatMapping = () => {
	const [, trigger] = useLegacyRequest({
		url: '/create_public_customer_chat_mapping',
		method: 'post',
	});

	const createPublicMapping = useCallback(
		async ({ customer_chat_id = '' }) => {
			try {
				await trigger({
					data: {
						customer_chat_id,
						auth_token: process.env.NEXT_PUBLIC_COGOVERSE_AUTH_TOKEN,
					},
				});
			} catch (err) {
				console.error(err);
			}
		},
		[trigger],
	);

	return {
		createPublicMapping,
	};
};

export default useCreatePublicCustomerChatMapping;
