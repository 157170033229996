import styled from '@cogoport/front/styled';

export const Container = styled.section`
	&.left-panel {
		height: 100vh;

		padding-top: ${(props) => props.paddingTop};
		/* padding-bottom: 80px; */

		position: relative;

		display: flex;
		flex-direction: column;

		position: relative;

		${(props) => {
			const { background = '' } = props;

			if (!background) return '';

			return `background: ${background};`;
		}}
	}

	& .left-panel__header {
		${(props) => props.showHeader && 'display: none'}
	}
`;

export const Header = styled.header`
	&.left-panel__header {
		height: 52px;
		background-color: inherit;

		position: fixed;
		top: 0;
		left: 0;
		right: calc(100% - 40%);

		z-index: 2;

		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px;

		@media (min-width: 768px) {
			padding-right: 32px;
			padding-left: 32px;
		}
		@media (max-width: 768px) {
			background: transparent;
		}
		@media (max-width: 420px) {
			display: none;
		}
	}
`;

export const Icon = styled.a`
	&.left-panel__icon {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&.left-panel__icon--cogoport {
		margin-top: 32px;

		& svg {
			width: 130px;
			min-height: 38px;
		}
	}

	&.left-panel__icon--globe {
		position: fixed;
		bottom: -160px;

		& svg {
			width: 100%;
			height: 500px;
		}
	}
`;

export const Main = styled.main`
	&.left-panel__main {
		padding: 0 16px;
		overflow-y: auto;
		z-index: 1;

		@media (min-width: 768px) {
			padding: 0 32px;
		}
		@media (max-width: 768px) {
			display: none;
		}
	}
`;

export const BackgroundImage = styled.div`
	${(props) => {
		const { backgroundImageUrl = '', backgroundImageHeight = 0 } = props;

		if (!backgroundImageUrl) {
			return '';
		}

		return `
			height: ${backgroundImageHeight}px;
			width: 100%;

			position: absolute;
			bottom: 0;
			margin-left: 24px;

			background-image: url('${backgroundImageUrl}');
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
		`;
	}}
`;

export const GlobeIcon = styled.div`
	z-index: 0;
	position: absolute;
	bottom: -10%;
	right: -20%;

	width: 300px;
	height: 300px;

	background-color: transparent;
	background-image: url(/Globe.png);
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
`;
