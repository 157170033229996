import { useEffect, useState } from 'react';
import { useSelector } from '@cogoport/front/store';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import useRequest from '@/temp/request/useRequest';
import { useDebounceQuery } from '@/commons/form';
import { useRouter } from '@/temp/next';

const getParams = ({ searchChapter }) => {
	return {
		filters: {
			status: 'active',
			course_sub_module_id: GLOBAL_CONSTANTS.uuid.demo_course_sub_module_id,
			q: searchChapter,
		},
	};
};

const useGetDemoVideos = () => {
	const {
		profile: {
			partner: { country_id },
		},
	} = useSelector((state) => state);

	const router = useRouter();
	const { trigger, loading, data } = useRequest(
		'get',
		true,
		'partner',
	)('/cogo_academy/list_course_sub_module_chapters');

	const { query = '', debounceQuery } = useDebounceQuery();

	const [searchChapter, setSearchChapter] = useState('');
	const [page, setPage] = useState(1);

	const getCourses = async () => {
		try {
			await trigger({
				params: getParams({ searchChapter }),
			});
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getCourses();
	}, [query, page]);

	const { list = [], ...pageData } = data || {};

	const handleSearch = (event) => {
		setSearchChapter(event);
		debounceQuery(event);
	};

	const { chapter_id } = router.query;

	const selectedChapterIndex = data?.list?.findIndex(
		(item) => item?.id === chapter_id,
	);
	const selectedChapter = data?.list?.find((item) => item?.id === chapter_id);

	const [indexes, setIndexes] = useState({
		moduleIndex: 0,
		subModuleIndex: 0,
		chapterIndex: selectedChapterIndex || 0,
		isNew: true,
	});

	const [chapter, setChapter] = useState(() => selectedChapter || list?.[0]);

	useEffect(() => {
		setChapter(selectedChapter);
	}, [selectedChapter]);

	return {
		chapterData: list,
		loading,
		chapter,
		setIndexes,
		indexes,
		setChapter,
		setSearchChapter: handleSearch,
		searchChapter,
		pageData,
		page,
		setPage,
		country_id,
	};
};

export default useGetDemoVideos;
