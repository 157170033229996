import { IcMSend, IcMAttach, IcMHappy, IcMCross } from '@cogoport/icons-react';
import React, { useState } from 'react';
import AwsUploader from '@/temp/form/components/Business/AwsUploader';
import { isEmpty } from '@cogoport/front/utils';
import { Popover, cl } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import { Image } from '@/temp/next';
import getFileAttributes from '../../../../utils/getFileAttributes';
import EmojisBody from './EmojisBody';
import styles from './styles.module.css';
import useGetEmojiList from '../../hooks/useGetEmojis';

const getUploadIcon = () => {
	return <IcMAttach className={styles.no_upload} />;
};

function FooterChat({ sendMessageLoading, sendFirebaseMessage, toggleHeight }) {
	const { t } = useTranslation(['common']);

	const [messageData, setMessageData] = useState({
		message: '',
		file: {},
		uploadingFile: false,
	});

	const handleChange = (obj) => {
		if (obj?.success) {
			setMessageData((p) => ({
				...p,
				uploadingFile: false,
				file: obj,
			}));
			return;
		}
		toggleHeight({ isFileDivPresent: false });
	};

	const handleProgress = (obj) => {
		if (obj?.type === 'progress') {
			setMessageData((p) => ({
				...p,
				uploadingFile: true,
			}));
			toggleHeight({ isFileDivPresent: true });
		}
	};

	const setEmojis = (val) => {
		setMessageData((p) => ({ ...p, message: `${p.message || ''}${val}` }));
	};

	const { file = {}, uploadingFile = false, message = '' } = messageData || {};

	const handleSend = () => {
		sendFirebaseMessage({
			message,
			type: 'text',
			file,
			reset: () => {
				setMessageData({
					message: '',
					file: {},
					uploadingFile: false,
				});
				toggleHeight({ isFileDivPresent: false });
			},
		});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();
			handleSend();
		}
	};

	const {
		emojisList = {},
		setOnClicked = () => {},
		onClicked,
	} = useGetEmojiList();

	const { fileIcon } = getFileAttributes({
		fileName: file?.name,
		finalUrl: file?.urls,
	});

	return (
		<>
			<div
				className={cl`${styles.show_file} ${
					uploadingFile || !isEmpty(file) ? styles.height_transition : ''
				}`}
			>
				{uploadingFile && (
					<div className={styles.uploading}>
						{t('common:components_webBot_components_chat')}
					</div>
				)}
				{!isEmpty(file) && (
					<div className={styles.uploaded_file}>
						{fileIcon}
						<div className={styles.file_name}>{file?.name}</div>
						<IcMCross
							className={styles.cross_icon}
							onClick={() => {
								setMessageData((p) => ({
									...p,
									file: {},
								}));
								toggleHeight({ isFileDivPresent: false });
							}}
						/>
					</div>
				)}
			</div>
			<div className={styles.bot_footer}>
				{!isEmpty(file) || uploadingFile ? (
					<IcMAttach className={styles.no_upload} />
				) : (
					<AwsUploader
						showProgress={false}
						hideUploadedList
						showIconAlways
						onProgress={handleProgress}
						onChange={handleChange}
						uploadIcon={getUploadIcon}
						drag
					/>
				)}
				<Popover
					theme="light"
					animation="shift-away"
					placement="top"
					content={
						<EmojisBody
							emojisList={emojisList}
							setOnClicked={setOnClicked}
							updateMessage={setEmojis}
						/>
					}
					visible={onClicked}
					maxWidth={355}
					onClickOutside={() => setOnClicked(false)}
				>
					<IcMHappy
						className={styles.emoji}
						onClick={() => setOnClicked((p) => !p)}
					/>
				</Popover>
				<textarea
					className={styles.chat_input}
					placeholder={t('common:components_webBot_components_footerChat')}
					onChange={(e) =>
						setMessageData((p) => ({
							...p,
							message: e.target.value,
						}))
					}
					onKeyPress={(e) => handleKeyPress(e)}
					value={message}
					rows="2"
					cols="50"
				/>
				{!sendMessageLoading ? (
					<IcMSend
						className={styles.send_icon}
						onClick={handleSend}
						style={{
							cursor: !(message || !isEmpty(file)) ? 'not-allowed' : 'pointer',
						}}
					/>
				) : (
					<Image
						src={GLOBAL_CONSTANTS.image_urls.loader}
						alt="loading"
						className={styles.send_icon}
						width={25}
						height={25}
					/>
				)}
			</div>
		</>
	);
}
export default FooterChat;
