import dynamic from 'next/dynamic';

import { useSelector } from '@cogoport/front/store';
import Header from '@/commons/components/Header';

import { Main } from './styles';

const Footer = dynamic(() => import('./Footer'), {
	ssr: false,
});

const NavBar = dynamic(() => import('./NavBar'), {
	ssr: false,
});

function AppLayout({ children, mobile }) {
	const { isMobile, pathname } = useSelector(({ general }) => general);

	const hideHeader = isMobile && mobile?.hideHeader;
	const hideFooter = mobile?.hideFooter;

	return (
		<>
			{!hideHeader && (
				<Header showSupportHelp showUserDetails showAnnouncements showDemo>
					{!isMobile && <NavBar />}
				</Header>
			)}

			<Main pathname={pathname}>{children}</Main>

			{isMobile && !hideFooter && <Footer />}
		</>
	);
}

export default AppLayout;
