import { IcMVideoCall } from '@cogoport/icons-react';
import { useSelector } from '@cogoport/front/store';
import { useCallback } from 'react';
import styles from './styles.module.css';
import useCreateVideoCallTimeline from '../../hooks/useCreateVideoCallTimeline';

function CallingFeature({ chatbotUserRoom = {} }) {
	const { inVideoCall = false, isMobile = false } = useSelector(
		({ profile, general }) => ({
			inVideoCall: profile?.is_in_video_call,
			isMobile: general?.isMobile,
		}),
	);

	const { createVideoCallTimeline } = useCreateVideoCallTimeline({
		chatbotUserRoom,
	});
	const { user_id, lead_user_id } = chatbotUserRoom || {};

	const mountVideoCall = useCallback(async () => {
		if (!inVideoCall) {
			await createVideoCallTimeline({
				userCallId: user_id,
				leadUserId: lead_user_id,
			});
		}
	}, [createVideoCallTimeline, inVideoCall, lead_user_id, user_id]);

	if (!isMobile) {
		return (
			<IcMVideoCall
				className={styles.video_call_icon}
				onClick={mountVideoCall}
			/>
		);
	}
	return null;
}

export default CallingFeature;
