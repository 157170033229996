function asyncFieldsLocations2() {
	return {
		valueKey: 'id',
		labelKey: 'name',
		endpoint: 'list_locations_v2',
		initialCall: true,
		params: {
			filters: { status: 'active' },
			page_limit: 20,
			includes: { country: null, default_params_required: true },
		},
	};
}

function asyncFieldsLocations() {
	return {
		valueKey: 'id',
		labelKey: 'name',
		endpoint: 'list_locations',
		initialCall: true,
		params: {
			filters: { status: 'active' },
			page_limit: 10,
			sort_by: 'name',
			sort_type: 'asc',
			includes: { country: null, default_params_required: true },
		},
	};
}
function asyncFieldsPartner() {
	return {
		labelKey: 'business_name',
		valueKey: 'id',
		endpoint: 'list_partners',
		initialCall: true,
		params: {
			filters: {
				status: 'active',
			},
			page_limit: 100,
		},
	};
}

function asyncFieldsPartnerRoles() {
	return {
		labelKey: 'name',
		valueKey: 'id',
		endpoint: 'list_roles',
		microService: 'auth',
		initialCall: true,
		params: {
			filters: { status: 'active' },
			page_limit: 100,
		},
	};
}

function asyncFieldsShippingLines() {
	return {
		valueKey: 'id',
		labelKey: 'short_name',
		endpoint: 'list_operators',
		initialCall: true,
		params: {
			filters: { operator_type: 'shipping_line', status: 'active' },
			page_limit: 100,
			sort_by: 'short_name',
			sort_type: 'asc',
		},
	};
}

function asyncFieldsAirLines() {
	return {
		valueKey: 'id',
		labelKey: 'short_name',
		endpoint: 'list_operators',
		initialCall: true,
		params: {
			filters: { operator_type: 'airline', status: 'active' },
			page_limit: 100,
			sort_by: 'short_name',
			sort_type: 'asc',
		},
	};
}
function asyncFieldsOrganizationUsers() {
	return {
		labelKey: 'name',
		valueKey: 'id',
		endpoint: 'list_organization_users',
		initialCall: true,
		params: {
			filters: {
				status: 'active',
				page_limit: 100,
			},
		},
	};
}

function asyncFieldsTicketTypes() {
	return {
		labelKey: 'TicketType',
		valueKey: 'TicketType',
		endpoint: 'default_types',
		authkey: 'get_tickets_default_types',
		microService: 'tickets',
		initialCall: true,
		qFilterKey: 'QFilter',
		listKey: 'items',
	};
}

function asyncTicketsCategory() {
	return {
		labelKey: 'category',
		valueKey: 'category',
		endpoint: 'configuration_categories',
		authkey: 'get_tickets_configuration_categories',
		microService: 'tickets',
		initialCall: true,
		qFilterKey: 'QFilter',
		listKey: 'items',
		searchByq: true,
	};
}

function asyncFieldsTradePartyKycStatusDetails() {
	return {
		valueKey: 'registration_number',
		labelKey: 'business_name',
		endpoint: 'list_organization_trade_parties',
		initialCall: true,
		params: {
			filters: { status: 'active' },
			page_limit: 10,
		},
	};
}

function asyncFieldsShipmentList() {
	return {
		valueKey: 'serial_id',
		labelKey: 'serial_id',
		endpoint: 'list_shipments',
		initialCall: true,
		params: {
			page_limit: 10,
		},
	};
}
function asyncListHsCodesAll() {
	return {
		labelKey: 'description',
		valueKey: 'code',
		endpoint: 'saas/hs-code/all',
		authkey: 'get_saas_hs_code_all',
		initialCall: false,
		params: {
			page_limit: 10,
		},
	};
}

function asyncFieldsPartnerUsers() {
	return {
		labelKey: 'name',
		valueKey: 'id',
		endpoint: 'list_partner_users',
		initialCall: true,
		params: {
			filters: {
				status: 'active',
			},
			page_limit: 100,
		},
	};
}
function asyncOrganizationTradeParties() {
	return {
		labelKey: 'display_name',
		valueKey: 'registration_number',
		endpoint: 'list_organization_trade_parties',
		initialCall: true,
	};
}
function asyncSearchProducts() {
	return {
		labelKey: 'item_name',
		valueKey: 'code',
		endpoint: 'search_products_v2',
		initialCall: true,
	};
}

function asyncFieldsLocationsMapping() {
	return {
		valueKey: 'id',
		labelKey: 'name',
		endpoint: 'list_locations_mapping',
		initialCall: true,
		params: {
			page_limit: 20,
		},
	};
}

export {
	asyncFieldsLocations,
	asyncFieldsLocations2,
	asyncFieldsPartner,
	asyncFieldsPartnerRoles,
	asyncFieldsShippingLines,
	asyncFieldsAirLines,
	asyncFieldsOrganizationUsers,
	asyncFieldsTicketTypes,
	asyncFieldsTradePartyKycStatusDetails,
	asyncTicketsCategory,
	asyncFieldsShipmentList,
	asyncListHsCodesAll,
	asyncFieldsPartnerUsers,
	asyncOrganizationTradeParties,
	asyncSearchProducts,
	asyncFieldsLocationsMapping,
};
