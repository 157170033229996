import GLOBAL_CONSTANTS from '@/constants/globals.json';
import formatDate from '@/temp/utils/formatDate';
import useRequest from '@/utils/request/useRequest';
import { useDispatch, useSelector } from '@cogoport/front/store';

import { setProfileStoreState } from '@/utils/stores';

import { useCallback } from 'react';

const getPayload = ({ userId = '', leadUserId = '', supportAgentId }) => {
	return {
		agent_id: supportAgentId,
		user_id: userId,
		provider_name: 'web_rtc',
		lead_user_id: leadUserId,
		source: 'channel_partner',
		start_stamp: formatDate({
			date: new Date(),
			dateFormat: GLOBAL_CONSTANTS.formats.date['yyyy-MM-dd'],
			timeFormat: GLOBAL_CONSTANTS.formats.time['HH:mm:ss'],
			formatType: 'dateTime',
			separator: ' ',
		}),
	};
};

const useCreateVideoCallTimeline = ({ chatbotUserRoom = {} }) => {
	const profile = useSelector((state) => state.profile || {});

	const { id: userId } = profile || {};

	const dispatch = useDispatch();

	const { loading, data, trigger } = useRequest(
		'post',
		false,
		'partner',
	)('/voice_call/create_outgoing_call');

	const { support_agent_id: supportAgentId } = chatbotUserRoom || {};

	const createVideoCallTimeline = useCallback(
		async ({ leadUserId = '' }) => {
			const payload = getPayload({ userId, leadUserId, supportAgentId });

			try {
				const res = await trigger({ data: payload });

				if (res?.data?.call_record_id) {
					dispatch(
						setProfileStoreState({
							video_call_recipient_data: {
								user_id: supportAgentId,
								user_name: 'Cogoport Agent',
							},
							is_in_video_call: true,
							video_call_id: res?.data?.call_record_id,
						}),
					);
				}
			} catch (e) {
				console.error(e);
			}
		},
		[dispatch, supportAgentId, trigger],
	);

	return {
		createVideoCallTimeline,
		loading,
		videoCallId: data?.call_record_id || '',
	};
};

export default useCreateVideoCallTimeline;
