export const COMMODITY_NAME_MAPPING = {
	general: {
		name: 'General',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	hazardous: {
		name: 'Hazardous',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	white_goods: {
		name: 'White Goods',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	pta: {
		name: 'PTA',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	cotton_and_yarn: {
		name: 'Cotton and yarn',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	fabric_and_textiles: {
		name: 'Fabric and textiles',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	sugar_rice: {
		name: 'Sugar Rice',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	chilled: {
		name: 'Chilled',
		description: '',
		is_reefer: true,
		is_haz: false,
	},
	frozen: {
		name: 'Frozen',
		description: '',
		is_reefer: true,
		is_haz: false,
	},
	pharma: {
		name: 'Pharma',
		description: '',
		is_reefer: true,
		is_haz: false,
	},
	in_gauge_cargo: {
		name: 'In Gauge Cargo',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	non_haz_solids: {
		name: 'Non Hazardous Solids',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	non_haz_liquids: {
		name: 'Non Hazardous Liquids',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	non_haz_gases: {
		name: 'Non Hazardous Gases',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	'gases-2.1': {
		name: 'Gases 2.1',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'gases-2.2': {
		name: 'Gases 2.2',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'gases-2.3': {
		name: 'Gases 2.3',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'flammable_liquids-3': {
		name: 'Flammable Liquids 3',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'flammable_solids-4.1': {
		name: 'Flammable Solids 4.1',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'flammable_solids_self_heat-4.2': {
		name: 'Flammable Solids 4.2',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'emit_flammable_gases_with_water-4.3': {
		name: 'Emit Flammable Gases with Water 4.3',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'imo_classes-5.1': {
		name: 'IMO Classes 5.1',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'toxic_substances-6.1': {
		name: 'Toxic Substances 6.1',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'infectious_substances-6.2': {
		name: 'Infectious Substances 6.2',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'radioactive_material-7': {
		name: 'Radioactive material 7',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'corrosives-8': {
		name: 'Corrosives 8',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	'miscellaneous_dangerous_goods-9': {
		name: 'Miscellaneous dangerous goods 9',
		description: '',
		is_reefer: false,
		is_haz: true,
	},
	express: {
		name: 'Express',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	perishable: {
		name: 'Perishable',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	live_animals: {
		name: 'Live animals',
		description: '',
		is_reefer: false,
		is_haz: false,
	},

	fmcg: {
		name: 'FCMG',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	fmcg_consumer_durables: {
		name: 'FCMG Consumer Durables',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	consumer_durables_equipments: {
		name: 'Consumer Durables Equipments',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	consumer_durables_equipments_machinery: {
		name: 'Consumer Durables Equipments Machinery',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	equipments_plant_machinery: {
		name: 'Equipments Plant Machinery',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
	consumer_durables: {
		name: 'Consumer Durables',
		description: '',
		is_reefer: false,
		is_haz: false,
	},
};

export const HAZ_CLASSES = [
	'gases-2.1',
	'gases-2.2',
	'gases-2.3',
	'flammable_liquids-3',
	'flammable_solids-4.1',
	'flammable_solids_self_heat-4.2',
	'emit_flammable_gases_with_water-4.3',
	'imo_classes-5.1',
	'toxic_substances-6.1',
	'infectious_substances-6.2',
	'radioactive_material-7',
	'corrosives-8',
	'miscellaneous_dangerous_goods-9',
];

const FAK_COMMODITY = 'general';
const FAK_HAZARDOUS = 'hazardous';
const STANDARD_COMMODITIES = [
	'white_goods',
	'pta',
	'cotton_and_yarn',
	'fabric_and_textiles',
	'sugar_rice',
];
const REFER_COMMODITIES = ['chilled', 'frozen', 'pharma'];
const OPEN_TOP_COMMODITIES = ['in_gauge_cargo'];
const FLAT_RACK_COMMODITIES = ['in_gauge_cargo'];
const OPEN_SIDE_COMMODITIES = ['general'];
const ISO_TANK_COMMODITIES = [
	'non_haz_solids',
	'non_haz_liquids',
	'non_haz_gases',
];

export const COMMODITY_SURCHARGE_CONTAINER_COMMODITY_MAPPINGS = {
	standard: [...STANDARD_COMMODITIES, ...HAZ_CLASSES],
	refer: REFER_COMMODITIES,
	open_top: OPEN_TOP_COMMODITIES,
	flat_rack: FLAT_RACK_COMMODITIES,
	open_side: OPEN_SIDE_COMMODITIES,
	iso_tank: ISO_TANK_COMMODITIES,
};

export const LOCAL_CONTAINER_COMMODITY_MAPPINGS = {
	standard: [null, ...HAZ_CLASSES],
	refer: [null],
	open_top: [null],
	flat_rack: [null],
	open_side: [null],
	iso_tank: [null, ...HAZ_CLASSES],
};

export const FREIGHT_CONTAINER_COMMODITY_MAPPINGS = {
	standard: [FAK_COMMODITY, ...STANDARD_COMMODITIES, ...HAZ_CLASSES],
	refer: REFER_COMMODITIES,
	open_top: OPEN_TOP_COMMODITIES,
	open_side: OPEN_SIDE_COMMODITIES,
	flat_rack: FLAT_RACK_COMMODITIES,
	iso_tank: [...ISO_TANK_COMMODITIES, ...HAZ_CLASSES],
};

export const FCL_CUSTOMS_CONTAINER_COMMODITY_MAPPING = {
	standard: [null, ...STANDARD_COMMODITIES, ...HAZ_CLASSES],
	refer: [null],
	open_top: [null],
	flat_rack: [null],
	open_side: [null],
	iso_tank: [null, ...HAZ_CLASSES],
};

export const AIR_EXPRESS_COMMODITIES = ['express'];

export const AIR_STANDARD_COMMODITIES = [
	'general',
	'perishable',
	'live_animals',
	'pharma',
];

export const AIR_HAZARDOUS_COMMODITIES = ['hazardous'];

export const AIR_LOCAL_COMMODITIES = [
	...AIR_STANDARD_COMMODITIES,
	...AIR_HAZARDOUS_COMMODITIES,
];

export const AIR_FREIGHT_COMMODITIES = [
	...AIR_EXPRESS_COMMODITIES,
	...AIR_STANDARD_COMMODITIES,
	...AIR_HAZARDOUS_COMMODITIES,
];

export const LCL_FRIEGHT_COMMODITIES = [
	'general',
	'gases-2.1',
	'gases-2.2',
	'gases-2.3',
	'flammable_liquids-3',
	'flammable_solids-4.1',
	'flammable_solids_self_heat-4.2',
	'imo_classes-5.1',
	'emit_flammable_gases_with_water-4.3',
	'toxic_substances-6.1',
	'infectious_substances-6.2',
	'radioactive_material-7',
	'corrosives-8',
	'miscellaneous_dangerous_goods-9',
];

export const ALL_FCL_COMMODITIES = [
	FAK_COMMODITY,
	...STANDARD_COMMODITIES,
	...HAZ_CLASSES,
];

export const FTL_COMMODITIES = [
	...HAZ_CLASSES,
	'fmcg',
	'fmcg_consumer_durables',
	'consumer_durables',
	'consumer_durables_equipments',
	'consumer_durables_equipments_machinery',
	'equipments_plant_machinery',
];
