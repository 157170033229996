import { toast } from '@cogoport/front/components';
import { getCookie } from '@/temp/cogo-cookies';
import { useTranslation } from 'next-i18next';
import useLegacyRequest from '../utils/getCustomAxios';

const useSendMessage = () => {
	const COGOVERSE_ID = process.env.NEXT_PUBLIC_COGOVERSE_ID;

	const { t } = useTranslation(['common']);

	const [{ loading }, trigger] = useLegacyRequest({
		url: 'communication/create_communication_public_platform_chat',
		method: 'POST',
	});

	const sendMessage = async ({
		messageMetaData,
		conversation_type = '',
		updateFirestore = () => {},
	}) => {
		const sender = getCookie('cogo_bot_token') || '';
		let payload = {};
		if (conversation_type === 'outward') {
			payload = {
				sender_user_id: COGOVERSE_ID,
				recipient: sender,
			};
		} else {
			payload = {
				sender,
			};
		}
		try {
			await trigger({
				data: {
					auth_token: process.env.NEXT_PUBLIC_COGOVERSE_AUTH_TOKEN,
					type: 'platform_chat',
					message_metadata: messageMetaData,
					service: 'user',
					conversation_type: conversation_type || 'inward',
					service_id: COGOVERSE_ID,
					source: 'CogoOne:PublicPlatform',
					...(payload || {}),
				},
			});
			updateFirestore();
		} catch (err) {
			toast.error(t('common:public_send_message_toast_error'));
		}
	};
	return {
		sendMessage,
		loading,
	};
};
export default useSendMessage;
