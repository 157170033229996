import { useState } from 'react';
import { Toast } from '@cogoport/components';
import { useSelector } from '@cogoport/front/store';
import { useTranslation } from 'next-i18next';
import useRequest from '@/utils/request/useRequest';

const translationKey = 'common:components_header_tickets_api';

const useCreateTicket = () => {
	const { t } = useTranslation(['common']);

	const {
		partner: {
			twin_importer_exporter_id = '',
			twin_service_provider_id = '',
			country_id = '',
			parent_entity_id = '',
		} = {},
		id = '',
	} = useSelector(({ profile }) => profile);

	const [ticketId, setTicketId] = useState('');

	const { loading, trigger, data } = useRequest('post', false, 'cogocare', {
		authKey: 'post_tickets_ticket',
	})(`/ticket`);

	const createTicket = async (val, extraField = '') => {
		try {
			const payload = {
				UserID: id,
				OrganizationID:
					twin_importer_exporter_id || twin_service_provider_id || undefined,
				Source: 'channel_partner',
				Type: val?.ticket_type || undefined,
				Description: val?.description || undefined,
				Data: {
					Attachment: val?.file_urls,
					[extraField]: extraField ? val?.[extraField] : undefined,
					Persona: 'channel_partner',
					Platform: 'partner',
					RequestType: val?.request_type || undefined,
					Service: val?.service_type || undefined,
					TradeType: val?.trade_type || undefined,
					SID: val?.serial_id || undefined,
				},
				CogoEntityId: parent_entity_id,
				PerformedByID: id,
				NotifyCustomer: true,
				CountryId: country_id,
			};

			const res = await trigger({
				data: payload,
			});

			Toast.success(t(`${translationKey}_create_success`));
			setTicketId(res?.data?.ID);
		} catch (e) {
			Toast.error(e?.error || t(`${translationKey}_error`));
		}
	};

	return {
		createTicket,
		loading,
		data,
		ticketId,
		setTicketId,
	};
};

export default useCreateTicket;
