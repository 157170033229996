import { useTranslation } from 'next-i18next';
import { IcMEmail, IcMCall } from '@cogoport/icons-react';
import { Tooltip } from '@cogoport/components';
import { useEffect } from 'react';
import { Image } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import styles from './styles.module.css';
import SupportTypes from './SupportTypes';
import useGetTicketsUnreadCount from '../../hooks/useGetTicketsUnreadCount';

const translationKey = 'common:components_header_help_support';

// const default_agent = ({ t }) => ({
// 	name: t(`${translationKey}_name`),
// 	email: t(`${translationKey}_email`),
// 	mobile_country_code: t(`${translationKey}_countrycode`),
// 	mobile_number: t(`${translationKey}_mobilenumber`),
// 	mobile_number_eformat: `${t(`${translationKey}_countrycode`)}${t(
// 		`${translationKey}_mobilenumber`,
// 	)}`,
// });

function Support({
	// agent = {},
	showPopover = false,
	setShowPopover = () => {},
	setModalData = () => {},
}) {
	const { t } = useTranslation(['common']);
	// const agentKey = isEmpty(agent) ? default_agent({ t }) : agent;

	const { unreadCount, getUnreadTicketsCount } = useGetTicketsUnreadCount();

	useEffect(() => {
		if (showPopover) {
			getUnreadTicketsCount();
		}
	}, [showPopover]);

	return (
		<div className={styles.container}>
			<div className={styles.help_header}>
				<div className={styles.help_title}>{t(`${translationKey}_title`)}:</div>
				<div className={styles.agent_details}>
					<div className={styles.agen_info}>
						<div className={styles.agent_avatar}>
							<Image
								src={GLOBAL_CONSTANTS.image_urls.cogoport_logo}
								alt="Profile Pic"
								width={38}
								height={38}
							/>
						</div>
						<div className={styles.agent_contact}>
							<div className={styles.agent_name}>
								{GLOBAL_CONSTANTS.onboarding_specialist.name}
							</div>
							<div className={styles.agent_email}>
								<IcMEmail className={styles.email_icon} />
								<a
									href={`mailto:${GLOBAL_CONSTANTS.onboarding_specialist.email_id}`}
									className={styles.agent_email_address}
								>
									{GLOBAL_CONSTANTS.onboarding_specialist.email_id}
								</a>
							</div>
						</div>
					</div>
					<Tooltip
						content={`${GLOBAL_CONSTANTS.onboarding_specialist.phone_number}`}
						placement="top"
					>
						<a
							href={`tel:${GLOBAL_CONSTANTS.onboarding_specialist.phone_number}`}
							className={styles.help_call_icon}
						>
							<IcMCall className={styles.agent_call} />
						</a>
					</Tooltip>
				</div>
			</div>
			<SupportTypes
				setModalData={setModalData}
				setShowPopover={setShowPopover}
				unreadCount={unreadCount}
			/>
		</div>
	);
}

export default Support;
