import { IcMTimer, IcMCalendar } from '@cogoport/icons-react';
import { useTranslation } from 'next-i18next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import formatDate from '@/temp/utils/formatDate';
import { useSelector } from '@cogoport/front/store';
import styles from './styles.module.css';

function UpcomingDemoCard({ upcoming_demo = {} }) {
	const { t } = useTranslation(['common']);

	const { id = '' } = useSelector(({ profile }) => profile);

	const { start_time, end_time, participants = [] } = upcoming_demo || {};

	const { user_data: { name = '' } = {} } =
		(participants || []).find((item) => item.source === 'agent') || {};

	const { invite_link = '' } =
		(participants || []).find((item) => item.source_id === id) || {};

	return (
		<div className={styles.container}>
			<p className={styles.heading}>
				{t('common:demo_with')} {name}
			</p>

			<div className={styles.content}>
				<div>
					<p className={styles.text}>
						<IcMTimer
							fill="#4F4F4F"
							style={{ width: 13, height: 13, marginRight: 6 }}
						/>
						{formatDate({
							date: new Date(start_time).toISOString(),
							formatType: 'time',
							timeFormat: GLOBAL_CONSTANTS.formats.time['hh:mm'],
							separator: ' ',
						})}{' '}
						-{' '}
						{formatDate({
							date: new Date(end_time).toISOString(),
							formatType: 'time',
							timeFormat: GLOBAL_CONSTANTS.formats.time['hh:mm'],
							separator: ' ',
						})}
					</p>

					<p className={styles.text}>
						<IcMCalendar
							fill="#4F4F4F"
							style={{
								width: 12,
								height: 12,
								marginLeft: 1,
								marginRight: 8,
							}}
						/>
						{formatDate({
							date: new Date(start_time).toISOString(),
							formatType: 'date',
							dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM yyyy'],
							separator: ' ',
						})}
					</p>
				</div>

				{invite_link ? (
					<a
						href={invite_link}
						target="_blank"
						className={styles.email_link}
						rel="noreferrer"
					>
						{t('common:visit_link')}
					</a>
				) : null}
			</div>
		</div>
	);
}

export default UpcomingDemoCard;
