import styled from '@cogoport/front/styled';

export const Wrapper = styled.div`
	cursor: pointer;
	z-index: 1000;

	&.wrapper {
		position: fixed;
		bottom: 16px;
		right: 16px;

		@media (max-width: 768px) {
			bottom: 76px;
			right: 16px;
		}
	}
`;

export const BotIcondiv = styled.div`
	z-index: 1000;

	.no_of_messages {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		right: 36px;
		bottom: 24px;
		z-index: 999;
		font-size: 12px;
		font-weight: 600;
		background-color: #fff;
		border: 2px solid #fde74d;
		height: 30px;
		width: 30px;
		border-radius: 50%;

		@media (max-width: 768px) {
			font-size: 8px;
			height: 20px;
			width: 20px;
			right: 20px;
			bottom: 15px;
		}
	}

	.icon_div {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fde74d;
		cursor: pointer;
		width: 50px;
		height: 50px;
		border-radius: 50%;

		@media (max-width: 768px) {
			width: 30px;
			height: 30px;
		}
	}

	.icon_div .default_img {
		width: 100%;
		height: 100%;
		background-image: url(${({ animated_bot }) => animated_bot});
		filter: drop-shadow(0 0 16px rgb(98 127 172 / 10%));
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 50%;
		cursor: pointer;

		:hover {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			transform: rotate(180deg);
			transition: all 0.35s ease;
			background-image: url(${({ arrow_cogo_assist }) => arrow_cogo_assist});

			&.arrow_down_svg {
				transition: all 0.35s ease;
				transform: rotate(0deg);
			}

			@media (max-width: 768px) {
				width: 100%;
				height: 100%;
			}
		}

		&.arrow_down_svg {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			transition: all 0.35s ease;
			background-image: url(${({ arrow_cogo_assist }) => arrow_cogo_assist});

			@media (max-width: 768px) {
				width: 100%;
				height: 100%;
			}
		}
	}
`;

export const ModalContainer = styled.div`
	position: fixed;
	background: #fff;
	bottom: 100px;
	right: 20px;
	z-index: 999;
	height: 0;
	width: 0;
	border-radius: 20px;
	overflow: hidden;
	transition: all 0.35s ease;
	box-shadow: -2px 2px 25px rgba(0, 0, 0, 0.15);

	&.animate {
		height: 85vh;
		width: 400px;
		display: flex;
		justify-content: flex-end;
		font-size: 14px !important;
		animation-name: fadeIn;
		animation-duration: 0.35s;
		animation-timing-function: ease;
		animation-iteration-count: 1;

		@media (max-width: 768px) {
			bottom: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border-radius: 0;
		}
	}

	&.hide_modal {
		animation-name: fadeOut;
		animation-duration: 0.35s;
		animation-timing-function: ease;
		animation-iteration-count: 1;
	}

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		20% {
			opacity: 0.2;
		}
		50% {
			opacity: 0.5;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
		}
		20% {
			opacity: 0.5;
		}
		50% {
			opacity: 0.2;
		}
		100% {
			opacity: 0;
		}
	}
`;

export const MainContainer = styled.div`
	box-shadow: -2px 2px 25px rgba(0, 0, 0, 0.15);
`;

export const HeaderContainer = styled.div`
	background: #fde74d;
	height: 100px;
	padding: 20px;
	width: 400px;

	.sub_title {
		font-size: 18px;
	}
`;

export const TabsContainer = styled.div`
	padding: 20px;
	overflow: scroll;
`;

export const TabsDiv = styled.div`
	height: 120px;
	border-radius: 8px;
	padding: 20px;
	box-shadow: -2px 2px 25px rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	cursor: pointer;

	:hover {
		background: #fdebe9;
		border-bottom: 2px solid #ee3425;

		.chat_icon .main_icon {
			color: #ff0000;
		}

		.icon_container {
			background: #fdebe9;
		}
	}

	@media (max-width: 768px) {
		height: 140px;
	}
`;

export const TitleName = styled.div`
	font-size: 18px;
	font-weight: 600;
`;

export const ChatIconDiv = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #fde74d;

	.chat_icon {
		position: relative;
		top: 4px;
		left: 10px;
	}
`;

export const ChatDetailsDiv = styled.div`
	margin-left: 16px;
`;

export const MainTabsContainer = styled.div`
	height: 100%;
	width: 400px;
	box-shadow: -2px 2px 25px rgba(0, 0, 0, 0.15);

	.ui_tabs_container {
		height: 100%;
		width: 100%;
		margin: 0 !important;
		padding-top: 12px;
	}

	.ui_tabs_tabpanel {
		height: calc(100% - 28px);
		width: 100%;
	}
`;

export const FlexContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const Title = styled.div`
	margin-left: 4px;
`;

export const IconContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	padding: 12px;
	z-index: 99999;
`;
