import useRequest from '@/temp/request/useRequest';
import { useSelector } from '@cogoport/front/store';
import { useCallback, useEffect, useState } from 'react';

const useGetListCommunications = () => {
	const { general } = useSelector((state) => state);
	const { scope } = general;
	const [page, setPage] = useState(1);

	const { data, loading, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/communication/list_communications');

	const getListCommunications = useCallback(async () => {
		try {
			await trigger({
				params: {
					data_required: true,
					not_seen_count_required: true,
					pagination_data_required: true,
					page,
					communication_content_required: true,
					filters: {
						type: 'platform_notification',
					},
				},
			});
		} catch (e) {
			console.error(e);
		}
	}, [page, trigger]);

	useEffect(() => {
		getListCommunications();
	}, [getListCommunications, page]);

	return {
		data,
		loading,
		getListCommunications,
		setPage,
	};
};

export default useGetListCommunications;
