import { useRef, useEffect } from 'react';

import Grid from '@cogoport/front/components/Grid';
import { useSelector, useDispatch } from '@cogoport/front/store';
import { setStoreState as setGeneralStoreState } from '@cogoport/front/store/store/general';

import MetaTags from '@/commons/components/MetaTags';
import useGetActiveSubscription from '@/commons/hooks/useGetActiveSubscription';
import AppLayout from '../AppLayout';

import { Component } from './styles';

const { useScreenClass } = Grid;

function GlobalLayout({ children, layout, hideBG, ...rest }) {
	const generalData = useSelector(({ general }) => general);
	const dispatch = useDispatch();

	const elementRef = useRef(null);

	const { title, description, keywords } = generalData?.metaTagsData || {};

	const screenClass = useScreenClass(elementRef);
	const isMobile = ['xs', 'sm'].includes(screenClass);

	useEffect(() => {
		dispatch(setGeneralStoreState({ ...generalData, isMobile, screenClass }));
	}, [isMobile]);

	useGetActiveSubscription();

	const getBody = () => {
		if (layout === 'app') {
			return <AppLayout {...rest}>{children}</AppLayout>;
		}

		return children;
	};

	return (
		<Component ref={elementRef} className={hideBG ? '' : 'page-bg'}>
			<MetaTags title={title} description={description} keywords={keywords} />
			{getBody()}
		</Component>
	);
}

export default GlobalLayout;
