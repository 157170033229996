import useRequest from '@/temp/request/useRequest';
import { useSelector } from '@cogoport/front/store';
import { useCallback } from 'react';

const useFetchPrivateFirebaseCustomToken = ({
	supabaseClient,
	firestoreAuthId,
}) => {
	const userEmailAddress = useSelector(({ profile }) => profile?.user?.email);
	const { trigger } = useRequest(
		'get',
		false,
		'partner',
	)('communication/get_firestore_custom_token');

	const fetchPrivateFirebaseCustomToken = useCallback(async () => {
		try {
			const res = await trigger({
				params: {
					email: userEmailAddress,
					document_id: firestoreAuthId || undefined,
				},
			});

			const { data } = res || {};

			const { refresh_token = '', access_token = '' } = data || {};
			const { data: signedInData } = await supabaseClient.auth.setSession({
				access_token,
				refresh_token,
			});
			return signedInData;
		} catch (err) {
			return {};
		}
	}, [trigger, supabaseClient.auth, userEmailAddress, firestoreAuthId]);

	return {
		fetchPrivateFirebaseCustomToken,
	};
};

export default useFetchPrivateFirebaseCustomToken;
