import { string, node } from 'prop-types';

import { Container } from './styles';

function Message(props) {
	const { children, ...rest } = props;

	return <Container {...rest}>{children}</Container>;
}

Message.propTypes = {
	themeType: string,
	children: node,
};

Message.defaultProps = {
	themeType: null,
	children: null,
};

export default Message;
