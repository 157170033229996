module.exports = {
	'en-IN': {
		key: 'en-IN',
		label: 'EN',
		language: 'English',
		link: 'Poppins:wght@400',
		font_family: 'var(--font-family-en)',
		displayLanguage: 'English',
	},
	'en-SG': {
		key: 'en-SG',
		label: 'EN',
		language: 'English',
		link: 'Poppins:wght@400',
		font_family: 'var(--font-family-en)',
		displayLanguage: 'English',
	},
	'vi-VN': {
		key: 'vi-VN',
		label: 'VI',
		language: 'Vietnamese',
		link: 'Be+Vietnam+Pro:wght@400;500',
		font_family: 'var(--font-family-vi)',
		displayLanguage: 'Tiếng Việt',
	},
	'th-TH': {
		key: 'th-TH',
		label: 'TH',
		language: 'Thai',
		link: 'Poppins:wght@400',
		font_family: 'var(--font-family-en)',
		displayLanguage: 'แบบไทย',
	},
	'id-ID': {
		key: 'id-ID',
		label: 'ID',
		language: 'Indonesian',
		link: 'Poppins:wght@400',
		font_family: 'var(--font-family-en)',
		displayLanguage: 'Bahasa Indonesia',
	},
	'zh-CN': {
		key: 'zh-CN',
		label: 'ZH',
		language: 'Chinese',
		link: 'Poppins:wght@400',
		font_family: 'var(--font-family-en)',
		displayLanguage: '简体中文',
	},
};
