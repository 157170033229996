import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Image } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals';
import styles from './styles.module.css';

function LoadingSuccess() {
	const { t } = useTranslation(['common']);

	const [showSuccess, setShowSuccess] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowSuccess(true);
		}, 1500);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div className={styles.loading_container}>
			{showSuccess ? (
				<div className={styles.content}>
					<Image
						src={GLOBAL_CONSTANTS.image_urls.tick_demo}
						width={180}
						height={180}
					/>

					<div className={styles.bold}>
						{t('common:demo_scheduled_success_text')}
					</div>

					<div className={styles.text}>
						{t('common:demo_scheduled_success_subText')}
					</div>
				</div>
			) : (
				<div className={styles.content}>
					<Image
						src={GLOBAL_CONSTANTS.image_urls.junction_loader}
						width={180}
						height={180}
					/>

					<div className={styles.text}>{t('common:demo_scheduling')}</div>
				</div>
			)}
		</div>
	);
}

export default LoadingSuccess;
