import { isEmpty } from '@cogoport/front/utils';
import { getCookie } from '@/temp/cogo-cookies';

import rest from './rest';
import defaultConfig from './defaultConfig';

const getOrganizationId = (storeKey, ctx) => {
	if (typeof window !== 'undefined') {
		const getStoreState = window?.[storeKey]?.getState;

		if (typeof getStoreState === 'function') {
			return getStoreState()?.general?.query?.partner_id;
		}

		return null;
	}

	return ctx?.query?.partner_id || null;
};

const requestMethods = (requestConfigs = {}) => {
	const restConfig = defaultConfig(requestConfigs);

	const request = { ...rest };

	Object.keys(restConfig.data).forEach((key) => {
		const methods = {};

		['get', 'post', 'put', 'delete', 'patch', 'all'].forEach((verb) => {
			methods[verb] = (...args) => {
				const { ctx = {}, ...restArgs } = args[1] || {};

				if (!isEmpty(restArgs)) {
					args[1] = restArgs;
				}

				Object.keys(restConfig.data[key]).forEach((item) => {
					if (item === 'header_keys') {
						const headers = {};
						const token = getCookie(
							restConfig?.data[key].header_keys?.auth_token,
							ctx,
						);

						if (token) {
							headers.authorization = `Bearer: ${token}`;
						}

						const org_id = getOrganizationId(restConfig.storeKey, ctx);

						if (org_id) {
							headers.authorizationscopeid = org_id;
						}

						if (restConfig.data[key].header_keys.auth_scope) {
							headers.authorizationscope =
								restConfig.data[key][item].auth_scope;
						}

						request.defaults.headers.common = headers;
					} else if (item === 'responseInterceptor') {
						request.interceptors.response.handlers = [];
						request.interceptors.response.use(
							...restConfig.data[key].responseInterceptor,
						);
					} else if (item === 'baseURLs') {
						request.defaults.baseURL = restConfig?.data?.[key]?.baseURLs?.main;
					} else {
						request.defaults[item] = restConfig.data[key][item];
					}
				});

				return request[verb](...args);
			};
		});

		request[key] = methods;
	});

	return request;
};

export default requestMethods;
