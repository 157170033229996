// import { Select } from '@cogoport/components';
import React from 'react';
import countryCode from '@/data-store/constants/countries.json';
import SelectController from '../../../Controlled/SelectController';

function SelectCountryCode(props) {
	const { value } = props;
	const formattedList = countryCode.map((code) => ({
		value: code.currency_code || 'USD',
		label: code.currency_code || 'USD',
	}));
	return (
		<SelectController
			{...props}
			options={formattedList}
			value={value}
			readOnly
		/>
	);
}

SelectCountryCode.defaultProps = {
	value: '',
	onChange: () => {},
	multiple: false,
};

export default SelectCountryCode;
