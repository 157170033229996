import { startCase } from '@cogoport/front/utils';
// eslint-disable-next-line import/no-unresolved
import Countries from '@/data-store/constants/countries.json';

import SEARCHABLE_SERVICES from '../constants/searchable-services';
import currencies from '../constants/currencies';
import SalesAgentLocations from '../constants/sales-agent-locations.json';
import ContainerTypes from '../constants/container-types.json';
import ContainerSizes from '../constants/container-sizes.json';
import getCommodityList from '../constants/getCommodityList';
import TradeTypes from '../constants/trade-types.json';
import TruckTypes from '../constants/truck-types.json';
import IncoTerms from '../constants/inco-terms.json';
import Languages from '../constants/languages.json';
import WorkScope from '../constants/work-scope.json';
import ServiceTypes from '../constants/service-types.json';
import SupplierLicenseType from '../constants/supplier-license-types.json';
import months from '../constants/months.json';

const getOptionsFromKey = (
	key,
	{ commodityType, containerType, country_code = 'IN' },
) => {
	const data = {
		options: null,
		valueKey: 'value',
		labelKey: 'label',
		getOptionLabel: null,
		getOptionValue: null,
	};

	if (key === 'work-scopes') {
		data.options = WorkScope;
	} else if (key === 'countries') {
		data.options = Countries;
		data.valueKey = 'id';
		data.labelKey = 'name';
		data.getOptionLabel = (o) => o.name;
		data.getOptionValue = (o) => o.id;
	} else if (key === 'languages') {
		data.options = Languages;
	} else if (key === 'inco-terms') {
		data.options = IncoTerms;
	} else if (key === 'container-sizes') {
		data.options = ContainerSizes;
	} else if (key === 'container-types') {
		data.options = ContainerTypes;
	} else if (key === 'truck-types') {
		const applicableTrucks = TruckTypes.filter(
			(truck) =>
				truck.country_codes.includes(country_code) ||
				truck.country_codes.includes('ALL'),
		);
		data.options = applicableTrucks.map((truck) => ({
			...truck,
			label: `${startCase(truck.type)} Body ${truck.label}`,
		}));
	} else if (key === 'trade-types') {
		data.options = TradeTypes;
	} else if (commodityType) {
		data.options = getCommodityList(commodityType, containerType);
	} else if (key === 'currencies') {
		data.options = currencies;
	} else if (key === 'commodities') {
		data.options = getCommodityList('freight');
	} else if (key === 'service-types') {
		data.options = ServiceTypes;
	} else if (key === 'supplier-license-type') {
		data.options = SupplierLicenseType;
	} else if (key === 'months') {
		data.options = months;
	} else if (key === 'sales_agent_locations') {
		data.options = SalesAgentLocations;
	} else if (key === 'searchable-services') {
		data.options = SEARCHABLE_SERVICES;
	}

	return data.options ? data : null;
};

export default getOptionsFromKey;
