import {
	IcCChinaCircular,
	IcCCountryVietnam,
	IcCIndonesiaCircular,
	IcCThailandCircular,
} from '@cogoport/icons-react';

import USAFlag from '@/commons/components/USAFlag';

export const LANGUAGE_WISE_FLAGS = {
	VI: <IcCCountryVietnam />,
	ID: (
		<IcCIndonesiaCircular
			style={{ borderRadius: '50%', backgroundColor: 'rgb(0 0 0 / 12%)' }}
		/>
	),
	TH: <IcCThailandCircular />,
	ZH: <IcCChinaCircular />,
	EN: <USAFlag />,
	default: <USAFlag />,
};
