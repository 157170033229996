import { useCallback, useState } from 'react';
import { debounce } from '@cogoport/front/utils';

const useSearchQuery = () => {
	const [query, setQuery] = useState('');

	const request = debounce((value) => {
		setQuery(value);
	}, 600);

	const debounceQuery = useCallback((value) => request(value), []);

	return { debounceQuery, query };
};

export default useSearchQuery;
