import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import { useEffect, useState } from 'react';
import useRequest from '@/utils/request/useRequest';

const useTopicList = () => {
	const {
		general: { scope = '' },
		profile,
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const { partner = {} } = profile;
	const {
		country_id = '',
		parent_entity_id = '',
		account_types = [],
	} = partner;

	const [search, setSearch] = useState('');
	const [topic, setTopic] = useState('');
	const [page, setPage] = useState(1);
	const [question, setQuestion] = useState(null);
	const [showHistory, setShowHistory] = useState(false);
	const [showNotificationContent, setShowNotificationContent] = useState(false);

	const { data, loading, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/cogo_academy/list_faq_topics');

	const fetch = () => {
		try {
			trigger({
				params: {
					filters: {
						status: 'active',
						state: 'published',
						country_id,
						cogo_entity_id: parent_entity_id,
						persona: account_types?.[0],
					},
					sort_by: 'view_count',
					page,
					page_limit: 20,
				},
			});
		} catch (error) {
			Toast.error(getApiErrorString(error?.data));
		}
	};

	useEffect(() => {
		fetch();
	}, [page]);

	const { list, ...paginationData } = data || {};

	return {
		search,
		setSearch,
		list,
		paginationData,
		loading,
		page,
		setPage,
		topic,
		setTopic,
		question,
		setQuestion,
		showHistory,
		setShowHistory,
		setShowNotificationContent,
		showNotificationContent,
	};
};

export default useTopicList;
