import Draggable from 'react-draggable';
import { Tabs, TabPanel } from '@cogoport/components';
import { Flex } from '@cogoport/front/components';
import { isEmpty } from '@cogoport/utils';
import { useTranslation } from 'next-i18next';
import { IcMCross } from '@cogoport/icons-react';
import GLOBAL_CONSTANTS from '@/constants/globals';
import ModuleContent from '@/components/DemoVideo/DemoVideoPage/components/ModuleContent';
import {
	Wrapper,
	BotIcondiv,
	MainContainer,
	HeaderContainer,
	TabsContainer,
	TabsDiv,
	TitleName,
	ChatIconDiv,
	ChatDetailsDiv,
	MainTabsContainer,
	ModalContainer,
	IconContainer,
} from './styles';
import useFloatingWidget from './useFloatingWidget';

function FloatingButtonWidgets({ anonymousUserId, supabaseClient }) {
	const { t } = useTranslation(['common']);

	const {
		userName = '',
		showWidget = false,
		onStart = () => {},
		onStop = () => {},
		onDrag = () => {},
		totalMessages = 0,
		handleOpenOptions = () => {},
		widgetMapping,
		show,
		setShow,
		toggleUserChat,
		activeTab,
		setActiveTab,
		increaseModalSize,
		setIncreaseModalSize,
		isRoomCreated = false,
	} = useFloatingWidget({ anonymousUserId, supabaseClient });

	if (!showWidget) return null;

	return (
		<>
			<Draggable
				onStop={onStop}
				onDrag={onDrag}
				onStart={onStart}
				handle="#draggable_widget"
				onMouseDown={() => {
					if (isRoomCreated) {
						setActiveTab('chat');
					} else {
						setActiveTab('');
					}

					handleOpenOptions();
				}}
			>
				<Wrapper className="wrapper">
					<BotIcondiv
						className="bot_icon"
						id="draggable_widget"
						animated_bot={GLOBAL_CONSTANTS.image_urls.animated_bot}
						arrow_cogo_assist={GLOBAL_CONSTANTS.image_urls.arrow_cogo_assist}
					>
						<div>
							{totalMessages > 0 && (
								<div className="no_of_messages">
									{totalMessages > 99 ? '99+' : totalMessages}
								</div>
							)}
							<div className="icon_div" style={{ cursor: 'pointer' }}>
								<div
									alt="logo"
									className={`default_img ${
										show ? 'arrow_down_svg' : 'arrow_up_svg'
									}`}
								/>
							</div>
						</div>
					</BotIcondiv>
				</Wrapper>
			</Draggable>

			<ModalContainer
				className={show ? 'animate' : 'hide_modal'}
				style={
					!isEmpty(increaseModalSize) && activeTab === 'demo'
						? { width: 1400 }
						: null
				}
			>
				<IconContainer onClick={() => setShow(false)}>
					<IcMCross />
				</IconContainer>

				{isEmpty(activeTab) ? (
					<MainContainer>
						<HeaderContainer>
							<TitleName>
								{t('common:floatingButton_greeting')}, {userName}{' '}
							</TitleName>

							<div className="sub_title">
								{t('common:floatingButton_message')}
							</div>
						</HeaderContainer>

						<TabsContainer>
							{widgetMapping.map((tabItems) => {
								const {
									name,
									tabs_title,
									tabs_sub_title,
									icon: Icon,
									conditionToShow = true,
								} = tabItems;

								if (!conditionToShow) {
									return false;
								}

								return (
									<TabsDiv
										key={name}
										onClick={() => {
											if (name === 'chat') {
												toggleUserChat();
											}

											setActiveTab(name);
										}}
									>
										<Flex>
											<ChatIconDiv className="icon_container">
												<div className="chat_icon">
													<Icon
														width={30}
														height={30}
														padding={100}
														className="main_icon"
													/>
												</div>
											</ChatIconDiv>

											<ChatDetailsDiv>
												<TitleName>{tabs_title}</TitleName>

												<div>{tabs_sub_title}</div>
											</ChatDetailsDiv>
										</Flex>
									</TabsDiv>
								);
							})}
						</TabsContainer>
					</MainContainer>
				) : (
					<>
						{!isEmpty(increaseModalSize) && activeTab === 'demo' ? (
							<ModuleContent
								viewType="widgetModal"
								chapter={increaseModalSize}
								setVideo={setIncreaseModalSize}
							/>
						) : null}

						<MainTabsContainer>
							<Tabs activeTab={activeTab} onChange={setActiveTab} fullWidth>
								{widgetMapping.map((tabItem) => {
									const {
										name,
										title,
										component: Component,
										props,
										conditionToShow = true,
									} = tabItem;

									if (!conditionToShow) {
										return false;
									}

									return (
										<TabPanel
											key={name}
											name={name}
											title={title}
											style={{ margin: 0 }}
										>
											<Component
												key={name}
												{...props}
												activeTab={activeTab}
												show={show}
											/>
										</TabPanel>
									);
								})}
							</Tabs>
						</MainTabsContainer>
					</>
				)}
			</ModalContainer>
		</>
	);
}

export default FloatingButtonWidgets;
