import { startCase } from '@cogoport/utils';
import React, { useState } from 'react';
import GLOBAL_CONSTANTS from '@/constants/globals';
import styles from './styles.module.css';
import quotes from './utils/quotes';

function Loader({ topic = {} }) {
	const [extract] = useState(quotes[Math.floor(Math.random() * quotes.length)]);

	return (
		<div className={styles.container}>
			<div className={styles.topic_heading}>
				Topic: {startCase(topic.display_name) || 'Search Result'}
			</div>

			<div className={styles.loop_wrapper}>
				<div className={styles.mountain} />
				<div className={styles.hill} />
				<div
					className={styles.tree}
					style={{
						background: `url(${GLOBAL_CONSTANTS.image_urls.cogo_icon_red}) no-repeat`,
					}}
				/>
				<div className={styles.rock} />
				<div
					className={styles.truck}
					style={{
						backgroundImage: `url(${GLOBAL_CONSTANTS.image_urls.truck})`,
						backgroundRepeat: 'no-repeat',
					}}
				/>
			</div>

			<div className={styles.line}>{extract}</div>
		</div>
	);
}

export default Loader;
