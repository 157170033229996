import GLOBAL_CONSTANTS from '@/constants/globals.json';

const addInitialMessage = async ({
	roomId,
	supabaseClient,
	name,
	t,
	sendMessage,
}) => {
	const userChat = {
		conversation_type: 'received',
		response: {
			message: `${t('common:components_webBot_userChat_message1')} ${
				name || 'User'
			} ${t('common:components_webBot_userChat_message2')}`,
		},

		agent_type: 'bot',
		customer_chat_id: roomId,
	};

	if (roomId) {
		await supabaseClient.from('customer_messages').insert(userChat);
	}

	const userWhatsappQR = {
		conversation_type: 'received',
		message_type: 'image',
		response: {
			whatsapp_get_started_link:
				GLOBAL_CONSTANTS.urls.whatsapp_get_started_link,
			message: t('common:whatsapp_qr_description'),
			media_url: GLOBAL_CONSTANTS.image_urls.whatsapp_qr,
		},
		agent_type: 'bot',
		customer_chat_id: roomId,
	};

	if (roomId) {
		await supabaseClient.from('customer_messages').insert(userWhatsappQR);
	}

	const messageMetaData = {
		message_type: 'text',
		text: `${t('common:components_webBot_userChat_message1')} ${
			name || 'User'
		} ${t('common:components_webBot_userChat_message2')}`,
		customer_chat_id: roomId,
	};

	sendMessage({
		messageMetaData,
		conversation_type: 'outward',
	});

	const whatsappQRMetadata = {
		whatsapp_get_started_link: GLOBAL_CONSTANTS.urls.whatsapp_get_started_link,
		message_type: 'image',
		text: t('common:whatsapp_qr_description'),
		media_url: GLOBAL_CONSTANTS.image_urls.whatsapp_qr,
		customer_chat_id: roomId,
	};

	sendMessage({
		messageMetaData: whatsappQRMetadata,
		conversation_type: 'outward',
	});
};

export default addInitialMessage;
