import { getCookie, setCookies } from '@/temp/cogo-cookies';
import { v1 as uuid } from 'uuid';
import { useCallback, useRef, useState } from 'react';
import { isEmpty } from '@cogoport/utils';
import getRoomHelpers from '../helpers/roomHelpers';
import useCreateCustomerChatMapping from './useCreateCustomerChatMapping';
import useCreatePublicCustomerChatMapping from './useCreatePublicCustomerChatMapping';

const useFetchRoom = ({
	supabaseClient,
	profile,
	sendMessage,
	t,
	isUnKnownUser,
	setCogobotLoading,
	setChatbotUserRoom,
	anonymousUserId,
}) => {
	const fetchRoomRef = useRef(null);

	const [cogoBotState, setCogoBotState] = useState({
		isOpen: false,
		newMessageCount: 0,
		isRoomCreated: false,
		room_id: null,
	});

	const { isOpen, isRoomCreated = false } = cogoBotState || {};

	const { createPublicMapping = () => {} } =
		useCreatePublicCustomerChatMapping();
	const { createMapping = () => {} } = useCreateCustomerChatMapping();

	const { updateRoom, createRoom } = getRoomHelpers({
		profile,
		supabaseClient,
		isUnKnownUser,
		setCogoBotState,
		t,
		sendMessage,
		anonymousUserId,
		createPublicMapping,
		createMapping,
	});

	const getInitialRoomData = useCallback(
		async ({ abortMessagesControl }) => {
			let cogoBotToken = getCookie('cogo_bot_token') || '';

			if (!cogoBotToken) {
				cogoBotToken = uuid();
				setCookies('cogo_bot_token', cogoBotToken);
			}
			const { data, error } = await supabaseClient
				.from('customer_chats')
				.select()
				.eq('auth_user_id', anonymousUserId)
				.abortSignal(abortMessagesControl?.signal);

			if (error) {
				return;
			}
			if (data && !isEmpty(data)) {
				const {
					updated_at: roomCreatedAt = 0,
					new_user_message_count = 0,
					id = null,
				} = data[0];
				setCogobotLoading(false);
				setCogoBotState((p) => ({
					...p,
					isRoomCreated: !!roomCreatedAt,
					newMessageCount: new_user_message_count,
					room_id: id,
				}));

				if (roomCreatedAt) {
					setChatbotUserRoom(data[0]);
				}
			}
		},
		[anonymousUserId, supabaseClient, setCogobotLoading, setChatbotUserRoom],
	);

	const fetchRealtimeRoom = useCallback(() => {
		try {
			if (
				!isEmpty(fetchRoomRef.current) &&
				fetchRoomRef.current.state === 'joined'
			) {
				return;
			}
			let cogoBotToken = getCookie('cogo_bot_token') || '';

			if (!cogoBotToken) {
				cogoBotToken = uuid();
				setCookies('cogo_bot_token', cogoBotToken);
			}

			fetchRoomRef.current = supabaseClient
				.channel('platformChatUserRoom')
				.on(
					'postgres_changes',
					{
						event: '*',
						schema: 'public',
						table: 'customer_chats',
						filter: `auth_user_id=eq.${anonymousUserId}`,
					},
					(userRoomData) => {
						const {
							updated_at: roomCreatedAt = 0,
							new_user_message_count = 0,
							id = null,
						} = userRoomData.new;
						setCogobotLoading(false);
						setCogoBotState((p) => ({
							...p,
							isRoomCreated: !!roomCreatedAt,
							newMessageCount: new_user_message_count,
							room_id: id,
						}));

						if (roomCreatedAt) {
							setChatbotUserRoom(userRoomData.new);
						}
					},
				)
				.subscribe();
		} catch (err) {
			console.error('CHAT_ROOM_FETCH_ERROR', err);
		}
	}, [supabaseClient, anonymousUserId, setCogobotLoading, setChatbotUserRoom]);

	const toggleUserChat = async () => {
		if (isOpen) {
			setCogoBotState((p) => ({ ...p, isOpen: false }));
			await supabaseClient
				.from('customer_chats')
				.update({ new_user_message_count: 0 })
				.eq('auth_user_id', anonymousUserId);
		} else {
			if (!isRoomCreated) {
				await createRoom();
			} else {
				await updateRoom();
			}
			setCogoBotState((p) => ({ ...p, isOpen: true }));
		}
	};

	return {
		toggleUserChat,
		getInitialRoomData,
		fetchRealtimeRoom,
		cogoBotState,
		fetchRoomRef,
	};
};
export default useFetchRoom;
