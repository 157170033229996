import { useTranslation } from 'next-i18next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import Image from 'next/image';
import styles from './styles.module.css';

function EmptyList() {
	const { t } = useTranslation(['common']);

	return (
		<div className={styles.empty_container}>
			<Image
				src={GLOBAL_CONSTANTS.image_urls.empty_state}
				alt={t('common:empty_image_notifications')}
				width={200}
				height={200}
			/>
			<div className={styles.text}>
				{t('common:no_notifications_found_notifications')}
			</div>
		</div>
	);
}

export default EmptyList;
