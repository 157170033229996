import FloatingButtonWidgets from '@/commons/components/FloatingButtonWidgets';

import useCheckUserLoggedIn from './hooks/useCheckUserLoggedIn';
import useSupabase from './hooks/useSupabase';

function BotAuthorization() {
	const { supabaseClient } = useSupabase();

	const { anonymousUserId } = useCheckUserLoggedIn({ supabaseClient });

	if (!anonymousUserId) {
		return null;
	}

	return (
		<FloatingButtonWidgets
			key={anonymousUserId}
			anonymousUserId={anonymousUserId}
			supabaseClient={supabaseClient}
		/>
	);
}

export default BotAuthorization;
