import { Popover } from '@cogoport/components';
import { useRouter } from '@/temp/next';
import { useEffect, useState } from 'react';
import LANGUAGE_MAPPING from '@/constants/languageMapping';
import { isEmpty } from '@cogoport/utils';
import getLanguageCode from '@/temp/utils/getLanguageCode';
import { LANGUAGE_WISE_FLAGS } from '@/constants/languageWiseFlags';
import styles from './styles.module.css';
import Languages from './Languages';

function LanguageSelect() {
	const router = useRouter();

	const { locale } = router;

	const [showPopover, setShowPopover] = useState(false);

	const localeDetails = LANGUAGE_MAPPING[locale] || {};

	const { label } = localeDetails || {};

	const languageCode = getLanguageCode(locale);

	useEffect(() => {
		setShowPopover(false);
	}, [locale]);

	if (isEmpty(localeDetails)) {
		return null;
	}

	return (
		<div className={styles.language_pop}>
			<Popover
				placement="top"
				key="languageChange"
				content={<Languages />}
				visible={showPopover}
				onClickOutside={() => setShowPopover(false)}
			>
				<div
					role="presentation"
					onClick={() => setShowPopover((pv) => !pv)}
					className={styles.lang}
				>
					<div className={styles.globe_container}>
						{LANGUAGE_WISE_FLAGS[languageCode.toUpperCase()]}
						<div className={styles.selected_lang}>{label}</div>
					</div>
				</div>
			</Popover>
		</div>
	);
}
export default LanguageSelect;
