import { toast } from '@cogoport/front/components';
import useRequest from '@/utils/request/useRequest';
import { useSelector } from '@cogoport/front/store';
import { useTranslation } from 'next-i18next';

const useSendMessage = ({
	user_id = null,
	lead_user_id = null,
	organization_id = null,
}) => {
	const {
		general: { scope },
	} = useSelector((state) => state);

	const { t } = useTranslation(['common']);

	const COGOVERSE_ID = process.env.NEXT_PUBLIC_COGOVERSE_ID;
	const { loading, trigger } = useRequest('post', false, scope, {
		autoCancel: false,
	})('communication/create_communication_platform_chat');

	const sendMessage = async ({
		messageMetaData,
		conversation_type = '',
		updateFirestore = () => {},
	}) => {
		let service = 'user';
		if (!user_id && lead_user_id) {
			service = 'lead_user';
		}

		let extraPayload = {};

		if (conversation_type === 'outward') {
			extraPayload = { user_id, lead_user_id, organization_id };
		} else {
			extraPayload = {
				sender_user_id: user_id,
				service_id: user_id,
				sender_lead_user_id: lead_user_id,
			};
		}

		try {
			await trigger({
				data: {
					type: 'platform_chat',
					message_metadata: messageMetaData,
					service,
					service_id: COGOVERSE_ID,
					conversation_type: conversation_type || 'inward',
					source: 'CogoOne:PartnerPlatform',
					...(extraPayload || {}),
				},
			});
			updateFirestore();
		} catch (err) {
			toast.error(t('common:public_send_message_toast_error'));
		}
	};
	return {
		sendMessage,
		loading,
	};
};
export default useSendMessage;
