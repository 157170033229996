import { useState, useEffect } from 'react';
import RcUpload from 'rc-upload';

import { Button } from '@cogoport/front/components/admin';
import { Flex } from '@cogoport/front/components';
import IconUpload from '../icons/file.svg';

import UploadList from './UploadList';
import Message from './Message';
import { getFileSizeExceedMessage } from './getFileSizeMessage';

import {
	Container,
	DragContainer,
	DragText,
	HelperText,
	UploadLists,
} from './styles';

export const fileToObject = (file) => ({
	...file,
	lastModified: file.lastModified,
	lastModifiedDate: file.lastModifiedDate,
	name: file.name,
	size: file.size,
	type: file.type,
	uid: file.uid,
	percent: 0,
	originFileObj: file,
});

function Uploader(props) {
	const {
		accept,
		action,
		data,
		directory,
		customRequest,
		disabled,
		headers,
		multiple,
		name,
		listType,
		showUploadList,
		withCredentials,
		onPreview,
		children,
		ssr,
		onProgress,
		onSuccess,
		onError,
		onChange,
		onRemove,
		beforeUpload,
		drag,
		renderUploadList,
		themeType,
		isLoading,
		dragNDropText,
		helperText,
		uploadedFilesList,
		width,
		message,
		messageType,
		showRemoveIcons,
		showMessage,
		showDownloadIcon,
		maximumFileSize,
		uploadText,
		uploadIcon,
		showUploadIcon,
		className: classNameProps,
	} = props;

	const [fileList, setFileList] = useState(uploadedFilesList || []);
	const [errorMessage, setErrorMessage] = useState(null);

	const className = [drag ? 'drag' : '', classNameProps].join(' ');

	const handleBeforeUpload = (file) => {
		const targetItem = fileToObject(file);
		if (targetItem.size > maximumFileSize) {
			setErrorMessage(getFileSizeExceedMessage(maximumFileSize));
		} else {
			setErrorMessage(null);
			setFileList((allfiles) => [...allfiles, targetItem]);

			if (beforeUpload) {
				beforeUpload(file);
			}
		}
	};

	const onStart = (file) => {
		const targetItem = fileToObject(file);
		targetItem.status = 'uploading';
		if (targetItem.size > maximumFileSize) {
			setErrorMessage(getFileSizeExceedMessage(maximumFileSize));
		} else {
			setErrorMessage(null);
			onChange({ file: targetItem, fileList, originalFile: file });
		}
	};

	const handleRemove = (file) => {
		setFileList((allFiles) => allFiles.filter((item) => file.uid !== item.uid));
		onRemove?.(file);
	};

	const acceptFormats = () => {
		if (listType === 'image') {
			return 'image/*';
		}
		if (listType === 'docs') {
			return '.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		}
		return null;
	};

	const actualIcon =
		typeof uploadIcon === 'function' ? uploadIcon() : <IconUpload size={3} />;

	useEffect(() => {
		setFileList(uploadedFilesList);
	}, [JSON.stringify(uploadedFilesList)]);

	return (
		<Container className={`upload-container ${className}`} width={width}>
			<UploadLists className={listType === 'image' ? 'row' : ''}>
				{(multiple ||
					(((uploadedFilesList || []).length < 1 || fileList.length < 1) &&
						!multiple)) && (
					<RcUpload
						accept={acceptFormats() || accept}
						action={action}
						beforeUpload={handleBeforeUpload}
						customRequest={customRequest}
						directory={directory}
						disabled={disabled}
						headers={headers}
						multiple={multiple}
						name={name}
						supportServerRender={ssr}
						data={data}
						onStart={onStart}
						onProgress={onProgress}
						onSuccess={onSuccess}
						onError={onError}
						withCredentials={withCredentials}
					>
						{drag ? (
							<DragContainer
								className={`${className} upload-area ${disabled && 'disabled'}`}
							>
								<>
									{(showUploadIcon && actualIcon) || null}
									<DragText>
										<Flex
											className="secondary lg"
											variant="link"
											isLoading={isLoading}
											disabled={disabled}
											style={{ textDecoration: 'none', marginLeft: -15 }}
										>
											{typeof dragNDropText === 'function'
												? dragNDropText()
												: dragNDropText}
										</Flex>
									</DragText>
								</>
							</DragContainer>
						) : (
							<Button type="button" isLoading={isLoading} disabled={disabled}>
								{children || (multiple && (uploadedFilesList || []).length > 0)
									? 'Upload Another'
									: uploadText}
							</Button>
						)}
					</RcUpload>
				)}
				{((uploadedFilesList || []).length > 0 || fileList?.length > 0) &&
					showUploadList && (
						<UploadList
							items={uploadedFilesList || fileList}
							disabled={disabled}
							listType={listType}
							onRemove={handleRemove}
							onPreview={onPreview}
							showDownloadIcon={showDownloadIcon}
							size="215px"
							multiple={multiple}
							renderUploadList={renderUploadList}
							themeType={themeType}
							className={`${className} ${disabled && 'disabled'}`}
							showRemoveIcons={showRemoveIcons}
						/>
					)}
			</UploadLists>
			{helperText && <HelperText>{helperText}</HelperText>}

			{(message || errorMessage) && showMessage ? (
				<Message themeType={`${messageType} small`}>
					{message || errorMessage}
				</Message>
			) : null}
		</Container>
	);
}
Uploader.defaultProps = {
	action: '/api/nothing',
	accept: '',
	themeType: null,
	isLoading: false,
	children: null,
	directory: false,
	disabled: false,
	data: null,
	drag: false,
	headers: '',
	multiple: false,
	name: '',
	ssr: true,
	showUploadList: true,
	beforeUpload: null,
	customRequest: null,
	onChange: null,
	onProgress: null,
	onSuccess: null,
	renderUploadList: null,
	onRemove: null,
	onPreview: null,
	onError: () => {},
	listType: 'file',
	withCredentials: false,
	dragNDropText: 'Upload',
	helperText: null,
	uploadedFilesList: null,
	maximumFileSize: Infinity,
	showMessage: true,
	uploadText: 'Upload',
	uploadIcon: null,
	showUploadIcon: true,
};

export default Uploader;
