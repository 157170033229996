import { Controller } from 'react-hook-form';

import AsyncSelect from '../Business/AsyncSelect';

function AsyncSelectController(props) {
	const { name, control, rules, value, ...rest } = props;

	return (
		<Controller
			key={rest.id}
			control={control}
			name={name}
			rules={rules}
			defaultValue={value}
			render={({ field: { onChange, onBlur, value: newValue } }) => (
				<AsyncSelect
					{...rest}
					key={`${rest?.id}_${newValue}`}
					onChange={(val, obj) => {
						if (typeof rest?.onChange === 'function') {
							rest?.onChange(val, obj, name);
						} else if (typeof rest?.handleChange === 'function') {
							rest?.handleChange(val, obj, name);
						}

						onChange(val, obj);
					}}
					value={newValue || rest?.finalValue}
					onBlur={onBlur}
					data-test-value={newValue}
				/>
			)}
		/>
	);
}
export default AsyncSelectController;
