import React, { useEffect } from 'react';
import { Modal, Button } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import { useForm } from '@/commons/form';
import styles from './styles.module.css';
import FormLayout from './FormLayout';
import additionalInformation from '../../../../configurations/additional-information';

const translationKey = 'common:components_header_tickets_list';

function CreateTicket({
	createTicket = () => {},
	loading = false,
	setSelectedQuery = () => {},
}) {
	const { t } = useTranslation(['common']);

	const formProps = useForm();
	const { handleSubmit, watch, setValue } = formProps || {};

	const queryWatch = watch('ticket_type');
	const descriptionWatch = watch('description');
	const watchRequestType = watch('request_type');

	const extraField =
		additionalInformation(t).find(({ keyword }) =>
			queryWatch?.toLowerCase()?.includes(keyword),
		) || {};

	const onSubmit = (val) => {
		createTicket(val, extraField?.name);
	};

	useEffect(() => {
		setSelectedQuery(queryWatch || '');
	}, [queryWatch]);

	useEffect(() => {
		setSelectedQuery(descriptionWatch || '');
	}, [descriptionWatch]);

	return (
		<>
			<Modal.Header title={t(`${translationKey}_create_ticket`)} />
			<Modal.Body className={styles.modal_body}>
				<FormLayout
					{...formProps}
					extraField={extraField}
					watchRequestType={watchRequestType}
					setValue={setValue}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button
					size="md"
					themeType="primary"
					onClick={handleSubmit(onSubmit)}
					disabled={loading}
				>
					{t(`${translationKey}_create_submit`)}
				</Button>
			</Modal.Footer>
		</>
	);
}

export default CreateTicket;
