import React from 'react';
import formatDate from '@/temp/utils/formatDate';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.css';
import { statusLabelTransformation } from '../../../../configurations/key-mapping';
import getTicketStatus from '../../../../utils/getTicketStatus';

const translationKey = 'common:components_header_tickets_summary';

function TicketSummary({ ticketDetails = {} }) {
	const { t } = useTranslation(['common']);
	const statusLabel = statusLabelTransformation({ t });
	const {
		ID: id = '',
		Type: type = '',
		Status: status = '',
		UpdatedAt: updatedAt = '',
		CreatedAt: createdAt = '',
	} = ticketDetails || {};

	const { label = '' } = statusLabel?.[getTicketStatus(status)] || {};

	return (
		<div className={styles.container}>
			<div className={styles.header}>{t(`${translationKey}_header`)}</div>
			<div className={styles.ticket_body}>
				<div className={styles.ticket_header}>
					<div className={styles.ticket_id}>#{id}</div>
					<div className={styles.description}>{type}</div>
				</div>
				<div className={styles.ticket_status}>
					<div>{label || status}</div>
					<div className={styles.updated_at}>
						{formatDate({
							date: updatedAt,
							dateFormat: GLOBAL_CONSTANTS.formats.date['dd/mm/yyyy'],
							separator: ', ',
							timeFormat: GLOBAL_CONSTANTS.formats.time['HH:mm'],
							formatType: 'dateTime',
						})}
					</div>
				</div>
			</div>

			<div className={styles.ticket_data}>
				{t(`${translationKey}_${status === 'closed' ? 'resolved' : 'created'}`)}
				<span className={styles.updated_at}>
					{formatDate({
						date: status === 'closed' ? updatedAt : createdAt,
						dateFormat: GLOBAL_CONSTANTS.formats.date['dd/mm/yyyy'],
						separator: ', ',
						timeFormat: GLOBAL_CONSTANTS.formats.time['HH:mm'],
						formatType: 'dateTime',
					})}
				</span>
			</div>
		</div>
	);
}

export default TicketSummary;
