import GLOBAL_CONSTANTS from '@/constants/globals.json';
import styles from './styles.module.css';

function CustomFileDiv({ mediaUrl = '' }) {
	const urlArray = decodeURI(mediaUrl)?.split('/');
	const fileNameFromUrl = urlArray[(urlArray?.length || 0) - 1] || '';
	const [fileName = '', extension = ''] = fileNameFromUrl.split('.') || [];

	return (
		<div
			className={styles.container}
			role="presentation"
			onClick={() => {
				window.open(mediaUrl, '_blank', 'noreferrer');
			}}
		>
			<img
				alt="logo"
				src={GLOBAL_CONSTANTS.image_urls.document_icon}
				className={styles.img_styles}
			/>
			<div className={styles.name_flex}>
				<div className={styles.file_name}>{fileName}</div>
				{extension && <div className={styles.extension}>.{extension}</div>}
			</div>
		</div>
	);
}
export default CustomFileDiv;
