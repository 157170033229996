import { getGeoConstants } from '@/constants/geo';
import { formatDateToString } from '@cogoport/front/date';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

const geoConstants = getGeoConstants();

const formatTypeFunMapping = {
	date({ date, dateFormat }) {
		let formatDate = geoConstants.formats.date.default;
		if (dateFormat in GLOBAL_CONSTANTS.formats.date) {
			formatDate = dateFormat;
		}

		return formatDateToString(date, formatDate);
	},
	time({ date, timeFormat }) {
		let formatTime = geoConstants.formats.time['12hrs'];
		if (timeFormat in GLOBAL_CONSTANTS.formats.time) {
			formatTime = timeFormat;
		}

		return formatDateToString(date, formatTime);
	},
	dateTime({ date, timeFormat, dateFormat, separator = ' | ' }) {
		const formattedDate = this.date({ date, dateFormat });
		const formattedTime = this.time({ date, timeFormat });

		return `${formattedDate}${separator}${formattedTime}`;
	},
};

/**
 *
 * @param {
 *  date: Date Obj => null
 *  formatType: String => ['date', 'time', 'dateTime'],
 *  dateFormat
 *  timeFormat
 *  separator: String => [' | ']
 * }
 * @returns formattedDate
 */
const formatDate = (params) => {
	const { date, formatType } = params;

	if (!date) {
		return null;
	}

	let type = 'date';
	if (formatType in formatTypeFunMapping) {
		type = formatType;
	}

	return formatTypeFunMapping[type](params);
};

export default formatDate;
