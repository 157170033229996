import { Toast, Input, Button } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { IcMSearchlight, IcMCross, IcMArrowBack } from '@cogoport/icons-react';
import { isEmpty } from '@cogoport/utils';
import React from 'react';

import styles from './styles.module.css';

function Header({
	search = '',
	setSearch = () => {},
	topic = '',
	setTopic = () => {},
	question,
	setQuestion,
	showHistory,
	setShowHistory = () => {},
	setShowNotificationContent = () => {},
	showNotificationContent,
	refetch,
	from,
	setInput,
	input,
}) {
	const suffix = !input ? null : (
		<IcMCross
			onClick={() => {
				setInput('');
				setSearch('');
				setQuestion(null);
			}}
			style={{ cursor: 'pointer', color: '#000000' }}
		/>
	);

	const onClickBackButton = async () => {
		if (question && topic) {
			setQuestion(null);
		} else if (question && !topic) {
			setQuestion(null);
			try {
				const res = await refetch();
				if (isEmpty(res?.data?.notification_details || [])) {
					setShowNotificationContent(false);
				}
			} catch (e) {
				if (e.response?.data) Toast.error(getApiErrorString(e.response?.data));
			}
		} else {
			setTopic(null);
			setQuestion(null);
			setShowHistory(false);
			setShowNotificationContent(false);
		}
	};

	const showBackIcon =
		(!search && topic) || question || showHistory || showNotificationContent;

	const TABS_CONTENT_MAPPING = {
		faq: {
			back_icon_visible: true,
			placeholder: 'Search for a question or a topic',
			input_value: search,
			input_onchange: setSearch,
		},
	};

	return (
		<div className={`${styles.container} ${styles[from]}`}>
			<div className={styles.wrapper}>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						const searchFn = TABS_CONTENT_MAPPING.faq.input_onchange;
						searchFn(input);
						setQuestion(null);
					}}
					className={`${styles.input_container} ${styles[from]}`}
				>
					<Input
						className="primary lg"
						placeholder={TABS_CONTENT_MAPPING.faq.placeholder}
						value={input}
						onChange={(e) => {
							setInput(e);
							if (!e) setSearch('');
							setQuestion(null);
						}}
						suffix={suffix}
						style={{ padding: '0 10px', marginRight: 8 }}
					/>
					<Button type="submit" size="lg" themeType="primary">
						<IcMSearchlight />
					</Button>
				</form>

				{showBackIcon && TABS_CONTENT_MAPPING.faq.back_icon_visible && (
					<div className={styles.heading_container}>
						<div
							role="presentation"
							className={styles.arrow}
							onClick={() => onClickBackButton()}
						>
							<IcMArrowBack height={16} width={16} />

							<div style={{ marginLeft: '8px' }}>Go Back</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Header;
