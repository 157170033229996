import { Input, Pagination, Tooltip } from '@cogoport/components';
import { IcMVideoCall, IcMAppSearch } from '@cogoport/icons-react';

import { isEmpty } from '@cogoport/utils';
import EmptyState from '@/commons/components/EmptyState';
import styles from './styles.module.css';
import LoadingState from './LoadingState';

function ListChapters({
	data,
	chapter,
	setState,
	setIndexes,
	setShowVideo,
	loading,
}) {
	if (loading) {
		return <LoadingState />;
	}

	if (isEmpty(data) && !loading) {
		return <EmptyState />;
	}

	return (
		<div className={styles.cards_container}>
			{data.map((currChapter, chapterIndex) => (
				<div
					role="presentation"
					key={currChapter?.id}
					className={`${styles.chapter_container} 
								${currChapter.id === chapter.id && styles.active}`}
					onClick={() => {
						setState({ current_chapter: currChapter });

						setIndexes({
							chapterIndex,
						});
						setShowVideo(() => currChapter);
					}}
				>
					<div className={styles.wrapper}>
						<div className={styles.databox_image}>
							<IcMVideoCall style={{ width: 14, height: 14, fill: '#fff' }} />
						</div>

						<div className={styles.tooltip}>
							<Tooltip
								content={`${currChapter.name}`}
								placement="top"
								maxWidth={500}
							>
								<div className={styles.name}>{currChapter.name}</div>

								<div className={styles.duration}>
									{currChapter?.completion_duration_value}{' '}
									{currChapter?.completion_duration_unit}
								</div>
							</Tooltip>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

function SubModuleContent({
	data = [],
	setIndexes,
	chapter = {},
	setState,
	setShowVideo = () => {},
	setSearchChapter = () => {},
	searchChapter = '',
	loading = false,
	viewType,
	pageData = {},
	page,
	setPage,
}) {
	return (
		<div
			className={styles.container}
			style={{
				paddingRight: viewType === 'helpCentre' && '8px',
			}}
			role="button"
			tabIndex="0"
		>
			<div className={styles.heading}>Watch Platform Videos</div>

			<div className={styles.input_container}>
				<Input
					className="primary lg"
					placeholder="Search Topic"
					value={searchChapter}
					onChange={(e) => setSearchChapter(e)}
					suffix={
						<IcMAppSearch style={{ marginRight: 8, width: 20, height: 20 }} />
					}
				/>
			</div>

			<ListChapters
				data={data}
				chapter={chapter}
				setState={setState}
				setIndexes={setIndexes}
				setShowVideo={setShowVideo}
				loading={loading}
			/>

			{(pageData?.total_count || 0) > 10 && !isEmpty(data) ? (
				<div className={styles.pagination_container}>
					<Pagination
						className="md"
						totalItems={100 || 0}
						currentPage={page || 1}
						pageSize={pageData?.page_limit}
						onPageChange={setPage}
					/>
				</div>
			) : null}
		</div>
	);
}

export default SubModuleContent;
