import styled from '@cogoport/front/styled';

export const UploadContainer = styled.div`
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	align-items: center;
	letter-spacing: 0.02em;
	text-transform: none;
	color: #000000;

	.blue {
		color: #034afd;
		margin-left: 2px;
		text-decoration-line: underline;
	}
`;

export const SupportedFormat = styled.div`
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 14px;
	/* identical to box height */
	text-transform: none;

	align-items: center;
	letter-spacing: 0.02em;

	/* Gray 3 */
	color: #828282;
`;

export const Column = styled.div`
	layout: column;
	margin-left: 24px;

	&.row {
		width: 50%;
	}
	&.aws {
		width: 100%;
	}
	&.center {
		align-items: center;
	}
`;

export const Row = styled.div`
	layout: row;
	align-items: center;
`;
