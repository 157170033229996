import useRequest from '@/temp/request/useRequest';
import { useSelector } from '@cogoport/front/store';

const useUpdateBulkCommunications = ({ getListCommunications }) => {
	const { profile = {}, general = {} } = useSelector((state) => state);
	const { scope } = general;

	const { data, loading, trigger } = useRequest(
		'POST',
		false,
		scope,
	)('/communication/bulk_update_communications');

	const updateBulkCommunication = async () => {
		try {
			await trigger({
				data: {
					filters: {
						type: 'platform_notification',
						user_id: profile?.id,
					},
					action_name: 'seen',
				},
			});
			getListCommunications();
		} catch (e) {
			console.error(e);
		}
	};

	return { updateBulkCommunication, data, loading };
};

export default useUpdateBulkCommunications;
