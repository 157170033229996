import styled from '@cogoport/front/styled';

const Container = styled.div`
	display: flex;
	justify-content: center;
	border: 1px dashed black;
	border-radius: 10px;
	padding: 12px 12px;
	align-items: center;
`;

export { Container };
