import React from 'react';
import TopicList from './TopicList';

function FAQs({
	faqNotificationApiLoading,
	fetchFaqNotification,
	faqNotificationData,
	refetch,
}) {
	return (
		<TopicList
			faqNotificationData={faqNotificationData}
			faqNotificationApiLoading={faqNotificationApiLoading}
			fetchFaqNotification={fetchFaqNotification}
			refetch={refetch}
		/>
	);
}

export default FAQs;
