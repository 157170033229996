import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import {
	func,
	string,
	bool,
	arrayOf,
	node,
	object,
	oneOfType,
} from 'prop-types';
import toast from '@cogoport/front/components/toast';
import useInterval from '@cogoport/front/hooks/useInterval';
import Uploader from './Upload';

import NewDragDrop from './NewDragDrop';
import { Container } from './styles';

function AwsUploader(props) {
	const {
		uploadedFilesList: filesList,
		value,
		signatureUrl,
		onChange,
		onInit,
		onlyURLOnChange,
		children,
		showProgress,
		multiple,
		docName,
		dragNDropText,
		height,
		format,
		...rest
	} = props;

	const { t } = useTranslation(['common']);

	const [showUploadedFileName, setShowUploadedFileName] = useState(true);
	const [fileName, setFileName] = useState('');
	let newValue = value;
	if (onlyURLOnChange && multiple) {
		newValue = (value || []).map((item, i) => ({
			url: item,
			name: `${docName} ${i + 1}`,
			uid: item,
		}));
	}

	const [uploadedFileList, setUploadedFileList] = useState(
		filesList || Array.isArray(newValue)
			? newValue
			: ((value || {}).url && [value]) || [],
	);
	const [percentComplete, setPercentComplete] = useState(0);
	const [isOnline, setIsOnline] = useState(true);

	useInterval(() => {
		if (percentComplete > 0 && percentComplete < 95) {
			setPercentComplete(percentComplete + 3);
		}
	}, 120);

	useEffect(() => {
		window.addEventListener('online', () => setIsOnline(true));
		window.addEventListener('offline', () => setIsOnline(false));
		return () => {
			window.removeEventListener('online', () => {});
			window.removeEventListener('offline', () => {});
		};
	}, []);

	useEffect(() => {
		let newValue1 = value;
		if (onlyURLOnChange && multiple) {
			newValue1 = (value || []).map((item, i) => ({
				url: item,
				name: `${docName} ${i + 1}`,
				uid: item,
			}));
		}
		setUploadedFileList(
			filesList || Array.isArray(newValue1)
				? newValue1
				: ((value || {}).url && [value]) ||
						(value && [{ url: value, uid: value, name: docName || 'img' }]) ||
						[],
		);
	}, [value]);

	const uploadDocument = (file, documentData) =>
		new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			const { url, headers } = JSON.parse(documentData);
			if (url) {
				xhr.open('PUT', url);
				xhr.onreadystatechange = function () {
					if (xhr.readyState === 4) {
						if (xhr.status === 200) {
							resolve(documentData);
						} else {
							setShowUploadedFileName(false);
							reject(toast.error([t('common:awsUploader_toast_1')]));
						}
					}
				};
				Object.keys(headers).forEach((header) =>
					xhr.setRequestHeader(header, headers[header]),
				);
				xhr.send(file);
			} else {
				setShowUploadedFileName(false);
				toast.error([t('common:awsUploader_toast_2')]);
			}
		});

	const getRequest = (url, params) =>
		new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest();
			xhr.open('GET', `${url}?file_name=${params.file_name}`, true);
			xhr.onreadystatechange = function () {
				if (xhr.readyState === 4) {
					if (xhr.status === 200) {
						resolve(xhr.responseText);
					} else {
						setShowUploadedFileName(false);
						reject(toast.error([t('common:awsUploader_toast_3')]));
					}
				}
			};
			xhr.send();
		});

	const getSignature = (params) => {
		try {
			const response = getRequest(signatureUrl, params);
			return response.success ? response.data : response;
		} catch (error) {
			setShowUploadedFileName(false);
			return error;
		}
	};

	const handleFilesChange = (files) => {
		const urls = files.map((item) => item.url);
		if (onlyURLOnChange) {
			onChange(urls, files);
		} else {
			onChange(files);
		}
	};
	const setImageUrls = (url, file) => {
		const files = [
			...uploadedFileList,
			{
				success: true,
				name: file.name,
				uid: file.uid,
				type: file.type,
				size: file.size,
				lastModified: file.lastModified,
				lastModifiedDate: file.lastModifiedDate,
				webkitRelativePath: file.webkitRelativePath,
				url,
			},
		];
		setUploadedFileList([...files]);
		if (multiple) handleFilesChange(files);
		else if (onlyURLOnChange) {
			onChange(url, { success: true, name: file.name, url });
		} else {
			onChange({ success: true, name: file.name, url });
		}
	};

	const handleRemove = (file) => {
		const restFiles = uploadedFileList.filter((item) => file.uid !== item.uid);
		setUploadedFileList(restFiles);
		if (multiple) handleFilesChange(restFiles);
		else onChange(null);
		setPercentComplete(0);
	};

	const handleUpload = (name, file) => {
		if (file) {
			getSignature({ file_name: name })
				.then((response) => uploadDocument(file, response))
				.then((res) => {
					const resObj = JSON.parse(res);
					if ((resObj || {}).url) {
						setImageUrls(resObj.url.split('?')[0], file);
						setPercentComplete(100);
					} else {
						setShowUploadedFileName(false);
						setPercentComplete(0);
						if (!isOnline) {
							toast.error([t('common:awsUploader_toast_4')], {
								hideAfter: 5,
							});
						} else toast.error([t('common:awsUploader_toast_5')]);
					}
				})
				.catch(() => {
					setShowUploadedFileName(false);
					setPercentComplete(0);
					if (!isOnline) {
						return toast.error([t('common:awsUploader_toast_4')], {
							hideAfter: 5,
						});
					}
					return toast.error([t('common:awsUploader_toast_6')]);
				});
		}
	};
	const handleChange = (info) => {
		if (isOnline) {
			setPercentComplete(1);
			const { name } = info.file;
			setFileName(name);
			if (onInit) {
				onInit();
			}

			const files = info.fileList || [];
			if (files.length > 0) {
				handleUpload(name, info.originalFile);
			}
		} else {
			toast.error([t('common:awsUploader_toast_4')], {
				hideAfter: 5,
			});
		}
	};

	const isNewDrag = rest.uploadType === 'aws' && rest.drag && !dragNDropText;

	const extrProps = {
		showUploadIcon: !(percentComplete > 0 && percentComplete < 100),
		dragNDropText: isNewDrag
			? () => (
					<NewDragDrop
						isUploading={percentComplete > 0 && percentComplete < 100}
						percentComplete={percentComplete}
						fileName={fileName}
						belowText={format}
					/>
			  )
			: null,
	};

	return (
		<Container className="main_upload_container">
			<Uploader
				{...rest}
				{...extrProps}
				showUploadList={showUploadedFileName}
				onChange={handleChange}
				uploadedFilesList={uploadedFileList}
				onRemove={handleRemove}
				multiple={multiple}
				// height={height}
				showUploadIcon
			>
				{children}
			</Uploader>
			{children}
		</Container>
	);
}

AwsUploader.propTypes = {
	onInit: func,
	onChange: func.isRequired,
	signatureUrl: string,
	showProgress: bool,
	// uploadedFilesList: arrayOf(object),
	multiple: bool,
	onlyURLOnChange: bool,
	value: oneOfType([arrayOf(object), object, string, arrayOf(string)]),
	docName: string,
	children: node,
	dragNDropText: func,
};

AwsUploader.defaultProps = {
	onInit: null,
	signatureUrl: `${process.env.NEXT_PUBLIC_REST_BASE_API_URL}/get_media_upload_url`,
	showProgress: true,
	// uploadedFilesList: null,
	multiple: false,
	onlyURLOnChange: false,
	value: [],
	docName: 'image',
	children: null,
	dragNDropText: null,
};

export default AwsUploader;
