import { useCallback } from 'react';

import useLegacyRequest from '../utils/getCustomAxios';

const useFetchFirebaseCustomToken = ({ supabaseClient, firestoreAuthId }) => {
	const [, trigger] = useLegacyRequest({
		url: '/get_public_firestore_custom_token',
		method: 'get',
	});

	const fetchFirebaseCustomToken = useCallback(async () => {
		try {
			const res = await trigger({
				params: { document_id: firestoreAuthId || undefined },
			});

			const { data } = res || {};

			const { refresh_token = '', access_token = '' } = data || {};

			const { data: signedInData } = await supabaseClient.auth.setSession({
				access_token,
				refresh_token,
			});
			return signedInData;
		} catch (err) {
			console.log('err:', err);
			return {};
		}
	}, [trigger, firestoreAuthId, supabaseClient.auth]);

	return {
		fetchFirebaseCustomToken,
	};
};

export default useFetchFirebaseCustomToken;
