import GLOBAL_CONSTANTS from '@/constants/globals.json';

const getToolsSubNavigation = ({ t = () => {} }) => {
	const translationKey = 'common:layouts_app_header_navigation_tools_options';

	return [
		{
			key: 'oceanTracking',
			label: t(`${translationKey}_oceanTracking_label`),
			href: '/saas/shipments-tracking',
			as: '/saas/shipments-tracking',
			type: 'link',
			description: t(`${translationKey}_oceanTracking_description`),
		},
		{
			key: 'airTracking',
			label: t(`${translationKey}_airTracking_label`),
			href: '/saas/air-tracking',
			as: '/saas/air-tracking',
			type: 'link',
			description: t(`${translationKey}_airTracking_description`),
		},
		{
			key: 'oceanSchedules',
			label: t(`${translationKey}_oceanSchedules_label`),
			href: '/saas/ocean-schedules',
			as: '/saas/ocean-schedules',
			type: 'link',
			description: t(`${translationKey}_oceanSchedules_description`),
		},
		{
			key: 'airSchedules',
			label: t(`${translationKey}_airSchedules_label`),
			href: '/saas/air-schedules',
			as: '/saas/air-schedules',
			type: 'link',
			description: t(`${translationKey}_airSchedules_description`),
		},
		{
			key: 'productClassification',
			label: t(`${translationKey}_productClassification_label`),
			href: '/saas/hs-codes',
			as: '/saas/hs-codes',
			type: 'link',
			description: t(`${translationKey}_productClassification_description`),
		},
		{
			key: 'traderEligibilityCheck',
			label: t(`${translationKey}_traderEligibilityCheck_label`),
			href: '/saas/trader-eligibility-check',
			as: '/saas/trader-eligibility-check',
			type: 'link',
			description: t(`${translationKey}_traderEligibilityCheck_description`),
		},
		{
			key: 'freightRateTrend',
			label: t(`${translationKey}_freightRateTrend_label`),
			href: '/saas/freight-rate-trend',
			as: '/saas/freight-rate-trend',
			account_type: ['importer_exporter', 'service_provider'],
			type: 'link',
			description: t(`${translationKey}_freightRateTrend_description`),
		},
	];
};

const getRatesSubNavigation = ({ t = () => {} }) => {
	const translationKey = 'common:layouts_app_header_navigation';
	return [
		{
			key: 'rateSheet',
			label: t(`${translationKey}_rates_options_rateSheet_label`),
			href: '/rate-list',
			as: '/rate-list',
			type: 'link',
			account_type: 'service_provider',
			description: t(`${translationKey}_rates_options_rateSheet_description`),
		},
		{
			key: 'ratesManagement',
			label: t(`${translationKey}_rates_options_ratesManagement_label`),
			href: '/rate-management',
			as: '/rate-management',
			type: 'link',
			account_type: 'service_provider',
			description: t(
				`${translationKey}_rates_options_ratesManagement_description`,
			),
		},
		{
			key: 'feedback',
			label: t(`${translationKey}_feedback_label`),
			href: '/feedback',
			as: '/feedback',
			type: 'link',
			account_type: 'service_provider',
			description: t(`${translationKey}_feedback_description`),
			show: {
				notKycVerified: false,
			},
		},
	];
};

const getBookingsSubNavigation = ({ t = () => {} }) => {
	const translationKey = 'common:layouts_app_header_navigation';
	return [
		{
			key: 'liveBooking',
			label: t(`${translationKey}_liveBooking_label`),
			href: '/live-booking',
			as: '/live-booking',
			type: 'link',
			account_type: 'service_provider',
			description: t(`${translationKey}_liveBooking_description`),
			show: {
				notKycVerified: false,
			},
		},
		{
			key: 'rfqEnquires',
			label: t(`${translationKey}_rfqEnquires_label`),
			href: '/rfq-enquires',
			as: '/rfq-enquires',
			type: 'link',
			account_type: 'service_provider',
			description: t(`${translationKey}_rfqEnquires_description`),
			tag: true,
			show: {
				notKycVerified: false,
			},
		},
	];
};

const getDashboardSubNavigation = ({ t = () => {} }) => {
	const translationKey = 'common:layouts_app_header_navigation';
	return [
		{
			key: 'dashboard',
			label: t(`${translationKey}_dashboard_label_for_combined_dashboard`),
			href: '/dashboard',
			as: '/dashboard',
			type: 'link',
			isQuickLink: true,
			account_type: 'importer_exporter',
			icon: 'dashboard',
			description: t(`${translationKey}_cp_dashboard_description`),
			show: {
				notKycVerified: true,
			},
			excludeCountries:
				GLOBAL_CONSTANTS.service_supported_countries.navigation.dashboard
					.exludeCountries,
		},
		{
			key: 'lsp_dashboard',
			label: t(`${translationKey}_lsp_dashboard_label_for_combined_dashboard`),
			href: '/lsp-dashboard',
			as: '/lsp-dashboard',
			type: 'link',
			isQuickLink: true,
			icon: 'dashboard',
			account_type: 'service_provider',
			description: t(`${translationKey}_lsp_dashboard_description`),
			show: {
				notKycVerified: false,
			},
		},
	];
};

const getNavigation = ({ t = () => {} }) => {
	const translationKey = 'common:layouts_app_header_navigation';

	return [
		{
			key: 'lsp_dashboard',
			label: t(`${translationKey}_lsp_dashboard_label`),
			href: '/lsp-dashboard',
			as: '/lsp-dashboard',
			type: 'link',
			isQuickLink: true,
			icon: 'dashboard',
			account_type: 'service_provider',
			show: {
				notKycVerified: false,
			},
		},
		{
			key: 'dashboard',
			label: t(`${translationKey}_dashboard_label`),
			href: '/dashboard',
			as: '/dashboard',
			type: 'link',
			isQuickLink: true,
			account_type: 'importer_exporter',
			icon: 'dashboard',
			show: {
				notKycVerified: true,
			},
			excludeCountries:
				GLOBAL_CONSTANTS.service_supported_countries.navigation.dashboard
					.exludeCountries,
		},
		{
			key: 'combineDashboards',
			label: t(`${translationKey}_combinedashboard_label`),
			options: getDashboardSubNavigation({ t }),
			type: 'rate_menu',
			show: {
				notKycVerified: false,
			},
		},
		{
			key: 'discoverRates',
			label: t(`${translationKey}_discoverRates_label`),
			href: '/discover-rates',
			as: '/discover-rates',
			type: 'link',
			icon: 'discover',
			account_type: 'importer_exporter',
			isQuickLink: true,
			show: {
				notKycVerified: true,
			},
		},
		{
			key: 'shipments',
			label: t(`${translationKey}_shipments_label`),
			href: '/shipments',
			as: '/shipments',
			type: 'link',
			icon: 'shipments',
			isQuickLink: true,
			show: {
				notKycVerified: true,
			},
		},
		{
			key: 'finance',
			label: t(`${translationKey}_finance_label`),
			href: '/finance-dashboard',
			as: '/finance-dashboard',
			type: 'link',
			icon: 'finance',
			show: {
				notKycVerified: true,
			},
		},
		{
			key: 'bookings',
			label: t(`${translationKey}_bookings_label`),
			options: getBookingsSubNavigation({ t }),
			type: 'rate_menu',
			account_type: 'service_provider',
			show: {
				notKycVerified: false,
			},
		},
		{
			key: 'tools',
			label: t(`${translationKey}_tools_label`),
			type: 'menu',
			account_type: 'importer_exporter',
			options: getToolsSubNavigation({ t }),
			show: {
				notKycVerified: true,
			},
		},
		{
			key: 'rates',
			label: t(`${translationKey}_rates_label`),
			type: 'rate_menu',
			account_type: 'service_provider',
			options: getRatesSubNavigation({ t }),
			show: {
				notKycVerified: false,
			},
		},
	];
};

export default getNavigation;
