import { Popover, Badge } from '@cogoport/components';
import { IcMNotifications } from '@cogoport/icons-react';

import useGetListCommunications from '../hooks/useGetListCommunications';
import useUpdateBulkCommunications from '../hooks/useUpdateBulkCommunications';

import NotificationsContent from './NotificationsContent';
import styles from './styles.module.css';

const DEFAULT_SEE_COUNT = 0;
function Notifications({ showPopover = false }) {
	const {
		data = {},
		loading = false,
		activeTab = '',
		setActiveTab = () => {},
		getListCommunications = () => {},
		setPage = () => {},
	} = useGetListCommunications({});

	const { updateBulkCommunication = () => {}, loading: updateBulkLoading } =
		useUpdateBulkCommunications({ getListCommunications });

	return (
		<div className={styles.notification_popover}>
			{showPopover ? (
				<Popover
					placement="bottom"
					caret={false}
					onClickOutside={() => {
						if (data?.is_not_seen_count > DEFAULT_SEE_COUNT) {
							updateBulkCommunication();
						}
					}}
					render={
						<NotificationsContent
							data={data}
							loading={loading}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							getListCommunications={getListCommunications}
							showPopover={showPopover}
							setPage={setPage}
							updateBulkCommunication={updateBulkCommunication}
							updateBulkLoading={updateBulkLoading}
						/>
					}
				>
					<div className={styles.container}>
						{data?.is_not_seen_count > DEFAULT_SEE_COUNT ? (
							<Badge
								placement="right"
								color="red"
								size="md"
								text={data?.is_not_seen_count}
							>
								<IcMNotifications className={styles.notifications} />
							</Badge>
						) : (
							<IcMNotifications className={styles.notifications} />
						)}
					</div>
				</Popover>
			) : (
				<NotificationsContent
					data={data}
					loading={loading}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					getListCommunications={getListCommunications}
					showPopover={showPopover}
					setPage={setPage}
					updateBulkCommunication={updateBulkCommunication}
					updateBulkLoading={updateBulkLoading}
				/>
			)}
		</div>
	);
}

export default Notifications;
