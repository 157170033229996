import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import { useEffect, useState } from 'react';
import useRequest from '@/utils/request/useRequest';
import { useDebounceQuery } from '@/commons/form';

const useListFaqSearchHistory = ({ activeTab = '' }) => {
	const {
		general: { scope = '' },
		profile,
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const [searchHistory, setSearchHistory] = useState('');

	const { loading, data, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/cogo_academy/list_faq_search_histories');

	const { list = [] } = data || {};

	const { query = '', debounceQuery } = useDebounceQuery();

	const { id = '' } = profile || {};

	const fetchFaqSearchHistory = () => {
		try {
			trigger({
				params: {
					filters: {
						user_id: id,
						is_cleared: false,
						q: query || undefined,
					},
					sort_by: 'updated_at',
				},
			});
		} catch (error) {
			Toast.error(getApiErrorString(error?.data));
		}
	};

	useEffect(() => {
		debounceQuery(searchHistory);
	}, [searchHistory]);

	useEffect(() => {
		fetchFaqSearchHistory();
	}, [query]);

	useEffect(() => {
		if (activeTab === 'search_history') {
			fetchFaqSearchHistory();
		}
	}, [activeTab]);

	return {
		data,
		searchHistory,
		setSearchHistory,
		list,
		loading,
		trigger,
		fetchFaqSearchHistory,
	};
};

export default useListFaqSearchHistory;
