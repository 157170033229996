import { forwardRef } from 'react';
// import { oneOfType, string, number } from 'prop-types';

function Image(props, ref) {
	return <img alt="world" ref={ref} {...props} />;
}

// Image.propTypes = { height: oneOfType([string, number]) };

// Image.defaultProps = { height: null };

export default forwardRef(Image);
