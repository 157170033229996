import React from 'react';
import { useTranslation } from 'next-i18next';
import { getElementController } from '@/commons/form/utils/getElementController';
import styles from './styles.module.css';

const translationKey = 'common:components_header_tickets_create';

function FormField({ fields = {}, control = {}, errors = {} }) {
	const { t } = useTranslation(['common']);
	const { label, type, name } = fields;
	const Element = getElementController(type) || null;

	if (!Element) {
		return null;
	}

	return (
		<div className={styles.form_field_container} key={name}>
			<div className={styles.form_field_label}>{label}</div>
			<div>
				<Element {...fields} control={control} size="sm" />
			</div>
			<div className={styles.form_field_error}>
				{errors?.[name] && `${label} ${t(`${translationKey}_required`)}`}
			</div>
		</div>
	);
}

export default FormField;
