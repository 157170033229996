import getOptionsFromKey from '@/temp/form/components/Business/Select/utils/getOptionsFromKey';
import STATIC_OPTIONS from '../configs/STATIC_OPTIONS';

const getStaticOptions = ({
	optionsListKey,
	commodityType = '',
	containerType = '',
	country_code,
}) => {
	let options = [];

	if (STATIC_OPTIONS.includes(optionsListKey)) {
		options = getOptionsFromKey(optionsListKey, {
			commodityType,
			containerType,
			country_code,
		})?.options;
	}

	return options;
};

export default getStaticOptions;
