import { isEmpty } from '@cogoport/utils';

import { IcMCross } from '@cogoport/icons-react';
import LoadingState from './LoadingState';
import styles from './styles.module.css';

function ModuleContent({
	loading,
	chapter = {},
	viewType = '',
	setVideo = () => {},
}) {
	const {
		name,
		content_type = '',
		chapter_content = '',
		description,
	} = chapter;

	if (loading) {
		return <LoadingState />;
	}

	return (
		<div
			className={styles.container}
			style={
				viewType === 'widgetModal'
					? { margin: 20, marginTop: 30, cursor: 'pointer' }
					: null
			}
		>
			<div className={styles.header}>
				<div className={styles.name}>{name}</div>
				{viewType === 'widgetModal' ? (
					<IcMCross onClick={() => setVideo({})} />
				) : null}
			</div>

			{!isEmpty(description) && (
				<div className={styles.description}>
					<p>{description}</p>
				</div>
			)}

			<div className={styles.content}>
				<iframe
					style={{
						width: '90%',
						marginTop: '20px',
						height: content_type === 'document' ? '80vh' : '450px',
						border: '0',
					}}
					src={
						chapter_content.includes('/watch?v=')
							? chapter_content.replace('/watch?v=', '/embed/')
							: ''
					}
					title="Embedded youtube"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					frameBorder="0"
					allowFullScreen
				/>
			</div>
		</div>
	);
}

export default ModuleContent;
