import { startCase } from '@cogoport/front/utils';

const showError = async (message, type = '') => {
	if (typeof window !== 'undefined') {
		// const { cogoToast } = await import('@cogo/deprecated_legacy/ui');
		// if (type === 'warn') {
		// 	cogoToast.warn(message, { hideAfter: 5 });
		// } else {
		// 	cogoToast.error(message, { hideAfter: 5 });
		// }
	}
};

const axiosConfigs = (requestConfigs = {}) => {
	const {
		baseURL,
		auth_token,
		auth_scope,
		axiosConfigs: extraAxiosConfigs,
	} = requestConfigs;

	const restConfigs = extraAxiosConfigs || {};

	return {
		header_keys: {
			auth_token,
			auth_scope,
		},
		baseURLs: {
			main: baseURL,
		},
		responseInterceptor: [
			(response) => {
				return {
					hasError: false,
					data: response.data,
				};
			},
			(error) => {
				if (error.response) {
					const errorResponse = error.response;
					const { status } = errorResponse;
					if (status === 400 || status === 500) {
						const res = errorResponse.data;
						const keys = Object.keys(res);
						const errorObj = {};
						keys.forEach((key) => {
							errorObj[key] = `${startCase(key)} ${
								Array.isArray(res[key]) ? (res[key] || []).join(', ') : res[key]
							}`;
						});
						return { hasError: true, messages: errorObj, status };
					}
					if (status === 401) {
						showError('Authentication failed!');
					} else if (status === 403) {
						//
					} else {
						showError('At this moment, we are unable to service this request.');
					}
					return { hasError: true, messages: [error.toString()], status };
				}
				return { hasError: true, messages: [error.toString()] };
			},
		],
		...restConfigs,
	};
};

const defaultConfigs = (requestConfigs) => {
	const { scope, storeKey } = requestConfigs;

	return {
		default: scope,
		storeKey: storeKey || process.env.NEXT_PUBLIC_STORE_KEY,
		data: { [scope]: axiosConfigs(requestConfigs) },
	};
};

export default defaultConfigs;
