import styled from '@cogoport/front/styled';

const PATHNAME_BACKGROUND_COLOR_MAPPING = {
	'/[partner_id]/book/[search_id]': '#F5F5F7',
	'/[partner_id]/checkout/[checkout_id]': '#F5F5F7',
	'/[partner_id]/book/[search_id]/[shipment_id]': '#F5F5F7',
	'/[partner_id]/checkout/[checkout_id]/[shipment_id]': '#F5F5F7',
	'/[partner_id]/demo': '#FAFAFA',
	'/[partner_id]/saas/subscription-v2/checkout/[id]': '#F9F9F9',
};

const PATHNAME_PADDING_MAPPING = {
	'/[partner_id]/help-center': '0 0',
	'/[partner_id]/dashboard': '0 0',
	'/[partner_id]/help-center/topics': '0 0',
	'/[partner_id]/checkout/[checkout_id]': '0 0',
	'/[partner_id]/help-center/topic/[topic_id]': '0 0',
	'/[partner_id]/saas/subscription-v2/checkout/[id]': '0 0',
};

export const Main = styled.main`
	width: 100%;
	padding: 0 36px;
	flex: 1;
	margin-top: 56px;

	${(props) =>
		`background-color: ${
			PATHNAME_BACKGROUND_COLOR_MAPPING[props.pathname] || ''
		};`}

	${(props) => `padding: ${PATHNAME_PADDING_MAPPING[props.pathname] || ''};`}

	@media (max-width: 768px) {
		margin-bottom: 80px;
		padding: 0 4px;
	}
`;
