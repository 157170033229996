import { Loader, Button } from '@cogoport/components';
import { isEmpty } from '@cogoport/front/utils';
import { useTranslation } from 'next-i18next';
import NotificationsTabs from './Tabs';
import styles from './styles.module.css';

function Title({
	updateBulkLoading = false,
	updateBulkCommunication = () => {},
}) {
	const { t } = useTranslation(['common']);
	return (
		<div>
			{updateBulkLoading ? (
				<Loader />
			) : (
				<Button themeType="tertiary" onClick={updateBulkCommunication}>
					{t('common:mark_all_as_read_notifications')}
				</Button>
			)}
		</div>
	);
}

function NotificationsContent({
	data = {},
	loading = false,
	getListCommunications = () => {},
	showPopover = false,
	setPage = false,
	updateBulkCommunication = () => {},
	updateBulkLoading = false,
}) {
	const { t } = useTranslation(['common']);

	return (
		<div className={`${showPopover ? styles.container : styles.main}`}>
			<div className={styles.heading}>
				<div className={styles.label}>{`${t('common:notifications')}`}</div>
				<div className={styles.box}>
					{!isEmpty(data?.list) && (
						<div role="presentation" onClick={updateBulkCommunication}>
							{showPopover ? (
								<Title updateBulkCommunication={updateBulkCommunication} />
							) : (
								<Button
									loading={updateBulkLoading}
									themeType="accent"
									size="md"
								>
									{t('common:mark_all_as_read_notifications')}
								</Button>
							)}
						</div>
					)}
				</div>
			</div>
			<NotificationsTabs
				data={data}
				loading={loading}
				getListCommunications={getListCommunications}
				showPopover={showPopover}
				setPage={setPage}
			/>
		</div>
	);
}

export default NotificationsContent;
