import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import useRequest from '@/utils/request/useRequest';

const useUpdateFaqSearchHistory = ({ setShowHistory }) => {
	const {
		general: { scope = '' },
		profile,
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const { id = '' } = profile || {};

	const params = {
		user_id: id,
		is_cleared: true,
	};

	const { loading, data, trigger } = useRequest(
		'post',
		false,
		scope,
	)('/cogo_academy/update_faq_search_history');

	const onClickClearHistory = async () => {
		try {
			await trigger({ params });

			setShowHistory(false);

			Toast.success('History deleted successfully!');
		} catch (err) {
			Toast.error(getApiErrorString(err?.data));
		}
	};

	return { onClickClearHistory, data, loading };
};

export default useUpdateFaqSearchHistory;
