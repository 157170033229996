import { bool, string, number } from 'prop-types';
import { useTranslation } from 'next-i18next';
import { UploadContainer, SupportedFormat, Column, Row } from './styles';

function NewDragDrop({
	isUploading,
	fileName,
	percentComplete,
	belowText = null,
}) {
	const { t } = useTranslation(['common']);
	return !isUploading ? (
		<Column className="center">
			<UploadContainer className="core-ui-file-upload">
				{t('common:awsUploader_newDragDrop_drop')}{' '}
				<span className="blue">
					{t('common:awsUploader_newDragDrop_browse')}
				</span>
			</UploadContainer>
			{belowText ? (
				<SupportedFormat>{belowText}</SupportedFormat>
			) : (
				<SupportedFormat>
					{t('common:awsUploader_newDragDrop_img')}
				</SupportedFormat>
			)}
		</Column>
	) : (
		<Row>
			<Column className="center">
				<UploadContainer>
					{fileName || t('common:awsUploader_newDragDrop_uploading')}
				</UploadContainer>
				<SupportedFormat>
					{percentComplete}
					{t('common:awsUploader_newDragDrop_upload')}
				</SupportedFormat>
			</Column>
		</Row>
	);
}

NewDragDrop.propTypes = {
	isUploading: bool,
	fileName: string.isRequired,
	percentComplete: number.isRequired,
};

NewDragDrop.defaultProps = { isUploading: false };

export default NewDragDrop;
