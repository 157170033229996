import { merge } from '@cogoport/front/utils';
import { useEffect, useState } from 'react';
import useRequest from '@/utils/request/useRequest';

import useDebounceQuery from './useDebounceQuery';
import getConditionalParam from './getConditionalParam';

const MICROSERVICEMAPPING = {
	auth: 'auth',
	partner: 'partner',
};

function useGetAsyncOptions({
	endpoint = '',
	initialCall = false,
	valueKey = '',
	labelKey = '',
	params = {},
	microService = '',
	authkey = '',
	getModifiedOptions,
}) {
	const { query, debounceQuery } = useDebounceQuery();
	const [storeoptions, setstoreoptions] = useState([]);

	const selectedMicroService = MICROSERVICEMAPPING[microService];

	const queryParam = getConditionalParam('q', query, authkey);

	const { data, loading } = useRequest(
		'get',
		initialCall || query,
		selectedMicroService,
	)(`/${endpoint}`, { params: merge(params, queryParam) });

	let options = data?.list || [];

	if (typeof getModifiedOptions === 'function' && Array.isArray(options)) {
		options = getModifiedOptions({ options });
	}

	const { trigger: triggerSingle, loading: loadingSingle } = useRequest(
		'get',
		false,
		selectedMicroService,
	)(`/${endpoint}`);

	useEffect(() => {
		storeoptions.push(...options);
		setstoreoptions(storeoptions);
	}, [options]);

	const onSearch = (inputValue) => {
		debounceQuery(inputValue);
	};

	const onHydrateValue = async (value) => {
		if (Array.isArray(value)) {
			let unorderedHydratedValue = [];

			const toBeFetched = [];
			value.forEach((v) => {
				const singleHydratedValue = storeoptions.find(
					(o) => o?.[valueKey] === v,
				);

				if (singleHydratedValue) {
					unorderedHydratedValue.push(singleHydratedValue);
				} else {
					toBeFetched.push(v);
				}
			});

			let res;
			if (toBeFetched.length) {
				res = await triggerSingle({
					params: merge(
						params,
						getConditionalParam(valueKey, toBeFetched, authkey),
					),
				});
				storeoptions.push(...(res?.data?.list || []));
			}
			unorderedHydratedValue = unorderedHydratedValue.concat(
				res?.data?.list || [],
			);

			const hydratedValue = value.map((v) => {
				const singleHydratedValue = unorderedHydratedValue.find(
					(uv) => uv?.[valueKey] === v,
				);
				return singleHydratedValue;
			});

			return hydratedValue;
		}

		const checkOptionsExist = options.filter(
			(item) => item[valueKey] === value,
		);

		if (checkOptionsExist.length > 0) return checkOptionsExist[0];

		const res = await triggerSingle({
			params: merge(params, getConditionalParam(valueKey, value, authkey)),
		});

		return res?.data?.list?.[0] || null;
	};

	return {
		loading: loading || loadingSingle,
		onSearch,
		options,
		labelKey,
		valueKey,
		onHydrateValue,
	};
}

export default useGetAsyncOptions;
