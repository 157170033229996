import toast from '@cogoport/front/components/admin/Toast';
import { useSelector } from '@cogoport/front/store';
import useRequest from '@/utils/request/useRequest';

const useCreateTicketActivity = ({
	ticketId,
	refetchTicket,
	scrollToBottom,
}) => {
	const { profile } = useSelector((state) => state);

	const { trigger, loading } = useRequest('post', false, 'cogocare', {
		authkey: 'post_tickets_activity',
	})(`/activity`);

	const createTicketActivity = async ({ file, message }) => {
		const payload = {
			Description: message,
			Type: 'respond',
			TicketID: [Number(ticketId)],
			Status: 'activity',
			Data: { Url: [file] || [] },
		};

		try {
			await trigger({
				data: {
					...payload,
					UserType: 'ticket_user',
					PerformedByID: profile?.id,
				},
			});

			refetchTicket();
			scrollToBottom();
		} catch (error) {
			toast.error(error?.error);
		}
	};

	return {
		createTicketActivity,
		createLoading: loading,
	};
};

export default useCreateTicketActivity;
