import React, { useEffect, useState } from 'react';
import QuestionListComponent from '../../QuestionListComponent';
import Header from '../QuestionList/Header';
import styles from './styles.module.css';
import useTopicList from './useTopicList';

function TopicList({
	faqNotificationApiLoading = false,
	fetchFaqNotification = () => {},
	faqNotificationData = {},
	refetch = () => {},
	from = 'cogo_assist',
}) {
	const [input, setInput] = useState('');

	const {
		search,
		setSearch,
		list,
		loading,
		paginationData,
		page,
		setPage,
		topic,
		setTopic,
		question,
		setQuestion,
		showHistory,
		setShowHistory,
		showNotificationContent,
		setShowNotificationContent,
	} = useTopicList();

	useEffect(() => {
		fetchFaqNotification();
	}, [fetchFaqNotification, showNotificationContent]);

	useEffect(() => {
		if (from === 'test_module') return;
		setSearch('');
		setInput('');
	}, [from, setSearch]);

	return (
		<div className={styles.main_container}>
			<Header
				search={search}
				setSearch={setSearch}
				topic={topic}
				setTopic={setTopic}
				question={question}
				showHistory={showHistory}
				setQuestion={setQuestion}
				setShowHistory={setShowHistory}
				setShowNotificationContent={setShowNotificationContent}
				showNotificationContent={showNotificationContent}
				fetchFaqNotification={fetchFaqNotification}
				refetch={refetch}
				from={from}
				setInput={setInput}
				input={input}
			/>
			<QuestionListComponent
				question={question}
				setQuestion={setQuestion}
				loading={loading}
				search={search}
				faqNotificationApiLoading={faqNotificationApiLoading}
				paginationData={paginationData}
				page={page}
				setPage={setPage}
				list={list}
				from={from}
				faqNotificationData={faqNotificationData}
				topic={topic}
				setTopic={setTopic}
				showHistory={showHistory}
				setSearch={setSearch}
				fetchFaqNotification={fetchFaqNotification}
				showNotificationContent={showNotificationContent}
				setShowNotificationContent={setShowNotificationContent}
				setShowHistory={setShowHistory}
				setInput={setInput}
			/>
		</div>
	);
}

export default TopicList;
