import { useSelector } from '@cogoport/front/store';
import { useEffect } from 'react';
import useRequest from '@/utils/request/useRequest';

const useAnswer = ({ question, setIsLiked, FEEDBACK_MAPPING_ISLIKED }) => {
	const {
		general: { scope = '' },
	} = useSelector((state) => ({
		general: state.general,
	}));

	const { data, loading, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/cogo_academy/get_question');

	const fetch = async () => {
		try {
			const res = await trigger({
				params: { id: question?.id },
			});

			const { is_positive } = res?.data?.answers?.[0]?.faq_feedbacks?.[0] || {};

			setIsLiked(FEEDBACK_MAPPING_ISLIKED[is_positive] || '');
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		fetch();
	}, []);

	return {
		data,
		loading,
		fetch,
	};
};

export default useAnswer;
