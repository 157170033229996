import GLOBAL_CONSTANTS from '@/constants/globals.json';
import useGetOrgCommunicationDetails from '@/commons/hooks/useGetOrgCommunicationDetails';
import ModuleContent from './components/ModuleContent';
import styles from './styles.module.css';
import useGetDemoVideos from './hooks/useGetDemoVideos';
import SubModuleContent from './components/SubModuleContent';
import UpcomingDemo from './components/UpcomingDemo';

function DemoVideoPage({ viewType = 'preview', setShowVideo }) {
	const {
		loading,
		chapterData,
		chapter,
		setIndexes,
		setChapter,
		indexes,
		searchChapter,
		setSearchChapter,
		pageData,
		page,
		setPage,
		country_id,
	} = useGetDemoVideos();

	const { data = {}, loading: demoListLoading = false } =
		useGetOrgCommunicationDetails();

	const setState = ({ current_chapter }) => {
		setChapter(current_chapter);
	};

	return (
		<div className={styles.container}>
			<div className={styles.main_content}>
				{viewType !== 'helpCentre' ? (
					<div className={styles.module_content}>
						<ModuleContent loading={loading} chapter={chapter} />
					</div>
				) : null}

				<div
					className={styles.sub_schedule_content}
					style={viewType === 'helpCentre' ? { width: '100%' } : null}
				>
					{country_id === GLOBAL_CONSTANTS.country_ids.IN ? (
						<UpcomingDemo data={data} loading={demoListLoading} />
					) : null}

					<SubModuleContent
						data={chapterData}
						loading={loading}
						chapter={chapter}
						setChapter={setChapter}
						setState={setState}
						indexes={indexes}
						setIndexes={setIndexes}
						setShowVideo={setShowVideo}
						searchChapter={searchChapter}
						setSearchChapter={setSearchChapter}
						viewType={viewType}
						pageData={pageData}
						page={page}
						setPage={setPage}
					/>
				</div>
			</div>
		</div>
	);
}

export default DemoVideoPage;
