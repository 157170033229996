import { Placeholder } from '@cogoport/components';

const LOADING_ARRAY = [...Array(10).keys()];

const Loader = () =>
	LOADING_ARRAY.map((ele) => (
		<Placeholder key={ele} height="60px" margin="10px" />
	));

export default Loader;
