const SEARCHABLE_SERVICES = [
	{ value: 'fcl_freight', label: 'FCL' },
	{ value: 'lcl_freight', label: 'LCL' },
	{ value: 'air_freight', label: 'AIR' },
	{ value: 'trailer_freight', label: 'Container Transportation' },
	{ value: 'ftl_freight', label: 'FTL' },
	{ value: 'ltl_freight', label: 'LTL' },
	{ value: 'haulage_freight', label: 'Rail Haulage' },
	{ value: 'fcl_customs', label: 'FCL Customs' },
	{ value: 'lcl_customs', label: 'LCL Customs' },
	{ value: 'air_customs', label: 'AIR Customs' },
];

export default SEARCHABLE_SERVICES;
