import { isEmpty } from '@cogoport/front/utils';
import getValue from '@/commons/utils/getValue';
import { deleteCookie, getCookie } from '@/temp/cogo-cookies';
import getNavigationList from '@/commons/utils/getNavigationList';
import { getCountryId } from '@/commons/utils/getCountryId';
import redirect from '../redirect';
import getPartner from './getPartner';
import getUserData from './getUserData';
import { setProfileStoreState } from '../stores';
import getDefaultPath from '../getDefaultPath';

const INDIA_COUNTRY_ID = getCountryId('IN');

const REDIRECT_PATH_URL = ['/url/[token]'];

const UNAUTHENTICATED_PATHS = [
	'/login',
	'/signup',
	'/welcome',
	'/schedule-demo',
	'/forgot-password',
	'/reset-password/[id]',
	'/verify-email/[id]',
	'/accept-invite/[id]',
	'/verify-auto-sign-up-email/[id]',
	'/verify-auto-login/[token]',
	'/mobile-verification/[id]',
	'/terms-and-conditions/[id]',
	'/market-feedback-form/[id]',
	'/draft-airway-bill/[id]',
	'/shipment-jobs/[id]',
];

const VERIFICATION_PATHS = [
	'/reset-password/[id]',
	'/verify-email/[id]',
	'/accept-invite/[id]',
	'/verify-auto-sign-up-email/[id]',
	'/mobile-verification/[id]',
	'/terms-and-conditions/[id]',
	'/draft-airway-bill/[id]',
	'/market-feedback-form/[id]',
	'/verify-auto-login/[token]',
];

const DEFAULT_PATHS = {
	NOT_VERIFIED_KYC: '/submit-kyc',
	AUTHENTICATED_CP: '/dashboard',
	AUTHENTICATED_LSP: '/lsp-dashboard',
	UNAUTHENTICATED: '/login',
};

const NOT_KYC_VERIFIED_ALLOWED_PATHS_NOT_IN_NAVIGATION = [
	'/demo',
	'/faqs',
	'/menu',
	'/profile',
	'/submit-kyc',
];

const AUTHENTICATED_ALLOWED_PATHNAME = [
	'/[partner_id]/help-center',
	'/[partner_id]/help-center/topics',
	'/[partner_id]/help-center/faq/[faq_id]',
	'/[partner_id]/help-center/topic/[topic_id]',
];

const AUTH_TOKEN_NAME = process.env.NEXT_PUBLIC_AUTH_TOKEN_NAME;

const handleAuthentication = async ({
	asPath,
	store,
	isServer,
	res,
	req,
	pathname,
	query,
}) => {
	let asPrefix = '';

	if ((asPath || '').includes('_next') || (asPath || '').includes('sw.js')) {
		return { asPrefix };
	}

	if (['/'].includes(asPath) || ['/'].includes(pathname)) {
		return { asPrefix };
	}

	if (
		REDIRECT_PATH_URL.includes(asPath) ||
		REDIRECT_PATH_URL.includes(pathname)
	) {
		return { asPrefix };
	}
	const isUnauthenticatedPath =
		UNAUTHENTICATED_PATHS.includes(asPath) ||
		UNAUTHENTICATED_PATHS.includes(pathname);

	const token = getCookie(AUTH_TOKEN_NAME, isServer ? { req } : null);

	const isVerificationPath =
		VERIFICATION_PATHS.includes(asPath) ||
		VERIFICATION_PATHS.includes(pathname);

	if (token && isVerificationPath) {
		try {
			deleteCookie(AUTH_TOKEN_NAME, { req, res });
		} catch (e) {
			console.log(e);
		}
	}

	if (!token) {
		if (isUnauthenticatedPath) {
			return { asPrefix };
		}

		const path = `${DEFAULT_PATHS.UNAUTHENTICATED}?redirectPath=${asPath}`;
		redirect({ isServer, res, path });
		return { asPrefix };
	}

	const userData = await getUserData({
		store,
		isServer,
		req,
	});

	if (isEmpty(userData)) {
		if (!isServer) {
			try {
				deleteCookie(AUTH_TOKEN_NAME, { req, res });
			} catch (e) {
				console.log(e);
			}
		}

		if (isUnauthenticatedPath) {
			return { asPrefix };
		}

		const path = `${DEFAULT_PATHS.UNAUTHENTICATED}?redirectPath=${asPath}`;

		redirect({ isServer, res, path });
		return { asPrefix };
	}

	if (asPath.startsWith('/get-started')) {
		return { asPrefix };
	}

	const { partner_id: channelPartnerId } = query;

	const { partners: channelPartners = [], permissions_navigations } = userData;

	const channelPartner =
		channelPartners.find((channelPartnerItem) => {
			return channelPartnerItem.id === channelPartnerId;
		}) ||
		channelPartners[0] ||
		{};

	if (isEmpty(channelPartner)) {
		if (asPath.includes('/get-started')) {
			return { asPrefix };
		}

		redirect({ isServer, res, path: '/get-started' });
		return { asPrefix };
	}

	let activeChannelPartnerServer = null;
	let activeChannelPartnerNavigationPermissionsServer = null;

	if (isServer && channelPartnerId) {
		const response = await getPartner({ req, query });

		activeChannelPartnerServer = response.partner || {};
		activeChannelPartnerNavigationPermissionsServer =
			response.permissions_navigations;
	}

	const activeChannelPartner = activeChannelPartnerServer || channelPartner;
	const activeChannelPartnerNavigationPermissions =
		activeChannelPartnerNavigationPermissionsServer || permissions_navigations;
	const { twin_importer_exporter_id, twin_service_provider_id } =
		activeChannelPartner;

	const account_types = [
		twin_importer_exporter_id ? 'importer_exporter' : '',
		twin_service_provider_id ? 'service_provider' : '',
	].filter((accountType) => accountType);

	await store.dispatch(
		setProfileStoreState({
			partner: {
				...activeChannelPartner,
				account_types,
			},
			permissions_navigations: activeChannelPartnerNavigationPermissions,
		}),
	);

	asPrefix = `/${activeChannelPartner.id}`;

	const isAllowedPath = AUTHENTICATED_ALLOWED_PATHNAME.some(
		(allowedPathname) => {
			return allowedPathname === pathname;
		},
	);

	if (isAllowedPath) {
		return { asPrefix };
	}

	const {
		tnc_accepted: isTermsAndConditionAccepted = false,
		country_id: countryId = '',
	} = activeChannelPartner;

	if (countryId === INDIA_COUNTRY_ID) {
		if (!isTermsAndConditionAccepted) {
			if (pathname.includes('/[partner_id]/accept-terms-and-conditions')) {
				return { asPrefix };
			}

			if (pathname.includes('/[partner_id]/get-started')) {
				return { asPrefix };
			}

			redirect({ isServer, res, path: `${asPrefix}/get-started` });
			return { asPrefix };
		}
	}

	if (countryId !== INDIA_COUNTRY_ID) {
		if (pathname.includes('/[partner_id]/get-started')) {
			return { asPrefix };
		}
	}

	const { verifications } = activeChannelPartner;
	const kycStatus = getValue(verifications, '[0].kyc_status');
	const isKycVerified = kycStatus === 'verified';

	if (!isKycVerified && twin_service_provider_id) {
		const isAsPathPresentInNotKycVerifiedAllowedPaths =
			NOT_KYC_VERIFIED_ALLOWED_PATHS_NOT_IN_NAVIGATION.some((pathItem) => {
				return asPath.includes(pathItem);
			});

		if (isAsPathPresentInNotKycVerifiedAllowedPaths) {
			return { asPrefix };
		}

		let redirectToSubmitKycScreen = isServer;

		if (!isServer) {
			const menuList = getNavigationList({
				isKycVerified,
				account_types,
			});

			const isNavigationAsPathAllowed = menuList.some((menuItem) => {
				if (menuItem.type === 'menu') {
					const { options } = menuItem;

					return (options || []).some((subMenuItem) => {
						return asPath.includes(subMenuItem.href);
					});
				}

				return asPath.includes(menuItem.href);
			});

			if (isNavigationAsPathAllowed) {
				return { asPrefix };
			}

			redirectToSubmitKycScreen = true;
		}

		if (redirectToSubmitKycScreen) {
			if (asPath.includes(DEFAULT_PATHS.NOT_VERIFIED_KYC)) {
				return { asPrefix };
			}

			const route = `${asPrefix}${DEFAULT_PATHS.NOT_VERIFIED_KYC || '/'}`;

			redirect({ isServer, res, path: route });
			return { asPrefix };
		}
	}

	const defaultPath = getDefaultPath({ activeChannelPartner }) || '/';

	let defaultRoute = `${asPrefix}${defaultPath}`;

	if (query.redirectPath) {
		const redirectPath = decodeURIComponent(query.redirectPath);
		if (redirectPath.split('/').includes(activeChannelPartner.id)) {
			defaultRoute = redirectPath;
		} else {
			defaultRoute = `${asPrefix}${redirectPath}`;
		}
	}

	if (
		pathname.includes('/[partner_id]/get-started')
		// asPath.includes(DEFAULT_PATHS.NOT_VERIFIED_KYC)
	) {
		redirect({ isServer, res, path: defaultRoute });
		return { asPrefix };
	}

	const asPathArr = asPath.split('/');
	if (
		!(`/${asPathArr[1]}` === asPrefix || `/${asPathArr[2]}` === asPrefix) ||
		pathname.includes('_error')
	) {
		redirect({
			isServer,
			res,
			path: defaultRoute,
		});
	}

	return { asPrefix };
};

export default handleAuthentication;
