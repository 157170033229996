import { makeUseAxios } from 'axios-hooks';

import customAxios from './custom-axios';

const useAxios = makeUseAxios({
	axios: customAxios,
});

const METTHOD_WITH_BODIES = ['post', 'put', 'patch'];
const METHOD_WITHOUT_BODIES = ['get', 'delete', 'head', 'options'];

const useRequest =
	(httpMethod, auto = false, scope, restOptions) =>
	(url, ...args) => {
		let body;
		let options;

		const { autoCancel = true, authKey = '' } = restOptions || {};

		const method = httpMethod.toLowerCase();

		if (METTHOD_WITH_BODIES.includes(method)) {
			body = args[0];
			options = args[1];
		} else if (METHOD_WITHOUT_BODIES.includes(method)) {
			options = args[0];
		}

		const [{ loading, error, data, response }, trigger] = useAxios(
			{
				url,
				method,
				scope,
				data: body,
				authKey,
				...options,
			},
			{
				manual: !auto,
				ssr: false,
				autoCancel,
			},
		);

		return {
			loading,
			error,
			data,
			response,
			trigger,
		};
	};

export default useRequest;
