import { useCallback } from 'react';

import useFetchFirebaseCustomToken from './useFetchFirebaseCustomToken';

import useFetchPrivateFirebaseCustomToken from './useFetchPrivateFirebaseCustomToken';

function useAuthenticateFirebase({
	isUnKnownUser = false,
	supabaseClient,
	firestoreAuthId,
}) {
	const { fetchFirebaseCustomToken } = useFetchFirebaseCustomToken({
		supabaseClient,
		firestoreAuthId,
	});
	const { fetchPrivateFirebaseCustomToken } =
		useFetchPrivateFirebaseCustomToken({ supabaseClient, firestoreAuthId });

	const signInUser = useCallback(async () => {
		const { data } = await supabaseClient.auth.getSession();

		if (data && data?.session) {
			const { role = '' } = data.session.user;

			if (isUnKnownUser && role !== 'anon') {
				return fetchFirebaseCustomToken({ supabaseClient });
			}
			if (!isUnKnownUser && role === 'anon') {
				return fetchPrivateFirebaseCustomToken({ supabaseClient });
			}
			return data;
		}

		if (isUnKnownUser) {
			return fetchFirebaseCustomToken({ supabaseClient });
		}

		return fetchPrivateFirebaseCustomToken({ supabaseClient });
	}, [
		fetchFirebaseCustomToken,
		isUnKnownUser,
		supabaseClient,
		fetchPrivateFirebaseCustomToken,
	]);

	return { signInUser };
}
export default useAuthenticateFirebase;
