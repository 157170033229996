import { IcMDocument } from '@cogoport/icons-react';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import formatDate from '@/temp/utils/formatDate';
import { isEmpty } from '@cogoport/utils';
import Loader from '../../Loader';
import Answer from '../../QuestionList/Answer';
import styles from './styles.module.css';
import useClearFaqNotifications from './useClearFaqNotifications';

function NotificationContent({
	faqNotificationData,
	question,
	setQuestion,
	setShowNotificationContent,
	faqNotificationApiLoading,
}) {
	const { onClickClearNotifications = () => {} } = useClearFaqNotifications({
		setShowNotificationContent,
		faqNotificationData,
	});

	const filteredObject = {};

	const today = new Date();
	const formatToday = formatDate({
		date: today,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	const yesterday = new Date(
		today.getTime() - GLOBAL_CONSTANTS.others.milliseconds_per_day,
	);

	const formatYesterday = formatDate({
		date: yesterday,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	(faqNotificationData || []).forEach((ele) => {
		const { created_at, question_abstract, id } = ele || {};
		const date = formatDate({
			date: created_at,
			dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
			formatType: 'date',
		});

		const time = formatDate({
			date: created_at,
			dateFormat: GLOBAL_CONSTANTS.formats.time['hh:mm aa'],
			formatType: 'time',
		});

		if (date && question_abstract) {
			if (!filteredObject[date]) {
				filteredObject[date] = [{ question_abstract, time, id }];
			} else {
				filteredObject[date].push({
					question_abstract,
					time,
					id,
				});
			}
		}
	});

	const sortedDates = Object.keys(filteredObject || {})
		.map((item) => item)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

	const DAY_MAPPING = {
		[formatToday]: 'Today',
		[formatYesterday]: 'Yesterday',
	};

	if (faqNotificationApiLoading)
		return (
			<div className={styles.loader_wrapper}>
				<Loader />
			</div>
		);

	if (question) {
		return <Answer question={question} setQuestion={setQuestion} />;
	}
	return (
		<div className={styles.container}>
			{sortedDates.map((ele) => (
				<>
					<div className={styles.header}>{DAY_MAPPING[ele] || ele}</div>

					{(filteredObject[ele] || []).map((a) => {
						const { question_abstract, time, id } = a || {};

						const selectedQuestion = (faqNotificationData || []).find(
							(element) => element.id === id,
						);

						return (
							<div
								className={styles.question_container}
								role="presentation"
								onClick={() => setQuestion(selectedQuestion)}
							>
								<div className={styles.icon_wrapper}>
									<IcMDocument width={25} height={25} />
								</div>

								<div className={styles.answer_added_container}>
									<div className={styles.question_time_container}>
										<div className={styles.answer_container}>Answer Added</div>

										<div className={styles.time_wrapper}>{time}</div>
									</div>

									<div className={styles.question_wrapper}>
										{question_abstract}
									</div>
								</div>
							</div>
						);
					})}
				</>
			))}

			{!isEmpty(faqNotificationData) && (
				<div
					className={styles.clear_update}
					role="presentation"
					onClick={onClickClearNotifications}
				>
					Clear Updates
				</div>
			)}
		</div>
	);
}

export default NotificationContent;
