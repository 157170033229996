import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import { useEffect } from 'react';
import useRequest from '@/utils/request/useRequest';

const useGetUserRequestedFaqs = ({ activeTab = '' }) => {
	const {
		general: { scope = '' },
		profile,
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const { id = '' } = profile || {};

	const { loading, data, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/cogo_academy/get_user_requested_faqs');

	const getUserFaqs = () => {
		try {
			trigger({
				params: {
					filters: {
						status: 'active',
					},
					user_id: id,
				},
			});
		} catch (error) {
			Toast.error(getApiErrorString(error?.data));
		}
	};

	const { requested_questions: list } = data || {};

	useEffect(() => {
		if (activeTab === 'requested_question') {
			getUserFaqs();
		}
	}, [activeTab]);

	return {
		list,
		loading,
	};
};

export default useGetUserRequestedFaqs;
