import { useSelector } from '@cogoport/front/store';
import { Popover } from '@cogoport/components';
import { IcMHelpInCircle } from '@cogoport/icons-react';
import React, { useState, useEffect } from 'react';
import { isEmpty } from '@cogoport/utils';
import styles from './styles.module.css';
import getDefaultValues from './utils/getDefaultValues';
import Support from './components/Support';
import Modals from './components/Modals';

function Help() {
	const { agent, query } = useSelector(({ profile, general }) => ({
		agent: profile.partner?.entity_manager,
		query: general?.query,
	}));

	const [showPopover, setShowPopover] = useState(false);
	const [modalData, setModalData] = useState(null);

	useEffect(() => {
		if (!isEmpty(query)) {
			setModalData(getDefaultValues({ query }));
		}
	}, [query]);

	return (
		<div className={styles.styled_tooltip_container}>
			<Popover
				animation="shift-away"
				placement="bottom"
				visible={showPopover}
				onClickOutside={() => setShowPopover(false)}
				render={
					<Support
						agent={agent}
						showPopover={showPopover}
						setModalData={setModalData}
						setShowPopover={setShowPopover}
					/>
				}
				theme="light"
				interactive
			>
				<div className={styles.container}>
					<IcMHelpInCircle
						className={styles.help_circle}
						aria-label="help"
						onClick={() => setShowPopover((p) => !p)}
					/>
				</div>
			</Popover>
			<Modals modalData={modalData} setModalData={setModalData} />
		</div>
	);
}

export default Help;
