import { Button, Modal } from '@cogoport/components';
import { useTranslation } from 'next-i18next';
import { useSelector } from '@cogoport/front/store';

import {
	ChipsController,
	DatepickerController,
	InputController,
} from '@/commons/form';
import LoadingSuccess from './LoadingSuccess';
import styles from './styles.module.css';
import useScheduleDemo from './useScheduleDemo';

const CONTROLLER_MAPPING = {
	text: InputController,
	date: DatepickerController,
	chips: ChipsController,
};

function ScheduleDemoForm() {
	const { t } = useTranslation(['common']);

	const {
		profile = {},
		general: { isMobile },
	} = useSelector((state) => state);
	const { demoModalSource = false } = profile;

	const {
		loading,
		demoScheduled,
		control,
		controls,
		errors,
		handleSubmit,
		onSubmit,
		handleNavigate,
		handleClose,
	} = useScheduleDemo();

	if (!demoModalSource) {
		return null;
	}

	return (
		<Modal
			size="md"
			className={styles.modal_container}
			style={{ width: !isMobile && 520 }}
			show={demoModalSource}
			key={demoModalSource}
			onClose={handleClose}
		>
			<Modal.Header title={t('common:schedule_demo')} />

			<Modal.Body>
				{loading || demoScheduled ? (
					<LoadingSuccess />
				) : (
					<>
						<p className={styles.text}>{t('common:heading_1')} </p>

						<div className={styles.form_container}>
							<p className={styles.heading}>{t('common:heading_2')} 👋</p>

							<div style={{ display: 'flex', flexWrap: 'wrap' }}>
								{controls.map((controlItem) => {
									const { type, label, name, ...rest } = controlItem;

									const Element = CONTROLLER_MAPPING[type];

									return (
										<div key={name} className={styles.form_element}>
											<p className={styles.label}>{label}</p>

											<Element control={control} name={name} {...rest} />

											{errors[name] ? (
												<p className={styles.error}>{t('common:error_text')}</p>
											) : null}
										</div>
									);
								})}
							</div>
						</div>
					</>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button
					onClick={handleNavigate}
					themeType={demoScheduled ? 'accent' : 'tertiary'}
					style={{ marginRight: demoScheduled ? 0 : 12 }}
				>
					{demoScheduled
						? `${t('common:button_text_ok')}`
						: `${t('common:button_text_cancel')}`}
				</Button>

				{!demoScheduled ? (
					<Button themeType="accent" onClick={handleSubmit(onSubmit)}>
						{t('common:schedule_demo_1')}
					</Button>
				) : null}
			</Modal.Footer>
		</Modal>
	);
}

export default ScheduleDemoForm;
