import { useEffect } from 'react';
import useRequest from '@/utils/request/useRequest';

const useGetTicketDetails = ({ ticketId }) => {
	const { trigger, data, loading } = useRequest('get', false, 'cogocare', {
		authkey: 'get_tickets_detail',
	})(`/detail`);

	const getTicketDetails = () => {
		try {
			trigger({
				params: {
					ID: Number(ticketId),
				},
			});
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (ticketId) {
			getTicketDetails();
		}
	}, [ticketId]);

	return {
		getTicketDetails,
		detailsLoading: loading,
		ticketData: data || '',
	};
};

export default useGetTicketDetails;
