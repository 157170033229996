const TIME_SLOT_OPTIONS = [
	'10:30 am - 11:00 am',
	'11:00 am - 11:30 am',
	'11:30 am - 12:00 pm',
	'12:00 pm - 12:30 pm',
	'12:30 pm - 01:00 pm',
	'01:00 pm - 01:30 pm',
	'01:30 pm - 02:00 pm',
	'02:00 pm - 02:30 pm',
	'02:30 pm - 03:00 pm',
	'03:00 pm - 03:30 pm',
	'03:30 pm - 04:00 pm',
	'04:00 pm - 04:30 pm',
	'04:30 pm - 05:00 pm',
	'05:00 pm - 05:30 pm',
	'05:30 pm - 06:00 pm',
];

const getDemoTimeSlotOptions = ({ user_availability_date = '' }) => {
	const currentDate = new Date();

	if (!user_availability_date || user_availability_date > currentDate) {
		return TIME_SLOT_OPTIONS.map((timeSlot) => ({
			children: timeSlot,
			key: timeSlot,
		}));
	}

	const currentHour = currentDate.getHours();
	const currentMinutes = currentDate.getMinutes();

	const currentTime24hr = currentHour * 60 + currentMinutes;

	const filteredTimeSlots = TIME_SLOT_OPTIONS.map((timeSlot) => {
		const [startTime] = timeSlot.split(' - ');

		const timeComponents = startTime?.match(/(\d+):(\d+) (\w+)/) || [];

		const startHour = parseInt(timeComponents[1], 10);
		const startMinutes = parseInt(timeComponents[2], 10);
		const period = timeComponents[3];

		const startTime24hr =
			(startHour % 12) * 60 + startMinutes + (period === 'pm' ? 720 : 0);

		return {
			children: timeSlot,
			key: timeSlot,
			disabled: currentTime24hr > startTime24hr,
		};
	});

	return filteredTimeSlots || [];
};

export default getDemoTimeSlotOptions;
