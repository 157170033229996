import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import { useState } from 'react';
import { useForm } from '@/commons/form';
import useRequest from '@/utils/request/useRequest';
import useAnswer from './useAnswer';

const FEEDBACK_MAPPING_ISLIKED = {
	true: 'liked',
	false: 'disliked',
};

const getPayload = ({ id = '', answerId = '', isLiked = '' }) => {
	if (isLiked === 'liked') {
		return {
			id,
			status: 'inactive',
		};
	}

	if (isLiked === 'disliked') {
		return {
			id,
			is_positive: true,
			status: 'active',
		};
	}

	return {
		faq_answer_id: answerId,
		is_positive: true,
		status: 'active',
	};
};

const goToFaq = ({ partnerId = '', question = {} }) => {
	const aElement = document.createElement('a');
	aElement.setAttribute(
		'href',
		`/${partnerId}/help-center/faq/${question?.id}`,
	);
	aElement.setAttribute('target', '_blank');

	const bodyElement = document.querySelector('body');
	bodyElement.appendChild(aElement);

	aElement.click();

	aElement.remove();
};

const useCreateFeedback = ({ question }) => {
	const {
		general: { scope = '' },
		profile: { partner = {} },
	} = useSelector((state) => ({
		general: state.general,
		profile: state.profile,
	}));

	const [show, setShow] = useState(false);
	const [load, setload] = useState(true);
	const [isLiked, setIsLiked] = useState('');

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm();

	const watchQuestionCheckbox = watch('question_checkbox');
	const watchAnswerCheckbox = watch('answer_checkbox');
	const watchRemark = watch('remark');

	const {
		data: answerData,
		loading,
		fetch,
	} = useAnswer({ question, setIsLiked, FEEDBACK_MAPPING_ISLIKED });

	const { id, is_positive } =
		answerData?.answers?.[0]?.faq_feedbacks?.[0] || {};

	const apiName = id ? 'update_faq_feedback' : 'create_faq_feedback';

	const { loading: feedbackLoading, trigger } = useRequest(
		'post',
		false,
		scope,
	)(`/cogo_academy/${apiName}`);

	const onClickLikeButton = async ({ answerId }) => {
		setload(false);
		setIsLiked(isLiked === 'liked' ? '' : 'liked');
		setShow(false);

		const payload = getPayload({ id, answerId, isLiked });

		try {
			await trigger({
				data: payload,
			});

			fetch();
		} catch (error) {
			if (error?.data) {
				Toast.error(getApiErrorString(error?.data));
			}

			setIsLiked(FEEDBACK_MAPPING_ISLIKED[is_positive] || '');
		}
	};

	const onClickRemoveDisLike = async () => {
		setload(false);
		setIsLiked('');

		try {
			await trigger({
				data: {
					id,
					status: 'inactive',
				},
			});

			fetch();
		} catch (error) {
			if (error?.data) {
				Toast.error(getApiErrorString(error?.data));
			}

			setIsLiked(FEEDBACK_MAPPING_ISLIKED[is_positive] || '');
		}
	};

	const goToFAQ = () => {
		const { id: partnerId = '' } = partner || {};

		goToFaq({ partnerId, question });
	};

	const onSubmit = async (values) => {
		setload(false);
		setIsLiked('disliked');

		let remark = values?.remark ? values.remark : '';

		if (values?.answer_checkbox) {
			remark = `Answer not satisfactory. ${remark}`;
		}
		if (values?.question_checkbox) {
			remark = `Question not satisfactory. ${remark}`;
		}

		let payload = {
			faq_answer_id: answerData?.answers[0]?.id,
			is_positive: false,
			remark,
			status: 'active',
			suggested_question_abstract: watchQuestionCheckbox
				? values?.question
				: undefined,
			suggested_answer: watchAnswerCheckbox ? values?.answer : undefined,
		};

		if (is_positive) {
			payload = {
				...payload,
				id,
			};
		}

		try {
			await trigger({
				data: payload,
			});

			setShow(false);
			fetch();
		} catch (error) {
			if (error?.data) {
				Toast.error(getApiErrorString(error?.data));
			}

			setIsLiked(FEEDBACK_MAPPING_ISLIKED[is_positive] || '');
		}
	};

	return {
		handleSubmit,
		control,
		show,
		load,
		loading,
		errors,
		feedbackLoading,
		onClickLikeButton,
		onClickRemoveDisLike,
		onSubmit,
		goToFAQ,
		answerData,
		isLiked,
		setShow,
		setIsLiked,
		watchAnswerCheckbox,
		watchQuestionCheckbox,
		watchRemark,
		is_positive,
		FEEDBACK_MAPPING_ISLIKED,
	};
};

export default useCreateFeedback;
