import styled from '@cogoport/front/styled';
import { Link } from '@/temp/next';

export const A = styled(Link)`
	cursor: pointer;
	height: 24px;
`;

export const ContainerFlex = styled.div`
	display: flex;
	align-items: center;
`;
