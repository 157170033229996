import requestMethods from './request-methods';

const requestConfigs = {
	scope: 'partner',
	storeKey: process.env.NEXT_PUBLIC_STORE_KEY,
	baseURL: process.env.NEXT_PUBLIC_REST_BASE_API_URL,
	auth_token: process.env.NEXT_PUBLIC_AUTH_TOKEN_NAME,
	auth_scope: 'partner',
};

const request = requestMethods(requestConfigs);

export default request;
