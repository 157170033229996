import {
	CheckboxController,
	LocationSelectController,
	DatepickerController,
	InputController,
	MobileNumberSelectController,
	MultiselectController,
	PillsController,
	RadioGroupController,
	SelectController,
	TextAreaController,
	CheckboxGroupController,
	ChipsController,
	DateRangePickerController,
	AsyncSelectController,
} from '@/commons/form';
import AwsController from '@/temp/form/components/Controlled/AwsController';

const mapping = {
	select: SelectController,
	number: InputController,
	text: InputController,
	password: InputController,
	email: InputController,
	datepicker: DatepickerController,
	daterangepicker: DateRangePickerController,
	pills: PillsController,
	textarea: TextAreaController,
	radio: RadioGroupController,
	checkbox: CheckboxController,
	file: AwsController,
	chips: ChipsController,
	'multi-select': MultiselectController,
	'creatable-select': SelectController,
	'mobile-number-select': MobileNumberSelectController,
	'location-select': LocationSelectController,
	'checkbox-group': CheckboxGroupController,
	'async-select': AsyncSelectController,
};

export const getElementController = (type = 'text') => {
	const element = mapping[type] || null;

	if (!element) {
		return function () {
			return null;
		};
	}

	return element;
};
