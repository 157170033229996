export const getThemeType = (theme) => {
	if (theme) {
		if (theme.includes('primary')) {
			return 'primary';
		}

		if (theme.includes('secondary')) {
			return 'secondary';
		}

		if (theme.includes('error')) {
			return 'error';
		}
	}

	return 'primary';
};
