import URL_KEY_MAPPING from '@/constants/urlKeyMapping.json';

const SUPPORTED_URLS = Object.keys(URL_KEY_MAPPING);

const getMetaTagData = async ({ locale, pathname }) => {
	const isValidPath = SUPPORTED_URLS.includes(pathname);

	if (!isValidPath) {
		return {};
	}

	let metaTags = {};

	try {
		metaTags = await import(`../../public/locales/${locale}/metaTags.json`);
	} catch (error) {
		metaTags = await import(`../../public/locales/en-IN/metaTags.json`);
	}

	const newPathname = URL_KEY_MAPPING[pathname];

	const metaData = {
		title: metaTags?.[`${newPathname}_title`],
		description: metaTags?.[`${newPathname}_description`],
		keywords: metaTags?.[`${newPathname}_keywords`],
	};

	return metaData;
};

export default getMetaTagData;
