import { Tooltip } from '@cogoport/components';
import { isEmpty } from '@cogoport/utils';
import { useMemo } from 'react';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import formatDate from '@/temp/utils/formatDate';
import RequestedQuestion from './RequestedQuestion';
import Loader from '../../Loader';
import styles from './styles.module.css';

function RenderText() {
	return <div style={{ color: '#000' }}>Answer is not available</div>;
}

function RequestedQuestionList({
	setQuestion = () => {},
	list = [],
	loading = false,
}) {
	const today = new Date();

	const formatToday = formatDate({
		date: today,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	const yesterday = new Date(today.getTime() - 86400000); // 86400000 is the number of milliseconds in a day

	const formatYesterday = formatDate({
		date: yesterday,
		dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
		formatType: 'date',
	});

	const daysMapping = {
		[formatToday]: 'Today',
		[formatYesterday]: 'Yesterday',
	};

	const filteredObject = useMemo(() => {
		const modifiedFilteredObject = {};

		(list || []).forEach((listItem) => {
			const { created_at, question_abstract, state, is_viewed, id } =
				listItem || {};
			if (!created_at || !question_abstract) return;

			const date = formatDate({
				date: created_at,
				dateFormat: GLOBAL_CONSTANTS.formats.date['dd MMM'],
				formatType: 'date',
			});

			const time = formatDate({
				date: created_at,
				dateFormat: GLOBAL_CONSTANTS.formats.time['hh:mm aaa'],
				formatType: 'time',
			});

			if (!modifiedFilteredObject[date]) modifiedFilteredObject[date] = [];

			modifiedFilteredObject[date].push({
				requestedQuestion: question_abstract,
				time,
				state,
				is_viewed,
				id,
			});
		});

		return modifiedFilteredObject;
	}, [list]);

	const sortedDates = Object.keys(filteredObject || {})
		.map((item) => item)
		.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

	if (loading) return <Loader />;

	if (isEmpty(list)) {
		return (
			<div className={styles.empty_state_wrapper}>
				No Requested Question Found
			</div>
		);
	}

	return (
		<div className={styles.container}>
			{(sortedDates || []).map((ele) => (
				<>
					<div className={styles.header}>{daysMapping[ele] || ele}</div>

					{(filteredObject[ele] || []).map((item) => {
						const { state = '', id = '' } = item || {};

						const selectedQuestion = (list || []).find(
							(element) => element.id === id,
						);

						if (state === 'requested') {
							return (
								<Tooltip
									theme="light-border"
									content={<RenderText />}
									interactive
								>
									<RequestedQuestion
										item={item}
										selectedQuestion={selectedQuestion}
										setQuestion={setQuestion}
									/>
								</Tooltip>
							);
						}

						return (
							<RequestedQuestion
								item={item}
								selectedQuestion={selectedQuestion}
								setQuestion={setQuestion}
							/>
						);
					})}
				</>
			))}
		</div>
	);
}

export default RequestedQuestionList;
