import { IcMBooking, IcMProfile } from '@cogoport/icons-react';

export const ICON_MAPPING = {
	user: {
		icon: <IcMProfile fill="#7278AD" height={30} width={30} />,
		background: '#F3FAFA',
	},
	shipment: {
		icon: <IcMBooking fill="#C26D1A" height={20} width={25} />,
		background: '#FEF3E9',
	},
};
