import UserSentView from './UserSentView';
import styles from './styles.module.css';
import UserReceivedView from './UserReceivedView';

function EachMessage({
	sendFirebaseMessage,
	eachMessage,
	sendMessageLoading,
	t,
}) {
	const { conversation_type = '' } = eachMessage || {};

	return conversation_type === 'received' ? (
		<div className={styles.parent_receive_div_message}>
			<UserReceivedView
				eachMessage={eachMessage}
				sendFirebaseMessage={sendFirebaseMessage}
				sendMessageLoading={sendMessageLoading}
				t={t}
			/>
		</div>
	) : (
		<div className={styles.parent_sent_div_message}>
			<UserSentView eachMessage={eachMessage} />
		</div>
	);
}

export default EachMessage;
