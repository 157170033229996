import { Loader } from '@cogoport/components';
import styles from './styles.module.css';

function LoadingState() {
	return (
		<div className={styles.loading_container}>
			<Loader className={styles.loader} />
		</div>
	);
}

export default LoadingState;
