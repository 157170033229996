import { Placeholder } from '@cogoport/components';
import styles from './styles.module.css';

function FaqListLoader() {
	return (
		<div>
			{[...Array(6).keys()].map((item) => (
				<div key={item}>
					<Placeholder
						width="100%"
						height="40px"
						className={styles.loading_skeleton}
					/>
				</div>
			))}
		</div>
	);
}

export default FaqListLoader;
