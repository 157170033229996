import { Placeholder } from '@cogoport/components';
import { Image } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

function LoadingState() {
	return (
		<Placeholder height="400px" width="90%" margin="40px 0px 20px 20px">
			<Image
				width={40}
				height={60}
				src={GLOBAL_CONSTANTS.image_urls.media_placeholder}
				alt="loading_media.png"
			/>
		</Placeholder>
	);
}

export default LoadingState;
