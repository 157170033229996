import styles from './styles.module.css';

const {
	IcMDoubleTick,
	IcMTick,
	IcMCrossInCircle,
} = require('@cogoport/icons-react');

export const getStatus = ({ item, t }) => {
	if (item?.is_clicked) {
		return (
			<div className={styles.status}>
				{t('common:read_notifications')}
				<IcMDoubleTick style={{ marginLeft: '4px' }} />
			</div>
		);
	}
	if (item?.is_seen) {
		return (
			<div className={styles.status}>
				{t('common:seen_notifications')}{' '}
				<IcMTick style={{ marginLeft: '4px' }} />
			</div>
		);
	}
	return (
		<div className={styles.status}>
			{t('common:un_read_notifications')}
			<IcMCrossInCircle style={{ marginLeft: '4px' }} />
		</div>
	);
};
