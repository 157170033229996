import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import { useSelector } from '@cogoport/front/store';
import useRequest from '@/utils/request/useRequest';

const useClearFaqNotifications = ({
	setShowNotificationContent,
	faqNotificationData = {},
}) => {
	const {
		general: { scope = '' },
	} = useSelector((state) => ({
		general: state.general,
	}));

	const { loading = false, trigger } = useRequest(
		'get',
		false,
		scope,
	)('/cogo_academy/clear_faq_notifications');

	const questionIds = (faqNotificationData || []).map(
		(questionnData) => questionnData?.id,
	);

	const params = {
		faq_question_ids: questionIds,
	};

	const onClickClearNotifications = async () => {
		try {
			await trigger({ params });
			setShowNotificationContent(false);
			Toast.success('Notifications cleared successfully!');
		} catch (err) {
			if (err?.data) {
				Toast.error(getApiErrorString(err?.data));
			}
		}
	};

	return { onClickClearNotifications, loading };
};

export default useClearFaqNotifications;
