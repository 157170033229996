import { Pagination } from '@cogoport/components';
import { useRouter } from '@/temp/next';
import { useTranslation } from 'next-i18next';

import { isEmpty } from '@cogoport/utils';
import NotificationsList from '../NotificationsList';

import styles from './styles.module.css';

function NotificationsTabs({
	data = {},
	loading = false,
	getListCommunications = () => {},
	showPopover,
	setPage = () => {},
}) {
	const { t } = useTranslation(['common']);
	const { list = [], total_count = 0, page = 1, page_limit } = data || {};
	const { push } = useRouter();

	return (
		<div>
			<div className={`${showPopover ? styles.container : styles.main}`}>
				<NotificationsList
					showPopover={showPopover}
					list={list}
					loading={loading}
					getListCommunications={getListCommunications}
				/>
			</div>

			{showPopover && !isEmpty(data?.list) ? (
				<div
					role="presentation"
					className={styles.view}
					onClick={() => push('/notifications')}
				>
					{t('common:view_all_notifications')}
				</div>
			) : (
				<div className={styles.pagination}>
					{!isEmpty(data?.list) && (
						<Pagination
							type="number"
							currentPage={page}
							totalItems={total_count}
							pageSize={page_limit}
							onPageChange={setPage}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default NotificationsTabs;
