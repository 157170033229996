import { Input } from '@cogoport/components';
import React from 'react';
import { Controller } from 'react-hook-form';

function InputController(props) {
	const { name, control, rules, value, itemKey, ...rest } = props;

	return (
		<Controller
			key={rest.id}
			control={control}
			name={name}
			defaultValue={value}
			rules={rules}
			render={({ field: { onChange, onBlur, value: newValue } }) => (
				<Input
					{...rest}
					key={itemKey}
					value={newValue}
					onBlur={onBlur}
					onChange={(e) => {
						if (typeof rest?.onChange === 'function') {
							rest?.onChange(e, name);
						}
						onChange(e);
					}}
				/>
			)}
		/>
	);
}

export default InputController;
