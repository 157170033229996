import { Placeholder } from '@cogoport/components';

import styles from './styles.module.css';

function Loader() {
	return (
		<div className={styles.container}>
			{[...Array(8).keys()].map((key) => (
				<Placeholder key={key} width="100%" height="25px" margin="16px 0" />
			))}
		</div>
	);
}

export default Loader;
