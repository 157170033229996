import { isEmpty } from '@cogoport/utils';

import { useState, useEffect, useRef, useCallback } from 'react';

const PAGE_LIMIT = 10;
const TIME_TO_SCROLL = 100;

const useGetMessages = ({
	supabaseClient,
	scrollToBottom,
	setMessageStatus,
	authUserId,
}) => {
	const [messagesState, setMessagesState] = useState({
		messagesHash: {},
		lastDocumentTimeStamp: null,
		islastPage: true,
	});

	const [loading, setLoading] = useState(false);

	const fetchMessagesRef = useRef(null);

	const getInitialMessagesData = useCallback(
		async ({ abortMessagesControl = {} }) => {
			const { data: roomData, error: roomError } = await supabaseClient
				.from('customer_chats')
				.select()
				.eq('auth_user_id', authUserId);

			if (roomError) {
				return;
			}

			const roomId = roomData[0]?.id;
			const supabaseQuery = supabaseClient
				.from('customer_messages')
				.select()
				.eq('customer_chat_id', roomId);

			const { data, error } = await supabaseQuery
				.limit(PAGE_LIMIT)
				.order('created_at', { ascending: false })
				.abortSignal(abortMessagesControl?.signal);

			if (error) {
				return;
			}
			const lastDocumentTimeStamp = data[data.length - 1]?.created_at;
			const newRecentMessageTimeStamp = data[0]?.created_at;

			const islastPage = data.length < PAGE_LIMIT;
			const formattedData = data?.reduce(
				(acc, itm) => ({
					...acc,
					[itm?.created_at]: itm,
				}),
				{},
			);
			setMessagesState((p) => ({
				...p,
				messagesHash: formattedData,
				lastDocumentTimeStamp,
				recentMessageTimeStamp: newRecentMessageTimeStamp,
				islastPage,
			}));
			setTimeout(scrollToBottom, TIME_TO_SCROLL);
		},
		[],
	);

	const { messagesHash = {}, islastPage: isLastPageOfMessages } =
		messagesState || {};

	const getPrevData = async () => {
		if (loading || isLastPageOfMessages) {
			return;
		}
		const { data: roomData, error: roomError } = await supabaseClient
			.from('customer_chats')
			.select()
			.eq('auth_user_id', authUserId);

		if (roomError) {
			return;
		}
		const roomId = roomData[0]?.id;

		const supabaseQuery = supabaseClient.from('customer_messages').select();

		setLoading(true);

		const updatedSupabaseQuery = supabaseQuery.eq('customer_chat_id', roomId);

		const { data: prevMessages = [], error } = await updatedSupabaseQuery
			.lte('created_at', messagesState?.lastDocumentTimeStamp)
			.limit(PAGE_LIMIT)
			.order('created_at', { ascending: false });

		if (error) {
			setLoading(false);
			return;
		}

		const lastDocumentTimeStamp =
			prevMessages[(prevMessages?.length || 0) - 1]?.created_at;

		const islastPage = prevMessages?.length < PAGE_LIMIT;
		let prevMessageData = {};
		prevMessages.forEach((eachMessage) => {
			const timeStamp = eachMessage?.created_at;
			prevMessageData = { ...prevMessageData, [timeStamp]: eachMessage };
		});

		setMessagesState((p) => ({
			...p,
			messagesHash: { ...(p.messagesHash || {}), ...prevMessageData },
			lastDocumentTimeStamp,
			islastPage,
		}));
		setLoading(false);
	};

	const fetchRealtimeMessages = useCallback(async () => {
		try {
			if (
				!isEmpty(fetchMessagesRef.current) &&
				fetchMessagesRef.current.state === 'joined'
			) {
				return;
			}

			const { data: roomData, error: roomError } = await supabaseClient
				.from('customer_chats')
				.select()
				.eq('auth_user_id', authUserId);

			if (roomError) {
				return;
			}
			const roomId = roomData[0]?.id;

			fetchMessagesRef.current = supabaseClient
				.channel('partnerChat')
				.on(
					'postgres_changes',
					{
						event: '*',
						schema: 'public',
						table: 'customer_messages',
						filter: `customer_chat_id=eq.${roomId}`,
					},
					(payload) => {
						const newData = payload?.new || {};

						setMessagesState((prev) => {
							if (['INSERT', 'UPDATE', 'UPSERT'].includes(payload?.eventType)) {
								let newMessagesData = { ...(prev?.messagesHash || {}) };

								newMessagesData = {
									...newMessagesData,
									[newData?.created_at]: newData,
								};

								return {
									...prev,
									messagesHash: newMessagesData,
									lastDocumentTimeStamp: newData?.created_at || new Date(),
								};
							}
							return prev;
						});
						setTimeout(scrollToBottom, TIME_TO_SCROLL);
					},
				)
				.subscribe((status) => {
					setMessageStatus(status);
				});
		} catch (err) {
			console.error('CHAT_MESSAGE_FETCH_ERROR', err);
		}
	}, [supabaseClient]);

	const handleScroll = (e) => {
		const bottom = e.target.scrollTop === 0;
		if (bottom) {
			getPrevData();
		}
	};

	useEffect(() => {
		const abortMessagesControl = new AbortController();
		setMessagesState({
			messagesHash: {},
		});
		getInitialMessagesData({ abortMessagesControl });

		fetchRealtimeMessages();

		return () => {
			if (fetchMessagesRef.current) {
				supabaseClient.removeChannel(fetchMessagesRef.current);
			}
			abortMessagesControl?.abort?.();
		};
	}, [fetchRealtimeMessages, getInitialMessagesData, supabaseClient]);

	const sortedMessageData = Object.values(messagesHash || {}).sort(
		(a, b) =>
			new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
	);

	return {
		sortedMessageData,
		getPrevData,
		loading,
		isLastPageOfMessages,
		handleScroll,
		fetchMessagesRef,
	};
};
export default useGetMessages;
