import { getCookie } from '@/temp/cogo-cookies';
import getCountryDetails from '@/temp/utils/getCountryDetails';
import GLOBAL_CONSTANTS from '../globals.json';
import IN from './IN';
import VN from './VN';
import SG from './SG';
import TH from './TH';
import ID from './ID';
import CN from './CN';

const { country_entity_ids } = GLOBAL_CONSTANTS;

export const ENTITY_MAPPING = {
	[country_entity_ids.IN]: IN,
	[country_entity_ids.VN]: VN,
	[country_entity_ids.SG]: SG,
	[country_entity_ids.TH]: TH,
	[country_entity_ids.ID]: ID,
	[country_entity_ids.CN]: CN,
};

const COUNTRY_ID_MAPPING = {
	IN,
	VN,
	SG,
	TH,
	ID,
	CN,
};

export const getGeoConstants = () => {
	const parent_entity_id = getCookie('parent_entity_id');

	return ENTITY_MAPPING[
		parent_entity_id in ENTITY_MAPPING
			? parent_entity_id
			: country_entity_ids.IN
	];
};

export const getCountryConstants = ({
	country_id,
	country_code,
	isDefaultData = true,
}) => {
	const geo = getGeoConstants();

	const countryData = getCountryDetails({ country_id, country_code });

	const { country_code: countryCode } = countryData || {};

	const isCountryCodeValid = countryCode in COUNTRY_ID_MAPPING;

	if (isDefaultData) {
		return COUNTRY_ID_MAPPING[
			isCountryCodeValid ? countryCode : geo.country.code
		];
	}

	return isCountryCodeValid ? COUNTRY_ID_MAPPING[countryCode] : {};
};
