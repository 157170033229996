import { useSelector, useDispatch } from '@cogoport/front/store';
import { isEmpty } from '@cogoport/utils';
import { useEffect } from 'react';
import useRequest from '@/utils/request/useRequest';
import { setProfileStoreState } from '@/utils/stores';

const useGetActiveSubscription = () => {
	const dispatch = useDispatch();
	const { profile } = useSelector((state) => state);
	const { partner = {} } = profile || {};
	const { id = '', subscription = {} } = partner || {};

	const { trigger } = useRequest(
		'get',
		false,
	)('/saas_subscriptions_v2/get_active_subscription');

	const getSubscriptionInfo = async () => {
		try {
			const response = await trigger({});

			dispatch(
				setProfileStoreState({
					...profile,
					partner: {
						...profile.partner,
						subscription: response?.data,
					},
				}),
			);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (isEmpty(subscription) && id) {
			getSubscriptionInfo();
		}
	}, [id]);

	return { getSubscriptionInfo };
};

export default useGetActiveSubscription;
