import RightPanel from './RightPanel';
import { Container, Header, Main, Panel, Icon, SinglePanel } from './styles';
import LeftPanel from './LeftPanel';

function PanelsLayout({
	activeComponentKey = '',
	headerComponent,
	leftPanel = {},
	rightPanel = {},
	cogoportIcon,
}) {
	const isPasswordComponent = ['setPassword', 'forgotPassword'].includes(
		activeComponentKey,
	);
	const isbeforeRegistration = [
		'verification',
		'login',
		'registration',
	].includes(activeComponentKey);
	const isTnCpage = ['termsAndConditions', 'accountCreated'].includes(
		activeComponentKey,
	);
	return (
		<Container className="onboarding-layout">
			{headerComponent && (
				<Header className="onboarding-layout__header">{headerComponent}</Header>
			)}

			<Main
				className="onboarding-layout__main"
				isHeaderComponentPresent={!!headerComponent}
				isbeforeRegistration={isbeforeRegistration}
			>
				{isPasswordComponent || isTnCpage ? (
					<SinglePanel className="onboarding-password_layout__panel">
						{isPasswordComponent ? (
							<Icon className="left-panel__icon left-panel__icon--cogoport">
								{cogoportIcon}
							</Icon>
						) : null}
						<RightPanel
							activeComponentKey={activeComponentKey}
							isbeforeRegistration={isbeforeRegistration}
							panel={rightPanel}
							isTnCpage={isTnCpage}
							headerComponent={headerComponent}
						/>
					</SinglePanel>
				) : (
					<>
						<Panel
							className="onboarding-layout__panel onboarding-layout__panel--left"
							isbeforeRegistration={isbeforeRegistration}
							isTnCpage={isTnCpage}
						>
							<LeftPanel
								cogoportIcon={cogoportIcon}
								activeComponentKey={activeComponentKey}
								panel={leftPanel}
								headerComponent={headerComponent}
							/>
						</Panel>
						<Panel
							className="onboarding-layout__panel onboarding-layout__panel--right"
							isbeforeRegistration={isbeforeRegistration}
							isTnCpage={isTnCpage}
						>
							<RightPanel
								activeComponentKey={activeComponentKey}
								isbeforeRegistration={isbeforeRegistration}
								panel={rightPanel}
								isTnCpage={isTnCpage}
								headerComponent={headerComponent}
							/>
						</Panel>
					</>
				)}
			</Main>
		</Container>
	);
}

export default PanelsLayout;
