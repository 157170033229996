/* eslint-disable global-require */
const getFirestoreCustomToken = async () => {
	if (process.env.FIREBASE_PRIVATE_KEY_ID) {
		const admin = require('firebase-admin');

		const serviceAccount = {
			type: 'service_account',
			project_id: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
			private_key_id: process.env.FIREBASE_PRIVATE_KEY_ID,
			private_key: process.env.FIREBASE_PRIVATE_KEY
				? process.env.FIREBASE_PRIVATE_KEY.replace(/\\n/gm, '\n')
				: undefined,
			client_email: process.env.FIREBASE_CLIENT_EMAIL,
			client_id: process.env.FIREBASE_CLIENT_ID,
			auth_uri: process.env.FIREBASE_AUTH_URI,
			token_uri: process.env.FIREBASE_TOKEN_URI,
			auth_provider_x509_cert_url:
				process.env.FIREBASE_AUTH_PROVIDER_X509_CERT_URL,
			client_x509_cert_url: process.env.FIREBASE_CLIENT_X509_CERT_URL,
		};
		const config = {
			credential: admin.credential.cert(serviceAccount),
			databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
		};
		const appExists = admin.apps.some((val) => val.name === 'secondary');

		const secondary_app = appExists
			? admin.app('secondary')
			: admin.initializeApp(config, 'secondary');

		const uid = process.env.FIRESTORE_AUTH_UID;
		const adminAuth = admin.auth(secondary_app);
		let firestoreCustomToken = 'token';

		await adminAuth
			.createCustomToken(uid)
			.then((customToken) => {
				firestoreCustomToken = customToken;
			})
			.catch((error) => {
				console.log(error);
			});
		return { firestoreCustomToken };
	}
	return { firestoreCustomToken: '' };
};

export default getFirestoreCustomToken;
