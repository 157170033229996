import { isEmpty } from '@cogoport/utils';
import React from 'react';

import useQuestionList from '../../useQuestionList';

import styles from './styles.module.css';

function RelatedQuestion({
	query_name = '',
	question_abstract = '',
	setQuestion,
	question,
}) {
	const { list, loading = false } = useQuestionList({ query_name, question });

	const uniqueQuestions =
		(list || []).filter(
			(ele) => ele?.question_abstract !== question_abstract,
		) || [];

	if (loading || isEmpty(uniqueQuestions)) {
		return null;
	}

	return (
		<div style={{ paddingTop: '20px' }}>
			<span className={styles.related_question}>Related Questions</span>

			{(uniqueQuestions || []).map((questionObj) => (
				<div
					role="presentation"
					key={questionObj?.question_abstract}
					className={styles.related_question_clickable}
					onClick={() => setQuestion(questionObj)}
				>
					Q. {questionObj?.question_abstract}
				</div>
			))}
		</div>
	);
}

export default RelatedQuestion;
