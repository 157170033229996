import React from 'react';
import { DemoVideoPage } from '@/components/DemoVideo';

function Demo({ setIncreaseModalSize, increaseModalSize }) {
	return (
		<DemoVideoPage
			viewType="helpCentre"
			setShowVideo={setIncreaseModalSize}
			showVideo={increaseModalSize}
		/>
	);
}

export default Demo;
