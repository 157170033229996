import GLOBAL_CONSTANTS from '@/constants/globals.json';

const DEFAULT_PATHS = {
	AUTHENTICATED_CP: '/discover-rates',
	AUTHENTICATED_LSP: '/lsp-dashboard',
};

const getDefaultPath = ({ activeChannelPartner }) => {
	const { twin_importer_exporter_id, country } = activeChannelPartner || {};

	const { country_code } = country || {};

	if (!(country_code in GLOBAL_CONSTANTS.country_specific_data)) {
		const authenticatedKey = twin_importer_exporter_id
			? 'AUTHENTICATED_CP'
			: 'AUTHENTICATED_LSP';

		return DEFAULT_PATHS[authenticatedKey];
	}

	const defaultPathsData =
		GLOBAL_CONSTANTS.country_specific_data[country_code]?.default_paths || {};

	const defaultUrl = twin_importer_exporter_id
		? defaultPathsData?.cp
		: defaultPathsData?.lsp;

	return defaultUrl || null;
};

export default getDefaultPath;
