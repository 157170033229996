import { cl } from '@cogoport/components';
import React from 'react';
import { getCookie, setCookie } from '@cogoport/utils';
import { useRouter } from '@/temp/next';
import LANGUAGE_MAPPING from '@/constants/languageMapping';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import { LANGUAGE_WISE_FLAGS } from '@/constants/languageWiseFlags';
import styles from './styles.module.css';

function Languages() {
	const router = useRouter();

	const { locale } = router;

	let countryCode = '';

	if (typeof document !== 'undefined') {
		countryCode = getCookie('location');
	}

	let localesToHide = GLOBAL_CONSTANTS.default_hidden_locales;

	if (countryCode in GLOBAL_CONSTANTS.country_specific_data) {
		localesToHide =
			GLOBAL_CONSTANTS.country_specific_data[countryCode]?.hidden_locales;
	}

	const filteredList = Object.values(LANGUAGE_MAPPING).filter(
		(lang) => !(localesToHide || []).includes(lang.key),
	);

	const handleRedirect = ({ loc }) => {
		setCookie('locale', loc);
		router.reload();
	};

	return (
		<div className={`${styles.main}`}>
			{Object.values(filteredList).map((lang) => {
				const { displayLanguage, key, label } = lang || {};
				return (
					<div
						role="presentation"
						onClick={() => handleRedirect({ loc: key })}
						className={cl`${styles.item} ${
							locale === key ? styles.active : ''
						} ${locale === key ? styles.active_fixed : styles.item}`}
					>
						{LANGUAGE_WISE_FLAGS[label]}
						<div className={styles.country_content}>{displayLanguage}</div>
					</div>
				);
			})}
		</div>
	);
}

export default Languages;
