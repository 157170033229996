import { Toast } from '@cogoport/components';
import getApiErrorString from '@cogoport/front/utils';
import useRequest from '@/utils/request/useRequest';
import { useSelector } from '@cogoport/front/store';

const useCreateFaqQuestion = ({
	searchQuestion = '',
	setShow,
	setQuestionCreated,
	answer,
}) => {
	const {
		general: { scope = '' },
	} = useSelector((state) => ({
		general: state.general,
	}));

	const { loading, trigger } = useRequest(
		'post',
		false,
		scope,
	)('/cogo_academy/request_faq_question');

	const createFaqQuestion = async () => {
		if (!searchQuestion) {
			Toast('Question cannot be Empty');
			return;
		}

		try {
			const payload = {
				question_abstract: searchQuestion,
				answer,
			};

			await trigger({
				data: payload,
			});

			setQuestionCreated(true);
			setShow(false);
		} catch (e) {
			if (e.response?.data) {
				Toast.error(getApiErrorString(e.response?.data));
			}
		}
	};

	return {
		createFaqQuestion,
		createQuestionloading: loading,
	};
};

export default useCreateFaqQuestion;
