import { useTranslation } from 'next-i18next';
import { useSelector } from '@cogoport/front/store';

const translationKey = 'common:components_header_tickets_create';

const getShipmentControls = ({
	watchRequestType = '',
	twinImporterExporterId = '',
	t = () => {},
	setValue = () => {},
}) => {
	if (watchRequestType !== 'shipment') {
		return [];
	}

	return [
		{
			name: 'serial_id',
			value: '',
			label: t(`${translationKey}_select_shipment_id`),
			isClearable: true,
			type: 'async-select',
			asyncKey: 'list_shipment',
			initialCall: true,
			placeholder: t(`${translationKey}_select_shipment_id_placeholder`),
			rules: {
				required: true,
			},
			params: {
				filters: {
					importer_exporter_id: twinImporterExporterId,
					state: [
						'shipment_received',
						'confirmed_by_importer_exporter',
						'in_progress',
					],
				},
				page_limit: 20,
			},
			handleChange: (val) => {
				setValue('service_type', val?.shipment_type);
				setValue('trade_type', val?.trade_type);
			},
		},
		{
			label: t(`${translationKey}_select_service_type`),
			name: 'service_type',
			type: 'select',
			placeholder: t(`${translationKey}_select_service_type_placeholder`),
			disabled: true,
			options: [
				{ value: 'fcl_freight', label: 'FCL' },
				{ value: 'lcl_freight', label: 'LCL' },
				{ value: 'air_freight', label: 'AIR' },
				{ value: 'ftl_freight', label: 'FTL' },
				{ value: 'ltl_freight', label: 'LTL' },
				{ value: 'fcl_cfs', label: 'Fcl Cfs' },
				{ value: 'haulage_freight', label: 'Rail Haulage' },
				{ value: 'fcl_customs', label: 'FCL Customs' },
				{ value: 'lcl_customs', label: 'LCL Customs' },
				{ value: 'air_customs', label: 'AIR Customs' },
				{ value: 'fcl_freight_local', label: 'FCL Freight Local' },
				{ value: 'lcl_freight_local', label: 'Lcl Freight Local' },
				{ value: 'air_freight_local', label: 'Air Freight Local' },
				{ value: 'domestic_air_freight', label: 'Domestic Air Freight' },
				{ value: 'rail_domestic_freight', label: 'Rail Domestic Freight' },
				{ value: 'trailer_freight', label: 'Container Transportation' },
			],
		},
		{
			label: t(`${translationKey}_select_trade_type`),
			name: 'trade_type',
			type: 'select',
			placeholder: t(`${translationKey}_select_trade_type_placeholder`),
			disabled: true,
			options: [
				{
					label: 'Import',
					value: 'import',
				},
				{
					label: 'Export',
					value: 'export',
				},
			],
		},
	];
};

const useRaiseTicketControls = ({
	watchRequestType = '',
	setValue = () => {},
}) => {
	const { t } = useTranslation(['common']);

	const {
		partner: { twin_importer_exporter_id: twinImporterExporterId = '' },
	} = useSelector(({ profile }) => profile);

	const shipmentControls = getShipmentControls({
		watchRequestType,
		twinImporterExporterId,
		setValue,
		t,
	});

	return [
		{
			label: t(`${translationKey}_select_issue_type`),
			name: 'request_type',
			type: 'select',
			isClearable: true,
			placeholder: t(`${translationKey}_select_issue_type_placeholder`),
			rules: {
				required: true,
			},
			options: [
				{
					label: 'Shipment',
					value: 'shipment',
				},
				{
					label: 'Finance',
					value: 'finance',
				},
				{
					label: 'Platform Issue',
					value: 'platform_issue',
				},
				{
					label: 'Subscription',
					value: 'subscription',
				},
			],
			handleChange: () => {
				setValue('service_type', '');
				setValue('trade_type', '');
			},
		},
		...shipmentControls,
	];
};

export default useRaiseTicketControls;
