import { IcMCross, IcMListView } from '@cogoport/icons-react';
import { isEmpty, startCase } from '@cogoport/utils';
import { useState, useRef } from 'react';

import { getElementMapping, getFooterItems } from './getfooterHelpers';
import styles from './styles.module.css';

const EMPTY_FUNC = () => {};

const scrollIntoList = (listRef) => {
	if (listRef.current) {
		listRef.current?.scrollIntoView({
			behavior: 'smooth',
			top: 50,
		});
	}
};

function FooterItems({ response, sendFirebaseMessage, sendMessageLoading }) {
	const [showFooter, setShowFooter] = useState(false);
	const listRef = useRef(null);

	const { list = [], type = '' } = getFooterItems(response);
	const elementMapping = getElementMapping({
		sendFirebaseMessage,
		sendMessageLoading,
	});

	const getItemFunc = elementMapping[type] || EMPTY_FUNC;

	if (isEmpty(list)) {
		return null;
	}

	const toggleList = () => {
		setShowFooter((p) => {
			if (p) {
				return false;
			}
			setTimeout(() => scrollIntoList(listRef), 400);
			return true;
		});
	};

	return (
		<>
			{type !== 'buttons' && (
				<div
					role="presentation"
					className={styles.list_button}
					onClick={toggleList}
				>
					{showFooter ? (
						<span className={styles.btn_container}>
							<IcMCross className={styles.btn_icon} />
							Hide
						</span>
					) : (
						<span className={styles.btn_container}>
							<IcMListView className={styles.btn_icon} />
							{startCase(type)}
						</span>
					)}
				</div>
			)}
			{(showFooter || type === 'buttons') && (
				<div className={styles.list_container} ref={listRef}>
					{(list || []).map(getItemFunc)}
				</div>
			)}
		</>
	);
}
export default FooterItems;
