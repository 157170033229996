import { createClient } from '@supabase/supabase-js';
import { useMemo } from 'react';
import { SUPABASE_CONFIG } from '../constants';

function useSupabase() {
	const supabaseClient = useMemo(() => {
		try {
			return createClient(SUPABASE_CONFIG.url, SUPABASE_CONFIG.key);
		} catch (error) {
			console.error('SUPABASE_CLIENT_INITIALIZE_ERROR', error);
			return {};
		}
	}, []);

	return { supabaseClient };
}

export default useSupabase;
