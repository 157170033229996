import getNavigation from '@/commons/configurations/getNavigation';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

const getNavigationList = ({
	isKycVerified,
	account_types,
	twin_service_provider_id,
	country_code,
	t = () => {},
}) => {
	const { exludeCountries } =
		GLOBAL_CONSTANTS.service_supported_countries.navigation.dashboard;
	let showCombinedDashboard = false;
	if (
		account_types.includes('service_provider') &&
		account_types.includes('importer_exporter') &&
		!(exludeCountries || []).includes(country_code)
	) {
		showCombinedDashboard = true;
	}
	const MENU_LIST = getNavigation({ t });

	return MENU_LIST.filter((menuItem) => {
		const { account_type, show, key } = menuItem;

		const { notKycVerified: showInNotKycVerified = false } = show || {};

		if (!isKycVerified && !showInNotKycVerified) {
			return false;
		}

		if (!isKycVerified && twin_service_provider_id) {
			return false;
		}
		if (['lsp_dashboard', 'dashboard'].includes(key) && showCombinedDashboard) {
			return false;
		}
		if (!showCombinedDashboard && key === 'combineDashboards') {
			return false;
		}

		return account_type === undefined || account_types.includes(account_type);
	});
};

export default getNavigationList;
