import { Select, MultiSelect } from '@cogoport/components';
import React from 'react';
import { Controller } from 'react-hook-form';

function SelectController(props) {
	const { name, control, rules, value, multiple = false, ...rest } = props;
	const Element = multiple ? MultiSelect : Select;
	return (
		<Controller
			key={rest.id}
			control={control}
			name={name}
			rules={rules}
			defaultValue={value}
			render={({ field: { onChange, onBlur, value: newValue } }) => (
				<Element
					{...rest}
					key={`${rest?.id}_${newValue}`}
					id={name}
					onChange={(val, obj) => {
						if (typeof rest?.onChange === 'function') {
							rest?.onChange(val, obj, name);
						}
						onChange(val, obj);
						if (rest.handleChange) {
							rest.handleChange(obj, name);
						}
					}}
					value={newValue}
					onBlur={onBlur}
					data-test-value={newValue}
				/>
			)}
		/>
	);
}
export default SelectController;
