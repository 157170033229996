const syncUserRooms = async ({
	platformChatRoomId = '',
	supabaseClient,
	preferredMobileNo,
}) => {
	const whatsappQuery = await supabaseClient
		.from('customer_chats')
		.select()
		.eq('channel_type', 'whatsapp')
		.eq('mobile_no', preferredMobileNo);

	const getWhatsappUser = whatsappQuery?.data;

	if (getWhatsappUser.size > 0) {
		const whatsappRoomId = getWhatsappUser[0]?.id;

		const user_channel_ids = {
			whatsapp_id: whatsappRoomId,
			platform_chat_id: platformChatRoomId,
		};
		await supabaseClient
			.from('customer_chats')
			.update({ user_channel_ids })
			.eq('id', whatsappRoomId);

		await supabaseClient
			.from('customer_chats')
			.update({ user_channel_ids })
			.eq('id', platformChatRoomId);
	}
};

export default syncUserRooms;
