import { MESSAGE_MAPPING, SLASH_MATCH_REGEX } from '../../../../constants';
import styles from './styles.module.css';
import FileViewMapping from './fileViewMapping';
import renderText from '../../../../helpers/templateTextFormatting';

function ShowMessage({ message, messageType = '' }) {
	let html = message.replace(SLASH_MATCH_REGEX, '<br>') || '';

	if (messageType !== 'template') {
		html = renderText(html);
	}

	return (
		<div
			className={styles.messages_div}
			dangerouslySetInnerHTML={{ __html: html }}
		/>
	);
}
function MessageBody({ response = {}, message_type = 'text' }) {
	const { message = '', media_url = '' } = response;

	const fileExtension = media_url?.split('.').pop();

	const isMessageTypeMedia =
		MESSAGE_MAPPING.media.includes(message_type) || false;

	if (isMessageTypeMedia) {
		return (
			<>
				<FileViewMapping
					mediaUrl={media_url}
					extension={fileExtension}
					messageType={message_type}
				/>
				<ShowMessage message={message} messageType={message_type} />
			</>
		);
	}

	return <ShowMessage message={message} messageType={message_type} />;
}

export default MessageBody;
