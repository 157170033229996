import { useSelector } from '@cogoport/front/store';
import { isEmpty } from '@cogoport/utils';
import { dynamic, useRouter } from '@/temp/next';
import Notifications from '@/components/Notifications/components';
import Help from './Help';
import CompanyDetails from './Company';
import Cogopoints from './Cogopoints';
import RedirectLink from '../RedirectLink';
import LanguageSelect from '../LanguageSelect';
import { Container, Right } from './styles';

const UserDetails = dynamic(() => import('../UserDetails'), {
	ssr: false,
});

const Logout = dynamic(() => import('../UserDetails/Menu/Logout'), {
	ssr: false,
});

function Header({
	children,
	showRedirectLink,
	redirectLink,
	showSupportHelp,
	showUserDetails,
	hideLogo,
	showLogooutMobile,
}) {
	const { partner = {}, isMobile } = useSelector(({ profile, general }) => ({
		partner: profile?.partner,
		inVideoCall: profile?.is_in_video_call,
		videoCallRecipientData: profile?.video_call_recipient_data,
		videoCallId: profile.video_call_id,
		isMobile: general?.isMobile,
	}));
	const router = useRouter();
	const { route } = router;
	const registrationPage = route.includes('/get-started');

	let showCogoPoints = true;

	if (
		partner.twin_service_provider_id &&
		partner.twin_importer_exporter_id === null
	) {
		showCogoPoints = false;
	}

	const renderMobileLogout = () => (showLogooutMobile ? <Logout /> : null);

	return (
		<Container>
			{!hideLogo && <CompanyDetails />}

			{children}

			<Right>
				{isMobile ? (
					renderMobileLogout()
				) : (
					<Right>
						{/* {showDemo && <DemoVideos />} */}
						{showSupportHelp && !isEmpty(partner) && <Help />}
						<LanguageSelect />
						{showCogoPoints && !isEmpty(partner) && <Cogopoints />}
						{showRedirectLink && <RedirectLink {...redirectLink} />}
						{showUserDetails && <UserDetails />}
						{!registrationPage ? <Notifications showPopover /> : null}
					</Right>
				)}
			</Right>
		</Container>
	);
}

export default Header;
