import { IcMRefresh } from '@cogoport/icons-react';
import React, { useRef } from 'react';
import GLOBAL_CONSTANTS from '@/constants/globals.json';
import useSupabase from '@/components/BotAuthorization/hooks/useSupabase';
import FooterChat from '../FooterChat';
import EachMessage from './EachMessage';
import useGetMessages from '../../hooks/useGetMessages';
import styles from './styles.module.css';
import useSendFirebaseMessage from '../../hooks/useSendFirebaseMessage';

function ChatBotBody({
	t,
	sendMessage,
	sendMessageLoading,
	messageStatus,
	setMessageStatus,
	chatbotUserRoom,
	authUserId,
}) {
	const messagesEnd = useRef(null);
	const messagesRef = useRef(null);
	const { supabaseClient } = useSupabase();

	const scrollToBottom = () => {
		messagesEnd.current?.scrollIntoView({ behavior: 'smooth' });
	};

	const toggleHeight = ({ isFileDivPresent = false }) => {
		if (messagesRef.current) {
			messagesRef.current.style.height = isFileDivPresent
				? 'calc(100% - 147px)'
				: 'calc(100% - 107px)';
		}
	};
	const {
		sortedMessageData = [],
		getPrevData,
		loading,
		isLastPageOfMessages,
		handleScroll,
		fetchMessagesRef,
	} = useGetMessages({
		supabaseClient,
		roomId: chatbotUserRoom?.id,
		scrollToBottom,
		setMessageStatus,
		authUserId,
	});

	const { sendFirebaseMessage } = useSendFirebaseMessage({
		supabaseClient,
		roomId: chatbotUserRoom?.id,
		sendMessageLoading,
		sendMessage,
		messageStatus,
		chatbotUserRoom,
		fetchMessagesRef,
		authUserId,
	});

	return (
		<>
			<div
				className={styles.bot_body}
				ref={messagesRef}
				onScroll={handleScroll}
			>
				{!isLastPageOfMessages && (
					<div className={styles.loader}>
						{loading ? (
							<img src={GLOBAL_CONSTANTS.image_urls.loader} alt="load" />
						) : (
							<IcMRefresh cursor="pointer" onClick={getPrevData} />
						)}
					</div>
				)}
				{sortedMessageData.map((eachMessage) => (
					<EachMessage
						key={eachMessage?.created_at}
						eachMessage={eachMessage}
						sendFirebaseMessage={sendFirebaseMessage}
						sendMessageLoading={sendMessageLoading}
						t={t}
					/>
				))}
				<div ref={messagesEnd} />
			</div>
			<FooterChat
				t={t}
				sendMessageLoading={sendMessageLoading}
				sendFirebaseMessage={sendFirebaseMessage}
				toggleHeight={toggleHeight}
			/>
		</>
	);
}
export default ChatBotBody;
