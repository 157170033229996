import { IcCCogoCoin } from '@cogoport/icons-react';
import { useTranslation } from 'next-i18next';
// import useGetCogopointsStats from '@/commons/hooks/useGetCogopointsStats';
import { useState } from 'react';
import { Button, Modal } from '@cogoport/components';
import { Image } from '@/temp/next';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

import {
	Container,
	A,
	ModalContainer,
	IconContainer,
	Text,
	Subtext,
} from './styles';

function Cogopoints() {
	const { t } = useTranslation(['common']);

	const [showCogoPointsModal, setShowCogoPointsModal] = useState(false);

	// const { stats } = useGetCogopointsStats();
	// const { earned = 0 } = stats || {};
	return (
		<Container>
			<A onClick={() => setShowCogoPointsModal(true)}>
				<IcCCogoCoin width={20} height={20} />
				{/* <Heading>{t('common:components_header_cogopoints')}</Heading> */}
				{/* <Points>{earned.toLocaleString('en-IN')}</Points> */}
			</A>

			{showCogoPointsModal ? (
				<Modal
					show={showCogoPointsModal}
					onClose={() => setShowCogoPointsModal(false)}
				>
					<Modal.Header
						title={t('common:components_header_cogopoints_modal_title')}
					/>

					<Modal.Body>
						<ModalContainer>
							<IconContainer>
								<Image
									src={GLOBAL_CONSTANTS.image_urls.coming_soon_icon}
									width={400}
									height={102}
								/>
							</IconContainer>

							<Text>
								{t('common:components_header_cogopoints_modal_heading')}
							</Text>

							<Subtext>
								{t('common:components_header_cogopoints_modal_sub_heading')}
							</Subtext>
						</ModalContainer>
					</Modal.Body>

					<Modal.Footer>
						<Button
							onClick={() => setShowCogoPointsModal(false)}
							themeType="accent"
						>
							{t('common:components_header_cogopoints_modal_button_text')}
						</Button>
					</Modal.Footer>
				</Modal>
			) : null}
		</Container>
	);
}

export default Cogopoints;
