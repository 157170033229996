import { Chips } from '@cogoport/components';
import React from 'react';
import { Controller } from 'react-hook-form';

function ChipsController(props) {
	const { name, control, options, rules, multiple, value, ...rest } = props;

	return (
		<Controller
			key={rest.id}
			control={control}
			name={name}
			defaultValue={value}
			rules={rules}
			render={({ field: { onChange, onBlur, value: newValue } }) => (
				<Chips
					{...rest}
					key={rest.id}
					items={options?.map((option) => ({
						...option,
						key: option.key || option.value,
						children: option.children || option.label,
						value: undefined,
						label: undefined,
					}))}
					{...(rest?.items ? { items: rest?.items } : {})}
					selectedItems={newValue}
					onItemChange={(val, obj) => {
						if (typeof rest?.onChange === 'function') {
							rest?.onChange(val, obj);
						}
						onChange(val, obj);
					}}
					enableMultiSelect={multiple}
					onBlur={onBlur}
				/>
			)}
		/>
	);
}
export default ChipsController;
