import { useSelector } from '@cogoport/front/store';
import { useTranslation } from 'next-i18next';
import { IcMProfile } from '@cogoport/icons-react';
import useSupabase from '@/components/BotAuthorization/hooks/useSupabase';
import { useState } from 'react';
import ChatBotBody from './components/ChatBotBody';
import styles from './styles.module.css';
import CallingFeature from './components/CallingFeature';

function WebBot({
	cogobotLoading = false,
	sendMessage = '',
	sendMessageLoading = false,
	chatbotUserRoom = {},
	show = '',
	authUserId,
}) {
	const { t } = useTranslation(['common']);

	const { profile = {} } = useSelector((state) => state);

	const { name: userName = '' } = profile || {};

	const { supabaseClient } = useSupabase;
	const [messageStatus, setMessageStatus] = useState('');

	if (cogobotLoading || !show) {
		return null;
	}

	return (
		<div className={styles.bot_card}>
			<div className={styles.bot_head}>
				<div className={styles.bot_head_img}>
					<IcMProfile height={16} width={16} />

					<div className={styles.bot_name}>
						{t('common:floatingButton_heading')}
					</div>
				</div>

				{chatbotUserRoom?.support_agent_id ? (
					<CallingFeature chatbotUserRoom={chatbotUserRoom} />
				) : null}
			</div>

			<ChatBotBody
				supabaseClient={supabaseClient}
				messageStatus={messageStatus}
				setMessageStatus={setMessageStatus}
				name={userName}
				t={t}
				sendMessage={sendMessage}
				sendMessageLoading={sendMessageLoading}
				chatbotUserRoom={chatbotUserRoom}
				authUserId={authUserId}
			/>
		</div>
	);
}

export default WebBot;
