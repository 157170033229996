import { getCookie } from '@/temp/cogo-cookies';
import syncUserRooms from './syncUserRooms';
import addInitialMessage from './addInitialMessage';

const getPartnersData = (partners) =>
	partners.map(
		({
			twin_service_provider_id,
			twin_importer_exporter_id,
			business_name,
			registration_number,
		}) => ({
			organization_id:
				twin_importer_exporter_id || twin_service_provider_id || '',
			business_name,
			registration_number,
			account_type: twin_importer_exporter_id
				? 'importer_exporter'
				: twin_service_provider_id && 'service_provider',
		}),
	);

const EMPTY_FUNC = () => {};

const getRoomHelpers = ({
	profile,
	supabaseClient,
	isUnKnownUser,
	t,
	sendMessage,
	anonymousUserId,
	createMapping = EMPTY_FUNC,
	createPublicMapping = EMPTY_FUNC,
}) => {
	const {
		name = null,
		id: user_id = null,
		email: user_email = '',
		mobile_number_eformat: mobile_no = '',
		whatsapp_number_eformat: whatsapp_no = '',
		lead_user_id = null,
		partner,
		partners = [],
	} = profile;

	const {
		business_name: organization_name = '',
		twin_importer_exporter_id = null,
		twin_service_provider_id = null,
		account_types = [],
		registration_number = '',
	} = partner || {};

	const preferredMobileNo = whatsapp_no || mobile_no;

	const userDetails = {
		account_type: account_types?.includes('importer_exporter')
			? 'importer_exporter'
			: account_types?.[0] || null,
		user_id,
		business_name: organization_name,
		email: user_email,
		name,
		organization_id:
			twin_importer_exporter_id || twin_service_provider_id || null,
		registration_number,
		organizations: getPartnersData(partners) || [],
	};

	const createRoom = async () => {
		const userName = name || 'anonymous user';
		const roomInfo = {
			user_id,
			auth_user_id: anonymousUserId,
			session_type: 'bot',
			user_name: userName?.toUpperCase(),
			new_message_count: 0,
			new_user_message_count: 0,
			last_message: '',
			user_type: isUnKnownUser ? 'public_cp' : 'cp',
			support_agent_id: null,
			organization_id:
				twin_importer_exporter_id || twin_service_provider_id || null,
			channel_type: 'platform_chat',
			lead_user_id,
			new_message_sent_at: new Date(),
			spectators_ids: [],
			spectators_data: [],
			mobile_no: preferredMobileNo,
			chat_tags: [],
			chat_status: '',
			user_details: !isUnKnownUser ? userDetails : {},
			sender: getCookie('cogo_bot_token') || null,
			previous_tag: 'new_user',
			previous_tag_count: 0,
			agent_type: 'bot',
			metadata: {
				user_email,
				organization_name,
			},
		};
		const query = await supabaseClient
			.from('customer_chats')
			.insert(roomInfo)
			.select();

		const { id: room_id = null } = query?.data?.[0] || {};

		if (isUnKnownUser) {
			await createPublicMapping({ customer_chat_id: room_id });
		} else {
			await createMapping({ customer_chat_id: room_id });
		}

		await addInitialMessage({
			roomId: room_id,
			supabaseClient,
			name,
			t,
			sendMessage,
		});
		if (!isUnKnownUser) {
			syncUserRooms({
				platformChatRoomId: room_id,
				supabaseClient,
				preferredMobileNo,
			});
		}
	};

	const updateRoom = async () => {
		const room_query = await supabaseClient
			.from('customer_chats')
			.select()
			.eq('auth_user_id', anonymousUserId);

		const getRoomData = room_query?.data?.[0];

		const {
			session_type = 'bot',
			user_channel_ids: userChannelId,
			agent_type = 'bot',
			metadata = {},
			id = null,
		} = getRoomData || {};

		const userNameHash = name ? { user_name: name?.toUpperCase() } : {};

		let payload = {};
		if (user_id) {
			payload = {
				new_user_message_count: 0,
				mobile_no: preferredMobileNo,
				user_id,
				organization_id:
					twin_importer_exporter_id || twin_service_provider_id || null,
				lead_user_id,
				sender: getCookie('cogo_bot_token') || '',
				user_details: !isUnKnownUser ? userDetails : {},
				session_type: session_type === 'archived' ? 'bot' : session_type,
				agent_type,
				metadata: {
					...metadata,
					user_email,
					organization_name,
				},
				...userNameHash,
			};
		} else {
			payload = {
				session_type: session_type === 'archived' ? 'bot' : session_type,

				agent_type,
				new_user_message_count: 0,
			};
		}
		await supabaseClient.from('customer_chats').update(payload).eq('id', id);

		if (!userChannelId?.whatsapp_id && !isUnKnownUser) {
			syncUserRooms({
				platformChatRoomId: id,
				supabaseClient,
				preferredMobileNo,
			});
		}
	};

	return {
		updateRoom,
		createRoom,
	};
};

export default getRoomHelpers;
