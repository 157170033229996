import styled from '@cogoport/front/styled';
import GLOBAL_CONSTANTS from '@/constants/globals.json';

const blueMap = GLOBAL_CONSTANTS.image_urls.global_blue_map;
const getLeft = ({ isbeforeRegistration, isTnCpage }) => {
	if (isTnCpage) return '45%';
	return isbeforeRegistration ? '60%' : '0%';
};
const getRight = ({ isbeforeRegistration, isTnCpage }) => {
	if (isTnCpage) return '55%';
	return isbeforeRegistration ? '38%' : '100%';
};
export const Container = styled.section`
	&.onboarding-layout {
		height: 100vh;
		overflow-y: hidden;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export const Header = styled.header`
	&.onboarding-layout__header {
		width: 100%;
	}
`;
export const Icon = styled.div`
	&.left-panel__icon {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&.left-panel__icon--cogoport {
		margin-top: 24px;
		margin-left: 24px;

		& svg {
			width: 130px;
			min-height: 38px;
		}
	}

	&.left-panel__icon--globe {
		position: fixed;
		bottom: -160px;

		& svg {
			width: 100%;
			height: 500px;
		}
	}
`;
export const Main = styled.main`
	&.onboarding-layout__main {
		width: 100%;

		${(props) =>
			`height: ${
				props.isHeaderComponentPresent ? 'calc(100vh - 52px)' : '100%'
			};`}

		${(props) => `margin-top: ${props.isbeforeRegistration ? '0' : '56px'};`}

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

export const Panel = styled.section`
	&.onboarding-layout__panel {
		height: 100%;
	}
	&.onboarding-password_layout__panel {
		width: 100%;
		padding: 24px;
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-image: url(${blueMap});
	}
	&.onboarding-layout__panel--left {
		display: block;

		@media (min-width: 768px) {
			display: ${({ isbeforeRegistration, isTnCpage }) =>
				isbeforeRegistration || isTnCpage ? 'block' : 'none'};
			width: ${({ isbeforeRegistration, isTnCpage }) =>
				getLeft({ isbeforeRegistration, isTnCpage })};
		}
	}

	&.onboarding-layout__panel--right {
		width: 100%;
		overflow: auto;
		@media (min-width: 768px) {
			width: ${({ isbeforeRegistration, isTnCpage }) =>
				getRight({ isbeforeRegistration, isTnCpage })};
			display: flex;
			align-items: center;
			justify-content: center;
			display: block;
		}
	}
`;
export const SinglePanel = styled.div`
	&.onboarding-layout__panel {
		height: 100%;
	}
	&.onboarding-password_layout__panel {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-image: url(${blueMap});
	}
	&.core-ui-button-root {
		margin-top: 12px;
	}
`;
