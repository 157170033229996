export const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const MESSAGE_MAPPING = {
	text: ['text', 'template', 'interactive'],
	media: ['image', 'audio', 'video', 'document'],
	contact: ['contact'],
};

// cant move regex to global constants as its a json file
export const ENDS_WITH_STAR_SPACE = /\* /;
export const ENDS_WITH_STAR_CHAR = /\*[^0-9a-zA-Z]/;
export const URL_MATCH_REGEX =
	/^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)$/;

export const SLASH_MATCH_REGEX = /(\r\n|\r|\n)/g;
